import type { RecipeConfiguration } from '@module/common';
import type { UtilityConfiguration } from '@module/common/types/utilityConfig.types';

import { BaseClient } from '../BaseClient';

import type { FrankieApiClient } from '../../FrankieApiClient';

export class BaseConfigurationClient extends BaseClient {
  constructor(
    frankie: FrankieApiClient,
    protected options: { recipe: string },
  ) {
    super(frankie);
  }
}

export class ConfigurationClientF2 extends BaseConfigurationClient {
  constructor(frankie: FrankieApiClient, options: { recipe: string }) {
    super(frankie, options);
  }
  async loadRecipe(): Promise<RecipeConfiguration> {
    const params = {
      namespace: 'onesdk_profiles',
      item: this.options.recipe,
      itemType: 'object',
    };
    return this.frankie
      .get('/f2/v1/config', {
        params,
      })
      .then((response) => response.data[this.options.recipe]);
  }

  async loadUtilityConfig() {
    const params = {
      namespace: 'onesdk_utility',
    };
    return this.frankie
      .get<UtilityConfiguration>('/onesdk/v2/config', {
        params,
      })
      .then((response) => response.data);
  }
}
