import { type BusinessStructure, type TrustType } from '@paid-ui/enums/business';
import {
  type CompanyDirectorsStructure,
  PermissionName,
  PermissionType,
  UserGroupRelation,
  type UserGroupType,
  type UserStatus,
} from '@paid-ui/enums/user';
import { type Address } from '@paid-ui/schemas/zod/address';
import { type UserDetail, type UserGroupRole } from '@paid-ui/types/parties';
import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

export type UserInGroup = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  homeAddress?: Address;
  relation: UserGroupRelation;
  userStatus: UserStatus;
  groupDefaultSignatory: boolean;
  roles: UserGroupRole[];
  canReactivate?: boolean;
  isNominee?: boolean;
  permission?: Record<string, unknown[]>;
};

export type OfficerInBusiness = {
  id: string;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  homeAddress?: Address;
  isEmailVerified: boolean;
  isMobileVerified: boolean;
};

export type TrusteeInBusiness = {
  id: string;
};

export type BusinessInGroup = {
  id: string;
  displayName: string;
  businessStructure: BusinessStructure;
  companyDirectorsStructure: CompanyDirectorsStructure;
  trusteeType: TrustType;
  provideBuildingServices: boolean;
  businessSetupCompleted: boolean;
  trustees: TrusteeInBusiness[];
  registeredName: string;
  registeredForGst: boolean;
  registeredAddress?: Address;
  abn: string;
  acn?: string;
  builderLicenceNumber?: string;
  contactNumber?: string;
  earlyReleaseDiscountRate?: number;
};

const initialState = {
  id: '',
  displayName: '',
  type: '' as UserGroupType,
  requiredGroupSignatories: 0,
  users: [] as UserInGroup[],
  usersWithPermissions: [] as UserInGroup[],
  linkedGroups: [] as LinkedUserGroupRelation[],
  business: undefined as BusinessInGroup | undefined,
  signatories: [] as UserInGroup[],
  relation: '' as UserGroupRelation,
  permissions: [] as UserGroupRole[],
  permission: {
    canManagePayment: false,
    canManageContract: false,
    canManagePrimeContract: false,
    canManageSecondaryContract: false,
    canSignContract: false,
    canManageUser: false,
  },
};

export type UserGroup = Omit<typeof initialState, 'relation' | 'permissions' | 'permission'>;
export type LinkedUserGroup = Omit<UserGroup, 'linkedGroups'>;
export type LinkedUserGroupRelation = {
  relation: UserGroupRelation;
  group: LinkedUserGroup;
};

export const userGroupManager = proxy(initialState);

devtools(userGroupManager, {
  name: 'Default User Group',
  enabled: false,
});

export const resetUserGroupManager = () => {
  Object.assign(userGroupManager, initialState);
};

export const saveUserGroup = (profile?: UserDetail | null) => {
  if (!profile?.groupRelation) return;
  const { relation, group, permission } = profile.groupRelation;
  const permissions = permission?.roles ?? [];
  const flatPermissions = permissions.flatMap((p) => p.permissions);
  const users = group.users ?? [];

  const signatories = users.filter((user) => user.relation !== UserGroupRelation.EMPLOYEE);
  const permissionsMap = {} as Partial<Record<PermissionName, PermissionType[]>>;

  for (const { permission, permissionType } of flatPermissions) {
    if (permission in permissionsMap) {
      permissionsMap[permission]?.push(permissionType);
    } else {
      permissionsMap[permission] = [permissionType];
    }
  }
  const managePaymentPermissions = permissionsMap[PermissionName.MANAGE_PAYMENT] ?? [];
  const manageContractPermissions = permissionsMap[PermissionName.MANAGE_CONTRACT] ?? [];
  const signContractPermissions = permissionsMap[PermissionName.MANAGE_SIGNING] ?? [];
  const manageUserPermissions = permissionsMap[PermissionName.MANAGE_USER] ?? [];

  Object.assign(userGroupManager, {
    relation,
    signatories,
    permissions: permission?.roles ?? [],
    permission: {
      canManagePayment: managePaymentPermissions.length > 0,
      canManageContract: manageContractPermissions.length > 0,
      canManagePrimeContract: manageContractPermissions.includes(PermissionType.CONTRACT_PRIME),
      canManageSecondaryContract: manageContractPermissions.includes(
        PermissionType.CONTRACT_SECONDARY,
      ),
      canSignContract: signContractPermissions.length > 0,
      canManageUser: manageUserPermissions.length > 0,
    },
    ...group,
  });
};

export const saveUsersWithPermissions = (users: UserInGroup[]) => {
  userGroupManager.usersWithPermissions = users;
};
