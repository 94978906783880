import { DateTime } from 'luxon';
import { ICloneable } from '../general';

export enum DateType {
  BUDDHIST = 'BUDDHIST',
  DISCORDIAN = 'DISCORDIAN',
  GREGORIAN = 'GREGORIAN',
  HEBREW = 'HEBREW',
  ISLAMIC = 'ISLAMIC',
  JULIAN = 'JULIAN',
  LUNAR = 'LUNAR',
  MESO_AMERICAN = 'MESO_AMERICAN',
  PERSIAN = 'PERSIAN',
  OTHER = 'OTHER',
}

export interface DatePayload {
  year?: string;
  month?: string;
  day?: string;
  unstructured?: string;
  type?: DateType;
}

export class Date implements DatePayload, ICloneable<Date> {
  year?: string;
  month?: string;
  day?: string;
  unstructured?: string;
  type?: DateType;

  constructor(data?: DatePayload) {
    if (!data) return;
    this.year = data.year;
    this.month = data.month;
    this.day = data.day;
    this.unstructured = data.unstructured;
    this.type = data.type;
  }

  clone(): Date {
    const payload = JSON.parse(JSON.stringify(this));
    const date = Date.fromJSON(payload);
    return date;
  }

  toJSON(): DatePayload {
    return { ...this };
  }

  toDateString(): string {
    return `${this.year}-${this.month}-${this.day}`;
  }

  static fromJSON(payload: DatePayload): Date {
    return new Date(payload);
  }

  static fromDateString = (dateString: string, previousDate?: Date): Date => {
    const dateTime = DateTime.fromISO(dateString);
    return new Date({
      ...previousDate,
      year: dateTime.toFormat('yyyy'),
      month: dateTime.toFormat('LL'),
      day: dateTime.toFormat('dd'),
    });
  };
}

export interface DateOfBirthPayload extends DatePayload {
  dateOfBirthId?: string;
  sourceId?: string;
}

export class DateOfBirth
  implements DateOfBirthPayload, ICloneable<DateOfBirth> {
  year?: string;
  month?: string;
  day?: string;
  unstructured?: string;
  type?: DateType;
  dateOfBirthId?: string;
  sourceId?: string;

  constructor(data?: DateOfBirthPayload) {
    if (!data) return;
    this.year = data.year;
    this.month = data.month;
    this.day = data.day;
    this.unstructured = data.unstructured;
    this.type = data.type;
    this.dateOfBirthId = data.dateOfBirthId;
    this.sourceId = data.sourceId;
  }

  clone(): DateOfBirth {
    const payload = JSON.parse(JSON.stringify(this));
    const dob = DateOfBirth.fromJSON(payload);
    return dob;
  }

  toJSON(): DateOfBirthPayload {
    return { ...this };
  }

  toDateString(): string {
    return `${this.year}-${this.month}-${this.day}`;
  }

  static fromJSON(payload: DateOfBirthPayload): DateOfBirth {
    return new DateOfBirth(payload);
  }

  static default(): DateOfBirth {
    return new DateOfBirth();
  }

  static fromDateString = (
    dateString: string,
    previousDob?: DateOfBirth,
  ): DateOfBirth => {
    const dateTime = DateTime.fromISO(dateString);
    return new DateOfBirth({
      ...previousDob,
      year: dateTime.toFormat('yyyy'),
      month: dateTime.toFormat('LL'),
      day: dateTime.toFormat('dd'),
    });
  };
}
