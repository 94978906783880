import axios from 'axios';

import type { EventHub } from '@module/common';
import { ReactiveStore } from '@module/common';
import { OneSDKError } from '@module/common';
import type { CheckSummary } from '@module/common/shared/models/CheckSummary';
import type { ApplicantClient } from '@module/frankie-client/clients/ApplicantClient';
import type { GlobalEvents } from '@module/sdk/types';

import type { IndividualModule, InternalState } from '../definition';
import type { Events } from '../events';
import type { AxiosResponse } from 'axios';

const THROW_ERROR_WHEN_MISSING_CONSENTS = false;
type SubmitMethod = IndividualModule['moduleContext']['submit'];
type Dependencies = {
  state$: ReactiveStore<InternalState>;
  eventHub: EventHub<Events>;
  client: ApplicantClient;
  globalEventHub: EventHub<GlobalEvents>;
};
export const mkSubmit = (deps: Dependencies) => {
  const submitMethod = async (o: {
    verify: boolean;
  }): Promise<CheckSummary | void> => {
    const { eventHub, client, globalEventHub, state$ } = deps;
    const documents$ = state$.getRootAccessors('documents');
    const applicant$ = state$.getRootAccessors('applicant');
    const device$ = state$.getRootAccessors('device');
    const isLoading$ = state$.getRootAccessors('isLoading');
    const isPersisted$ = state$.getRootAccessors('isPersisted');

    const eventName = o?.verify ? 'SUBMIT_AND_VERIFY' : 'SUBMIT';
    try {
      isLoading$.setValue(true);
      // TODO: Should we use client.entityId instead of applicant$.getValue().entityId?
      const hasEntityId = Boolean(applicant$.getValue().entityId);
      // This method will apply any logic for validating consents (if any) and potentially throw an error to interrupt the operation
      handleConsents(deps);

      // Decide if creating or updating applicant and then extract method into a single variable
      const method = hasEntityId
        ? client.updateApplicant
        : client.createApplicant;
      const applicant = applicant$.getValue();
      const documents = documents$.getValue();
      const device = device$.getValue();

      // A value for givenName and familyname is required when submitting an applicant
      applicant.name.givenName ??= '';
      applicant.name.familyName ??= '';

      // Bind extracted method above to the client object and then call it
      // Either action will result in an entityId
      // If creating new applicant, store recently found entity Id
      const { entityId } = await method.call(client, { applicant, documents });

      if (!hasEntityId) {
        applicant.entityId = entityId;
        applicant$.setValue(applicant);
      }
      // TODO: Investigate if this condition should be enabled
      // else if (applicant.entityId !== entityId) throw new Error('Entity id mismatch");

      isPersisted$.setValue(true);
      documents$.setValue(
        documents.map((document) => {
          document.isPersisted = true;
          return document;
        }),
      );
      let checkSummary: CheckSummary;
      if (o?.verify) {
        checkSummary = await client.triggerChecks({
          deviceCheckDetails: device,
        });
      }

      globalEventHub.emit('telemetry', {
        eventName,
        data: hasEntityId ? {} : { createdEntityId: entityId },
      });

      if (o?.verify) return checkSummary;
    } catch (error) {
      eventHub.emit(
        'error',
        axios.isAxiosError(error)
          ? new OneSDKError(
              (error.response as AxiosResponse<{ message?: string }>).data
                .message ?? error.message,
              error.response.data,
            )
          : new OneSDKError(error.message, error),
      );
      throw error;
    } finally {
      isLoading$.setValue(false);
    }
  };
  return submitMethod as SubmitMethod;
};

const handleConsents = ({ state$, eventHub }: Dependencies) => {
  const applicant$ = state$.getRootAccessors('applicant');
  const consentsGiven$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'consents',
  });

  const isMissingConsents = consentsGiven$.getValue().length === 0;
  const errorMessage =
    'Consent needs to be provided before submitting any data.';
  const hintMessage =
    "Capture user's consent and provide it using the method addConsent(type) on the individual module, " +
    "where 'type' is one of the values defined in our api documentation";

  // How we want to handle consents is still being discussed, but we know consents are not always strictly required to be provided at this stage
  // For that reason we are not throwing an error, but instead emitting a warning. At least until we decide to do otherwise
  if (isMissingConsents && THROW_ERROR_WHEN_MISSING_CONSENTS) {
    throw new OneSDKError(errorMessage, {
      hint: hintMessage,
    });
  } else if (isMissingConsents) {
    eventHub.emit('warning', {
      message: errorMessage + 'This request might fail.',
      payload: {
        hint: hintMessage,
      },
    });
  }
};
