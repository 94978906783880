import { Applicant } from '../Applicant';
import { Core } from '../Core';
import { ICloneable } from '../general';
import { AddressPayload, Address } from './Address';
import { Consent, ConsentPayload, ConsentType } from './Consent';
import {
  CustomAttributeRecord,
  CustomAttributesPayload,
} from './CustomAttribute';
import { Date, DateOfBirth, DateOfBirthPayload } from './Date';
import { Document, DocumentClass, DocumentPayload } from './Document';
import { EmailAddress, EmailAddressPayload } from './EmailAddress';
import {
  ExternalReference,
  ExternalReferencePayload,
  ExternalReferenceType,
} from './ExternalReference';
import { AlternateName, AlternateNamePayload, Name, NamePayload } from './Name';
import { PhoneNumber, PhoneNumberPayload } from './PhoneNumber';
import { Document as F1Document } from '../Document';
import { CUSTOMER_REFERENCE_NAME } from '../../utils';

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NON_BINARY = 'NON_BINARY',
  OTHER = 'OTHER',
  UNSPECIFIED = 'UNSPECIFIED',
}

export interface IndividualConstructor {
  entityId?: string;
  externalReferences?: ExternalReference[];
  name?: Name;
  alternateNames?: AlternateName[];
  dateOfBirth?: Date;
  alternateDatesOfBirth?: Date[];
  gender?: {
    gender?: Gender;
  };
  customAttributes?: CustomAttributeRecord;
  phoneNumbers?: PhoneNumber[];
  addresses?: Address[];
  emailAddresses?: EmailAddress[];
  consents?: Consent[];
  placeOfBirth?: Address;
  nationality?: string;
  documents?: { [K in DocumentClass]?: Document[] };
  createdAt?: string;
}

export interface IndividualPayload {
  entityId?: string;
  externalReferences?: ExternalReferencePayload[];
  name?: NamePayload;
  alternateNames?: AlternateNamePayload[];
  dateOfBirth?: DateOfBirthPayload;
  alternateDatesOfBirth?: DateOfBirthPayload[];
  gender?: {
    gender?: Gender;
  };
  customAttributes?: CustomAttributesPayload;
  phoneNumbers?: PhoneNumberPayload[];
  addresses?: AddressPayload[];
  emailAddresses?: EmailAddressPayload[];
  consents?: ConsentPayload[];
  placeOfBirth?: AddressPayload;
  nationality?: string;
  documents?: { [K in DocumentClass]?: DocumentPayload[] };
  createdAt?: string;
}

export class Individual implements ICloneable<Individual> {
  entityId?: string;
  externalReferences?: ExternalReference[];
  name?: Name;
  alternateNames?: AlternateName[];
  dateOfBirth?: Date;
  alternateDatesOfBirth?: Date[];
  gender?: {
    gender?: Gender;
  };
  customAttributes?: CustomAttributeRecord;
  phoneNumbers?: PhoneNumber[];
  addresses?: Address[];
  emailAddresses?: EmailAddress[];
  consents?: Consent[];
  placeOfBirth?: Address;
  nationality?: string;
  documents?: { [K in DocumentClass]?: Document[] };
  createdAt?: string;

  constructor();

  constructor(data: IndividualConstructor);

  constructor(data?: IndividualConstructor) {
    if (!data) return;
    this.entityId = data.entityId;
    this.externalReferences = data.externalReferences;
    this.name = data.name ?? new Name();
    this.alternateNames = data.alternateNames;
    this.dateOfBirth = data.dateOfBirth;
    this.alternateDatesOfBirth = data.alternateDatesOfBirth;
    this.gender = data.gender;
    this.customAttributes = data.customAttributes;
    this.phoneNumbers = data.phoneNumbers;
    this.addresses = data.addresses;
    this.emailAddresses = data.emailAddresses;
    this.consents = data.consents;
    this.placeOfBirth = data.placeOfBirth;
    this.nationality = data.nationality;
    this.documents = data.documents;
    this.createdAt = data.createdAt;
  }

  get customerReference(): string | null {
    return (
      this.externalReferences?.find(
        ({ name }) => name === CUSTOMER_REFERENCE_NAME,
      )?.value ?? null
    );
  }

  set customerReference(reference: string) {
    if (!this.externalReferences) this.externalReferences = [];
    const customerReference = this.externalReferences?.find(
      ({ name }) => name === CUSTOMER_REFERENCE_NAME,
    );
    if (customerReference) customerReference.value = reference;
    else
      this.externalReferences.push(
        new ExternalReference({
          name: CUSTOMER_REFERENCE_NAME,
          type: ExternalReferenceType.CUSTOMER,
          value: reference,
        }),
      );
  }

  clone(): Individual {
    const payload = JSON.parse(JSON.stringify(this));
    const individual = Individual.fromJSON(payload);
    return individual;
  }

  toApplicant(): Applicant {
    return new Applicant({
      entityId: this.entityId ?? null,
      customerReference: this.customerReference,
      additionalReferences: this.externalReferences?.reduce(
        (acc, { name, value }) => ({
          ...acc,
          [name]: value,
        }),
        {} as Record<string, string>,
      ),
      name: this.name,
      extraData: this.customAttributes?.toExtraData(),
      dateOfBirth: this.dateOfBirth?.toDateString(),
      gender: this.gender?.gender?.[0].toUpperCase() as Core['enumGender'],
      phoneNumber: {
        documentId: this.phoneNumbers?.[0]?.phoneNumberId,
        idNumber: this.phoneNumbers?.[0]?.number,
      },
      addresses: this.addresses?.map((address) => address.toF1Address()),
      email: {
        documentId: this.emailAddresses?.[0]?.emailAddressId,
        idNumber: this.emailAddresses?.[0]?.email,
      },
      consents: this.consents?.map(({ type }) => type?.toLowerCase() ?? ''),
    });
  }

  toJSON(): IndividualPayload {
    return {
      ...this,
      customAttributes: this.customAttributes?.toJSON(),
      documents: this.documents
        ? Object.entries(this.documents).reduce<{
            [K in DocumentClass]?: DocumentPayload[];
          }>(
            (acc, [documentClass, documents]) => ({
              ...acc,
              [documentClass]: documents.map((document) => document.toJSON()),
            }),
            {},
          )
        : undefined,
    };
  }

  static fromJSON(payload: IndividualPayload): Individual {
    return new Individual({
      ...payload,
      externalReferences: payload.externalReferences?.map(
        ExternalReference.fromJSON,
      ),
      name: payload.name ? Name.fromJSON(payload.name) : undefined,
      alternateNames: payload.alternateNames?.map(AlternateName.fromJSON),
      dateOfBirth: payload.dateOfBirth
        ? DateOfBirth.fromJSON(payload.dateOfBirth)
        : undefined,
      alternateDatesOfBirth: payload.alternateDatesOfBirth?.map(
        DateOfBirth.fromJSON,
      ),
      customAttributes: payload.customAttributes
        ? CustomAttributeRecord.fromJSON(payload.customAttributes)
        : undefined,
      phoneNumbers: payload.phoneNumbers?.map(PhoneNumber.fromJSON),
      emailAddresses: payload.emailAddresses?.map(EmailAddress.fromJSON),
      consents: payload.consents?.map(Consent.fromJSON),
      addresses: payload.addresses?.map(Address.fromJSON),
      placeOfBirth: payload.placeOfBirth
        ? Address.fromJSON(payload.placeOfBirth)
        : undefined,
      documents: payload.documents
        ? Object.entries(payload.documents).reduce<{
            [K in DocumentClass]?: Document[];
          }>(
            (acc, [documentClass, documents]) => ({
              ...acc,
              [documentClass]: documents.map(Document.fromJSON),
            }),
            {},
          )
        : undefined,
    });
  }

  static fromApplicant(
    applicant: Applicant,
    documents: F1Document[],
    previousIndividual?: Individual,
  ): Individual {
    const getPhoneNumber = () => {
      const modifiedPhoneNumbers =
        previousIndividual?.phoneNumbers?.map((phoneNumber) =>
          phoneNumber.clone(),
        ) ?? [];
      if (applicant.phoneNumber.idNumber) {
        if (!modifiedPhoneNumbers[0])
          modifiedPhoneNumbers.push(new PhoneNumber());
        modifiedPhoneNumbers[0].phoneNumberId =
          applicant.phoneNumber.documentId ?? undefined;
        modifiedPhoneNumbers[0].number = applicant.phoneNumber.idNumber;
      }
      return modifiedPhoneNumbers;
    };

    const getEmailAddresses = () => {
      const modifiedEmailAddresses =
        previousIndividual?.emailAddresses?.map((emailAddress) =>
          emailAddress.clone(),
        ) ?? [];
      if (applicant.email.idNumber) {
        if (!modifiedEmailAddresses[0])
          modifiedEmailAddresses.push(new EmailAddress());
        modifiedEmailAddresses[0].emailAddressId =
          applicant.email.documentId ?? undefined;
        modifiedEmailAddresses[0].email = applicant.email.idNumber;
      }
      return modifiedEmailAddresses;
    };

    const individual = new Individual({
      ...previousIndividual,
      entityId: applicant.entityId ?? undefined,
      externalReferences: Object.entries(applicant.additionalReferences).map(
        ([name, value]) => {
          const previousExternalReference =
            previousIndividual?.externalReferences?.find(
              (externalReference) => externalReference.name === name,
            );
          return new ExternalReference({
            ...previousExternalReference,
            name,
            value,
          });
        },
      ),
      name: Name.fromF1Name(applicant.name, previousIndividual?.name),
      dateOfBirth: applicant.dateOfBirth
        ? DateOfBirth.fromDateString(
            applicant.dateOfBirth,
            previousIndividual?.dateOfBirth,
          )
        : undefined,
      gender: applicant.gender
        ? {
            gender: Object.values(Gender).find((value) =>
              value.startsWith(applicant.gender!),
            ),
          }
        : undefined,
      customAttributes: CustomAttributeRecord.fromExtraData(
        applicant.extraData,
        previousIndividual?.customAttributes,
      ),
      phoneNumbers: getPhoneNumber(),
      addresses: applicant.addresses.map((applicantAddress) => {
        const previousIndividualAddress = previousIndividual?.addresses?.find(
          ({ addressId }) => addressId === applicantAddress.addressId,
        );
        return Address.fromF1Address(
          applicantAddress,
          previousIndividualAddress,
        );
      }),
      emailAddresses: getEmailAddresses(),
      consents: applicant.consents.map((consent) => {
        const previousConsent = previousIndividual?.consents?.find(
          ({ type }) => type === consent.toUpperCase(),
        );
        return new Consent({
          ...previousConsent,
          type: ConsentType[consent.toUpperCase()],
        });
      }),
      documents: {
        ...previousIndividual?.documents,
        [DocumentClass.IDENTITY]: documents.map((document) => {
          const previousDocument = previousIndividual?.documents?.[
            DocumentClass.IDENTITY
          ]?.find(({ documentId }) => documentId === document.documentId);
          return Document.fromF1Document(document, previousDocument);
        }),
      },
    });

    if (applicant.customerReference)
      individual.customerReference = applicant.customerReference;

    return individual;
  }

  static default(): Individual {
    return new Individual();
  }
}
