export enum ServiceProfileState {
  INIT = 'INIT',
  AUTO = 'AUTO',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  BLOCKLISTED = 'BLOCKLISTED',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export enum WorkflowExecutionState {
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  TERMINATED = 'TERMINATED',
  ERROR = 'ERROR',
  TIMEOUT = 'TIMEOUT',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum WorkflowStatus {
  UNCHECKED = 'UNCHECKED',
  IN_PROGRESS = 'IN_PROGRESS',
  REVIEW = 'REVIEW',
  PASS = 'PASS',
  FAIL = 'FAIL',
  COMPLETE = 'COMPLETE',
  INCOMPLETE = 'INCOMPLETE',
  NEEDS_APPROVAL = 'NEEDS_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  BLOCKED = 'BLOCKED',
  CLEAR = 'CLEAR',
  URGENT = 'URGENT',
  MONITOR = 'MONITOR',
}

export enum RiskLevel {
  UNKNOWN = 'UNKNOWN',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  UNACCEPTABLE = 'UNACCEPTABLE',
}

export enum RiskFactorStatus {
  VALID = 'VALID',
  STALE = 'STALE',
  OVERRIDDEN = 'OVERRIDDEN',
  DISCARDED = 'DISCARDED',
}

export interface RiskFactor {
  riskFactorId?: string;
  factor?: string;
  value?: string;
  score?: number;
  description?: string;
  workflowExecutionId?: string;
  stepResultId?: string;
  manualOverrideScore?: number;
  status?: RiskFactorStatus;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;
  updatedRequestId?: string;
}

export interface RiskAssessment {
  serviceProfileId: string;
  riskAssessmentId?: string;
  workflowId: string;
  workflowExecutionId: string;
  entityId: string;
  schemaVersion?: string;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;
  updatedRequestId?: string;
  workflowRiskScore?: number;
  workflowRiskLevel?: RiskLevel;
  riskScore?: number;
  riskLevel?: RiskLevel;
  isOutdated?: boolean;
  riskFactors?: RiskFactor[];
}

export enum WorkflowExecutionSteps {
  ORDER = 'order',
  PASSED = 'passed',
  FAILED = 'failed',
  INCOMPLETE = 'incomplete',
  NOT_APPLICABLE = 'notApplicable',
}

export enum IssueCategory {
  VERIFY = 'VERIFY',
  DECEASED = 'DECEASED',
  EXPIRY = 'EXPIRY',
  INFORMATIONAL = 'INFORMATIONAL',
  AML = 'AML',
  FRAUD = 'FRAUD',
  IDV = 'IDV',
  DEVICE = 'DEVICE',
  TRANSACTION = 'TRANSACTION',
  WATCHLIST = 'WATCHLIST',
  DUPLICATE = 'DUPLICATE',
  BLOCKLISTED = 'BLOCKLISTED',
  SYSTEM = 'SYSTEM',
  BAD_DATA = 'BAD_DATA',
  KYC = 'KYC',
}

export enum IssueType {
  NOT_FOUND = 'NOT_FOUND',
  BAD_DATA_NAME = 'BAD_DATA_NAME',
  BAD_DATA_DOB = 'BAD_DATA_DOB',
  BAD_DATA_ADDRESS = 'BAD_DATA_ADDRESS',
  BAD_DATA_ID = 'BAD_DATA_ID',
  BAD_DATA_EMAIL = 'BAD_DATA_EMAIL',
  BAD_DATA_MOBILE = 'BAD_DATA_MOBILE',
  DECEASED = 'DECEASED',
  REQUIRES_KYC = 'REQUIRES_KYC',
  REQUIRES_IDV = 'REQUIRES_IDV',
  REQUIRES_AML = 'REQUIRES_AML',
  CREDIT_HEADER = 'CREDIT_HEADER',
  PEP = 'PEP',
  SANCTION = 'SANCTION',
  MEDIA = 'MEDIA',
  WATCHLIST = 'WATCHLIST',
  FRAUD_LIST = 'FRAUD_LIST',
  FRAUD_CHECK = 'FRAUD_CHECK',
  DEVICE_FRAUD_MATCH = 'DEVICE_FRAUD_MATCH',
  DEVICE_INVALID = 'DEVICE_INVALID',
  AML_ISSUE = 'AML_ISSUE',
  FRAUD_ISSUE = 'FRAUD_ISSUE',
  BLOCKED = 'BLOCKED',
  INTERNAL_MATCH = 'INTERNAL_MATCH',
  DUPLICATE = 'DUPLICATE',
  MATCHED_INTERNAL = 'MATCHED_INTERNAL',
  MATCHED_SHARED = 'MATCHED_SHARED',
  MATCHED_EXTERNAL = 'MATCHED_EXTERNAL',
  SERVICE_TIMEOUT = 'SERVICE_TIMEOUT',
  SERVICE_ERROR = 'SERVICE_ERROR',
}

export enum IssueSeverity {
  INFO = 'INFO',
  REVIEW = 'REVIEW',
  BLOCK = 'BLOCK',
  WARNING = 'WARNING',
  CRITICAL = 'CRITICAL',
  ERROR = 'ERROR',
}

export interface Issue {
  issueId?: string;
  category: IssueCategory;
  issue: IssueType;
  workflowExecutionId?: string;
  stepResultId?: string;
  isCleared?: boolean;
  createdBy?: string;
  createdAt?: string;
  updatedAt?: string;
  updatedBy?: string;
  updatedRequestId?: string;
  severity: IssueSeverity;
}

export interface WorkflowSummary {
  schemaVersion?: number;
  workflowName?: string;
  workflowExecutionId?: string;
  workflowExecutionState?: WorkflowExecutionState;
  status?: WorkflowStatus;
  statusOverrideAt?: string;
  statusOverrideRequestId?: string;
  statusOverrideBy?: string;
  riskAssessment?: RiskAssessment;
  isManual?: boolean;
  steps?: Record<WorkflowExecutionSteps, string[]>;
  startedAt?: string;
  endedAt?: string;
  issues?: Issue[];
}

export interface ServiceProfile {
  serviceProfileId?: string;
  entityId?: string;
  entityName?: string;
  entityType?: string;
  state?: ServiceProfileState;
  organizationType?: string;
  country?: string;
  schemaVersion?: string;
  createdAt?: string;
  createdRequestId?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedRequestId?: string;
  assignee?: string;
  reviewer?: string;
  currentWorkflowId?: string;
  currentWorkflowName?: string;
  workflowSummaries?: WorkflowSummary[];
  serviceName?: string;
  manualApprovals?: string[];
  customerReference?: string;
}
