import type { Applicant } from '@module/common/shared/models/Applicant';
import type { Document } from '@module/common/shared/models/Document';
import type { DeepPartial } from '@module/common/shared/models/general';
import { IDVStatus } from '@module/frankie-client/clients/IDVClient';
import type { OCRDocumentType } from '@module/frankie-client/clients/OCRClient';

import { URL_PARAM } from '../../DummyFrankieApiClient';
import { loadExistingApplicant } from '../individual/loadIndividual';
import { Status as OCRStatus, mkOCRResultPayload } from '../ocr/ocrSubmission';

import type {
  DummyFrankieApiClient,
  StubResponse,
} from '../../DummyFrankieApiClient';
import type { MockOptions as _MockOptions } from '../options/MockOptions.type';

export type IDVFlowOptions = {
  result: IDVStatus;
  failGeneratingSession?: boolean;
  capturedIndividualDetails?: DeepPartial<Applicant>;
  capturedDocumentDetails?: DeepPartial<Document>;
};

type MockOptions = _MockOptions & { idvRequests?: IDVFlowOptions };

const dummyIframeUrl = 'https://dummy-iframe-url.com';
const randomEntityId = 'random-entity-id';
const tokenFromVendor = 'session-token-from-provider';
const isFailedStatus = (status?: IDVStatus) => {
  return (
    status &&
    [
      IDVStatus.AWAITING_CONSENT,
      IDVStatus.DOCUMENTS_INVALID,
      IDVStatus.FAILED,
      IDVStatus.INCOMPLETE,
      IDVStatus.WAITING_DOC_UPLOAD,
      IDVStatus.WAITING_SELFIE_UPLOAD,
      IDVStatus.PROVIDER_OFFLINE,
      IDVStatus.AWAITING_CONSENT,
    ].includes(status)
  );
};
export function mockIDVFlow(
  client: DummyFrankieApiClient,
  options: MockOptions,
): DummyFrankieApiClient {
  const mkResponseWithEntityId = (entityId?: string): StubResponse => ({
    title: 'Create IDV token',
    delay: 2000,
    status: options.idvRequests?.failGeneratingSession ? 400 : 200,
    data: options.idvRequests?.failGeneratingSession
      ? { error: 'Error generating IDV token' }
      : {
          token: tokenFromVendor,
          vendorParameters: {
            tokenURL: dummyIframeUrl,
          },
          entityId,
        },
  });
  client.stubResponse(
    { url: new RegExp(`data/v2/idvCheck/${URL_PARAM}/token`), method: 'get' },
    (config) => {
      // When a token is created, an individual is created with it if not pre existing
      loadExistingApplicant(client, {
        preloadedIndividual: {
          individual: options.idvRequests?.capturedIndividualDetails ?? {},
          documents: [
            options.idvRequests?.capturedDocumentDetails ?? {},
          ] as Document[],
        },
      });
      return mkResponseWithEntityId(config.urlMatch?.[1]);
    },
  );
  client.stubResponse(
    { url: new RegExp(`data/v2/idvCheck/token`), method: 'post' },
    () => {
      // When a token is created, an individual is created with it if not pre existing
      loadExistingApplicant(client, {
        preloadedIndividual: {
          individual: options.idvRequests?.capturedIndividualDetails ?? {},
          documents: [
            options.idvRequests?.capturedDocumentDetails ?? {},
          ] as Document[],
        },
      });
      return mkResponseWithEntityId(randomEntityId);
    },
  );
  // TODO: What's the difference between the response for synchronous and asynchronous?
  client.stubResponse(
    {
      url: new RegExp(`data\\/v2\\/idvCheck\\/${URL_PARAM}\\/initProcess`),
      method: 'post',
    },
    () => {
      return {
        title: 'Init Asynchronous IDV process',
        delay: 3000,
        data: {
          ...mkResultsPayload(options),
          checkStatus: IDVStatus.CHECK_PROCESSING,
        },
      };
    },
  );
  client.stubResponse(
    {
      url: new RegExp(
        `data\\/v2\\/idvCheck\\/${URL_PARAM}\\/initProcess\\?is_synchronous`,
      ),
      method: 'post',
    },
    () => {
      return {
        title: 'Init synchronous IDV process',
        delay: 3000,
        data: mkResultsPayload(options),
      };
    },
  );
  return client;
}

function mkResultsPayload(options: MockOptions) {
  const providedDocument = options.idvRequests?.capturedDocumentDetails ?? {};
  // IDVStatus is a superset of OCRStatus so we need a forced type coercion to use the OCR function `mkOCRResultPayload` as a helper
  const ocrStatus =
    (options.idvRequests?.result as unknown as OCRStatus) ?? OCRStatus.COMPLETE;
  const ocrDetectedType = providedDocument?.idType as OCRDocumentType;
  // Reusing OCR payload factory
  const ocrDocument = isFailedStatus(options.idvRequests?.result)
    ? null
    : mkOCRResultPayload(
        {
          ocrDocument: {},
          status: ocrStatus,
        },
        {
          ocrRequests: {
            detectedType: ocrDetectedType ?? 'PASSPORT',
          },
          idvFlow: options.idvRequests,
        },
      ).ocrDocument;
  return {
    ocrDocument,
    checkStatus: ocrStatus,
  };
}
