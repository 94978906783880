import { z } from 'zod';

import type { RecipeConfiguration } from '@module/common';
import type { InjectedState } from '@module/common/types';

import type { PartialDeep } from 'type-fest';
import type { ZodError } from 'zod';

export function parseConfiguration(shared: InjectedState): InjectedState {
  const individual = shared.oneSdkInstance.individual();
  const isPreloaded = individual.isPreloaded();

  // Preloaded entities aren't allowed to be federated
  if (isPreloaded)
    throw new Error(
      'Federation component initialised with an existing entity. Initialise your session using a reference to a non existing entity.',
    );

  // General
  if (!shared.recipe.federation) throw new Error("Missing 'recipe.federation'");
  if (!shared.recipe.idps) throw new Error("Missing 'recipe.idps' dictionary");
  if (!shared.recipe.idps[shared.recipe.federation])
    throw new Error(
      `Idp '${shared.recipe.federation}' missing from 'recipe.idps' configuration`,
    );

  // SINGPASS
  if (shared.recipe.federation === 'singpass')
    validateSingpassIdpConfiguration(shared.recipe.idps.singpass);

  return shared;
}

function validateSingpassIdpConfiguration(
  singpass: PartialDeep<NonNullable<RecipeConfiguration['idps']>['singpass']>,
): singpass is NonNullable<RecipeConfiguration['idps']>['singpass'] {
  let redirect_url = z
    .string({
      required_error: "'singpass.redirect_url' is required",
    })
    .url("The specified 'singpass.redirect_url' needs to be a valid URL");

  // If environment is production, require redirect url to use the secure https:// protocol
  if (singpass?.environment === 'production')
    redirect_url = redirect_url.startsWith('https://', {
      message:
        "When 'singpass.environment' is 'production', the specified 'singpass.redirect_url' needs to be a secure https URL",
    });

  const environment = z.enum(['production', 'sandbox', 'test'], {
    required_error: "Invalid 'singpass.environment'",
  });

  try {
    z.object({
      redirect_url,
      environment,
    }).parse(singpass);
  } catch (e) {
    throw new Error((e as ZodError).issues[0].message);
  }
  return true;
}
