import type { Vendor } from '@module/federation/constants';

import { BaseClient } from '../BaseClient';

export class FederationClientF2 extends BaseClient {
  async provideAuthorisation(
    provider: Vendor,
    authCode: string,
  ): Promise<AuthorisationPayload> {
    // TODO: This whole method
    return await this.frankie
      .get<AuthorisationPayload>(
        `/f2/v1/callback/${provider}?authCode=${authCode}`,
      )
      .then((d) => d.data);
  }
  submitCachedData() {
    return this.frankie.post('/f2/v1/singpass/entity').then((d) => d.data);
  }
}

type AuthorisationPayload = { individual: object; status: string };
