/* eslint-disable check-file/filename-naming-convention */
export class LocalStorage {
  private get storage(): Storage {
    return window.localStorage;
  }
  store(key: string, value: unknown) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  retrieve<Type>(key: string): Type | null {
    // May fail parsing and throw exception
    const value = this.storage.getItem(key);
    if (!value) return null;
    return JSON.parse(value);
  }

  delete(key: string) {
    this.storage.removeItem(key);
  }

  retrieveAndDelete<Type>(key: string): Type | null {
    const value = this.retrieve<Type>(key);
    this.delete(key);
    return value;
  }
}
