import { ReactiveStore } from '@module/common';
import { defineModule } from '@module/common/modules/defineModule';
import { Applicant } from '@module/common/shared/models/Applicant';
import { ApplicantClient } from '@module/frankie-client/clients/ApplicantClient';
import { mkGetEmail, mkSetEmail } from '@module/individual/actions/email';
import {
  mkGetPhoneNumber,
  mkSetPhoneNumber,
} from '@module/individual/actions/phoneNumber';

import { mkAddReference } from './actions/addReference';
import { mkAddAddress, mkUpdateAddress } from './actions/addresses';
import { mkAddConsents } from './actions/consents';
import {
  mkAddDocument,
  mkFormIOUpdateDocument,
  mkUpdateDocument,
} from './actions/documents';
import { subscribeAndEmitEvents } from './actions/emitEvents';
import { mkSetExtraData } from './actions/extraData';
import { mkSetProfileType, mkGetProfileType } from './actions/profileType';
import { mkSearch } from './actions/search';
import { mkSubmit } from './actions/submit';
import IndividualModuleF2 from './F2';

import type {
  IndividualModule,
  InternalState,
  PublicAccessors,
} from './definition';

export default defineModule<IndividualModule>('individual', (globalState) => {
  if (globalState.session.frankie2customer)
    return IndividualModuleF2(globalState);

  const { frankieClient, globalEventHub, localEventHub } = globalState;
  const client = new ApplicantClient(frankieClient);

  const state$ = new ReactiveStore<InternalState>({
    applicant: new Applicant(),
    documents: [],
    device: {},
    isLoading: false,
    isPreloaded: false,
    isPersisted: false,
  });

  const documents$ = state$.getRootAccessors('documents');
  const applicant$ = state$.getRootAccessors('applicant');
  const device$ = state$.getRootAccessors('device');
  const isLoading$ = state$.getRootAccessors('isLoading');
  const isPreloaded$ = state$.getRootAccessors('isPreloaded');
  const isPersisted$ = state$.getRootAccessors('isPersisted');

  const entityId$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'entityId',
  });
  const name$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'name',
  });
  const dob$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'dateOfBirth',
  });
  const addresses$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'addresses',
  });
  const references$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'additionalReferences',
  });
  const consentsGiven$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'consents',
  });
  const profile$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'profile',
  });
  const phoneNumber$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'phoneNumber',
  });
  const email$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'email',
  });
  const extraData$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'extraData',
  });

  name$.observable.subscribe((value) => {
    if (!value) return;
    const documents = documents$.getValue()?.map((document) => {
      const newExtraData = { ...document.extraData };
      if (newExtraData.display_middle_name) {
        const newMiddleName =
          (String(newExtraData.display_middle_name).length === 1
            ? value.middleName?.[0]
            : value.middleName) || null;

        newExtraData.display_middle_name = newMiddleName;
        newExtraData.display_name_line1 = `${value.givenName}${newMiddleName ? ` ${newMiddleName} ` : ' '}${value.familyName}`;
      } else if (newExtraData.display_name_line1) {
        newExtraData.display_name_line1 = `${value.givenName} ${value.familyName}`;
      }
      document.extraData = newExtraData;
      return document;
    });
    documents$.setValue(documents ?? null);
  });

  // when adding a new public field, make sure to update the PublicFields type in ./types/module
  const publicAccessors: PublicAccessors = {
    entityId: entityId$,
    name: name$,
    documents: documents$,
    addresses: addresses$,
    dateOfBirth: dob$,
    isLoading: isLoading$,
    consentsGiven: consentsGiven$,
    profile: profile$,
    phoneNumber: phoneNumber$,
    email: email$,
    device: device$,
    isPersisted: isPersisted$,
    extraData: extraData$,
    // These fields are present only temporarily and should be replaced with new data models.
    // Exposing Applicant, Document and Address is risky as it couples the host application to data models that WILL change in the future.
    // Instead, expose each of the individual fields using a generic interface (name, date of birth, etc.)
    // The complete data models should only be exposed internally to other modules, but not to the host application.
    // !PROPOSAL: This will require a "backdoor" to be built into the module.
    // ! I recommend adding a field "backdoor" to the type ModuleDefinition, which will require "backdoor" be included in the module's context (object returned by this function)
    // ! The function mkModuleInstantiator will then remove "backdoor" from the context object before returning it to the host application.
    // ! Modules may use the function "getInitialiser" to access other modules' initialisers, which will include the backdoor field.
    __deprecated_applicant: applicant$,
    __deprecated_documents: documents$,
  };

  // For each public field, emit "data_updated" when they change
  subscribeAndEmitEvents({
    emitDataUpdatedFor: [
      entityId$,
      name$,
      dob$,
      addresses$,
      consentsGiven$,
      references$,
      documents$,
      profile$,
      phoneNumber$,
      email$,
      device$,
    ],
    eventHub: localEventHub,
    applicant$,
    documents$,
    isPersisted$,
  });

  // methods
  const search = mkSearch({
    client,
    eventHub: localEventHub,
    state$,
    recipeName: globalState.recipe.name,
  });

  const submitData = mkSubmit({
    state$,
    client,
    eventHub: localEventHub,
    globalEventHub,
  });

  const addConsent = mkAddConsents({
    consentsGiven$,
  });

  const addDocument = mkAddDocument({
    documents$,
  });
  const updateDocument = mkUpdateDocument({
    documents$,
  });
  const formIOUpdateDocument = mkFormIOUpdateDocument({
    documents$,
  });
  const addAddress = mkAddAddress({
    addresses$,
  });
  const updateAddress = mkUpdateAddress({
    addresses$,
  });
  const addReference = mkAddReference({
    applicant$,
    eventHub: localEventHub,
  });
  const setProfileType = mkSetProfileType({
    applicant$,
  });
  const getProfileType = mkGetProfileType({
    applicant$,
  });

  const setPhoneNumber = mkSetPhoneNumber({
    applicant$,
  });
  const getPhoneNumber = mkGetPhoneNumber({
    applicant$,
  });

  const setEmail = mkSetEmail({
    applicant$,
  });
  const getEmail = mkGetEmail({
    applicant$,
  });
  const setExtraData = mkSetExtraData({
    applicant$,
  });

  // Refresh state automatically on initialisation
  search();
  return {
    // Fetch methods
    search,
    isLoading: () => isLoading$.getValue(),
    isPreloaded: () => isPreloaded$.getValue(),
    // Submission methods
    isPersisted: () => isPersisted$.getValue(),
    submit: submitData,
    // Data methods
    access: (fieldName) => publicAccessors[fieldName],
    addDocument,
    updateDocument,
    formIOUpdateDocument,
    addAddress,
    updateAddress,
    addConsent,
    addReference,
    setProfileType,
    getProfileType,
    setPhoneNumber,
    getPhoneNumber,
    setEmail,
    getEmail,
    setExtraData,
  };
});

export * from './definition';
export * from './events';
