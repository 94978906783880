import type { IndividualPayload } from '@module/common/shared/models/F2/Individual';
import { Individual } from '@module/common/shared/models/F2/Individual';
import type { ServiceProfile } from '@module/common/shared/models/F2/ServiceProfiles';

import { BaseClient } from '../BaseClient';

export class IndividualClient extends BaseClient {
  async load(): Promise<IndividualState> {
    /**
     * A search made using entityId HAS to be successful, since the entity id already exists. If not found, throw exception
     * A search made using reference doesn't need to return results, as it might be a
     */
    const { entityId, reference } = this;
    let searchEntity = entityId;
    if (reference && !entityId) {
      const { data } = await this.frankie.post<{
        matches: Array<{
          serviceProfile: {
            entityId: string;
          };
        }>;
      }>('/f2/one-sdk/v1/search', { reference });
      if (data.matches.length > 0) {
        searchEntity = data.matches[0].serviceProfile.entityId;
      } else {
        throw new Error('No matches found for reference');
      }
    }

    return await this.frankie
      .get<SearchPayload>(`/f2/one-sdk/v1/individuals/${searchEntity}`)
      .then((response) => {
        this.entityId = searchEntity;
        return response.data;
      })
      .then((data) => {
        const individual = Individual.fromJSON(data.individual);
        if (individual.documents)
          Object.values(individual.documents).forEach((documents) =>
            documents.forEach((document) => {
              document.isPersisted = true;
            }),
          );
        return { individual };
      });
  }

  async createIndividual(
    payload: IndividualState,
    params: { verify: boolean },
  ): Promise<IndividualResponse> {
    const { individual } = payload;
    return this.frankie
      .post<IndividualResponse>(
        '/f2/one-sdk/v1/individuals',
        {
          individual: individual.toJSON(),
        },
        { params },
      )
      .then((r) => {
        // store new entity id locally for subsequent requests
        this.entityId = r.data.individual.entityId ?? null;
        return r.data;
      });
  }

  async updateIndividual(
    payload: IndividualState,
    params: { verify: boolean },
  ): Promise<IndividualResponse> {
    if (!this.entityId)
      throw new Error(
        'Entity id not found in local state. Has entity been created yet?',
      );

    const individual = payload.individual.toJSON();
    Object.entries(individual.documents ?? {}).forEach(
      ([documentClass, documents]) => {
        (individual.documents as Required<IndividualPayload>['documents'])[
          documentClass
        ] = documents.filter((d) => !d.isPersisted);
      },
    );

    return this.frankie
      .put<IndividualResponse>(
        `/f2/one-sdk/v1/individuals/${this.entityId}`,
        {
          individual,
        },
        { params },
      )
      .then((r) => r.data);
  }
}

interface IndividualState {
  individual: Individual;
}

export interface SearchPayload {
  individual: IndividualPayload;
  serviceProfiles: ServiceProfile[];
}

export type IndividualResponse = {
  individual: IndividualPayload;
  serviceProfiles: ServiceProfile[];
  requestId: string;
};
