import type { TelemetryEvent } from '@module/common/shared/models/TelemetryEvent';

import { BaseClient } from './BaseClient';
import { TelemetryEventsClientF2 } from './F2/TelemetryEventsClientF2';

import type { FrankieApiClient } from '../FrankieApiClient';

export class TelemetryEventsClient extends BaseClient {
  constructor(frankie: FrankieApiClient) {
    super(frankie);
    if (frankie.frankie2customer) {
      return new TelemetryEventsClientF2(frankie);
    }
  }
  dispatch(payload: TelemetryEvent) {
    return this.frankie.post<void>(`/data/v2/events`, { ...payload });
  }
}
