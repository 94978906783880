import { feat } from '@feature-flags';
import type { ModuleVendorLoader } from '@module/common';
import { mkEventHub } from '@module/common';
import { useFormToReviewResults } from '@module/common/vendors/form/useForm';
import type { Events, OCRModule } from '@module/ocr';
import type { Dependencies as StartDependencies } from '@module/ocr/actions/start';
import { mkStartMethod } from '@module/ocr/actions/start';
import type { RecipeParsed } from '@module/sdk/types';
import type { OneSdkContext } from '@module/types';

type Dependencies = StartDependencies & {
  vendorLoader: ModuleVendorLoader<OCRModule>;
  recipe: RecipeParsed;
  oneSdkInstance: OneSdkContext;
};
export const mkMountMethod = (
  deps: Dependencies,
): OCRModule['moduleContext']['mount'] => {
  const { eventHub, recipe, oneSdkInstance } = deps;
  const vendorName = recipe?.ocr?.provider?.name;

  // For Form component to be included after the "results" event, the recipe
  // configuration provideReviewScreen must not be false
  // and the vendor must be configured in `features.ts` with 'containsReviewScreen' = false.
  // Form is not displayed for vendors which already have a review screen
  const recipeProvideReviewScreen = recipe.ocr?.provideReviewScreen ?? true;
  const containsReviewScreen = vendorName
    ? feat(`modules.ocr.containsReviewScreen.${vendorName}`)
    : false;
  const attachForm = !containsReviewScreen && recipeProvideReviewScreen;

  return async (domElementOrSelector: HTMLElement | string) => {
    eventHub.emit('loading', true, {
      message: `Loading OCR vendor '${vendorName}'`,
    });
    const mountElement =
      typeof domElementOrSelector === 'string'
        ? window.document.querySelector<HTMLElement>(domElementOrSelector)
        : domElementOrSelector;

    if (!mountElement) throw Error('Unable to find mount element');

    // when mounting, we also run the start method, which has its own logic to define if a new ocr capture is
    // required or not, based on pre-existing document objects with ocrResults.
    // 1) if ocr was already completed successfully, emit a results event
    // 2) otherwise, emit input_required event
    const privateEventHub = mkEventHub<Events>();
    const internalStartMethod = mkStartMethod({
      ...deps,
      eventHub: privateEventHub,
    });
    // when private results event is captured, broadcast to public event
    privateEventHub.on(
      'results',
      useFormToReviewResults({
        localEventHub: eventHub,
        oneSdkInstance,
        mountElement,
        attachForm: attachForm,
      }),
    );
    privateEventHub.on('input_required', () => {
      loadAndInitialiseVendor(deps, domElementOrSelector);
    });
    await internalStartMethod();
  };
};

const loadAndInitialiseVendor = (
  deps: Dependencies,
  domElementOrSelector: HTMLElement | string,
) => {
  const { vendorLoader, eventHub, globalEventHub } = deps;
  // Load and initialise vendor wrapper
  // Which will automatically mount camera feed to an HTML element provided as the parameter above
  vendorLoader({
    vendorWrapperOptions: () => {
      // Resolve the target HTML Element
      const mountElement =
        typeof domElementOrSelector === 'string'
          ? window.document.querySelector<HTMLElement>(domElementOrSelector)
          : domElementOrSelector;
      if (!mountElement)
        throw new Error(`DOM Element ${domElementOrSelector} not found.`);

      return {
        eventHub,
        mountElement,
      };
    },
    onSuccess: (_, { vendorName }) => {
      globalEventHub.emit('telemetry', {
        eventName: 'OCR:MOUNT',
        data: { vendor: vendorName },
      });
    },
    onError: (error, { vendorName }) => {
      globalEventHub.emit('telemetry', {
        eventName: 'OCR:MOUNT:ERROR',
        data: { vendor: vendorName },
        error,
      });
    },
  });
};
