import type { Applicant } from '@module/common/shared/models/Applicant';
import type { Accessors } from '@module/types';

import type { IndividualModule } from '../definition';

type SetPhoneNumber = IndividualModule['moduleContext']['setPhoneNumber'];
type GetPhoneNumber = IndividualModule['moduleContext']['getPhoneNumber'];

type MkSetPhoneNumber = {
  applicant$: Accessors<Applicant>;
};

type MkGetPhoneNumber = MkSetPhoneNumber;

export function mkSetPhoneNumber(deps: MkSetPhoneNumber): SetPhoneNumber {
  const { applicant$ } = deps;

  return (phoneNumber: string | null) => {
    const applicant = applicant$.getValue();
    applicant.phoneNumber = {
      documentId: applicant.phoneNumber.documentId,
      idNumber: phoneNumber,
    };
    applicant$.setValue(applicant);
  };
}

export function mkGetPhoneNumber(deps: MkGetPhoneNumber): GetPhoneNumber {
  const { applicant$ } = deps;

  return () => {
    const applicant = applicant$.getValue();
    return applicant.phoneNumber.idNumber;
  };
}
