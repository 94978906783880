import { ReactiveStore, type EventHub } from '@module/common';
import { Applicant } from '@module/common/shared/models/Applicant';
import { Individual } from '@module/common/shared/models/F2/Individual';
import type { InjectedState } from '@module/common/types';
import { IndividualClient } from '@module/frankie-client/clients/F2/IndividualClient';
import type { _DictionaryWrite } from '@types';

import { mkAddReference } from '../actions/addReference';
import { mkAddAddress, mkUpdateAddress } from '../actions/addresses';
import { mkAddConsents } from '../actions/consents';
import {
  mkAddDocument,
  mkFormIOUpdateDocument,
  mkUpdateDocument,
} from '../actions/documents';
import { mkGetEmail, mkSetEmail } from '../actions/email';
import { subscribeAndEmitEvents } from '../actions/emitEvents';
import { mkSetExtraData } from '../actions/extraData';
import { mkGetPhoneNumber, mkSetPhoneNumber } from '../actions/phoneNumber';
import { mkGetProfileType, mkSetProfileType } from '../actions/profileType';

import { mkSearch } from './actions/search';
import { mkSubmit } from './actions/submit';

import type { InternalStateF2 } from './definition';
import type { Events, IndividualModule, PublicAccessors } from '..';

const IndividualModuleF2 = (
  globalState: Omit<InjectedState, 'moduleMeta'> & {
    localEventHub: EventHub<Events>;
    recipe: _DictionaryWrite<unknown>;
  },
): IndividualModule['moduleContext'] => {
  const { frankieClient, globalEventHub, localEventHub } = globalState;

  const client = new IndividualClient(frankieClient);

  const state$ = new ReactiveStore<InternalStateF2>({
    individual: new Individual(),
    _applicant: new Applicant(),
    _documents: [],
    device: {},
    isLoading: false,
    isPreloaded: false,
    isPersisted: false,
  });

  const individual$ = state$.getRootAccessors('individual');
  const documents$ = state$.getRootAccessors('_documents');
  const applicant$ = state$.getRootAccessors('_applicant');
  const device$ = state$.getRootAccessors('device');
  const isLoading$ = state$.getRootAccessors('isLoading').toReadonly();
  const isPreloaded$ = state$.getRootAccessors('isPreloaded').toReadonly();
  const isPersisted$ = state$.getRootAccessors('isPersisted');

  applicant$.observable.subscribe((value) => {
    if (!value) return;
    individual$.setValue(
      Individual.fromApplicant(
        value,
        documents$.getValue(),
        individual$.getValue(),
      ),
    );
  });

  documents$.observable.subscribe((value) => {
    if (!value) return;
    individual$.setValue(
      Individual.fromApplicant(
        applicant$.getValue(),
        value,
        individual$.getValue(),
      ),
    );
  });

  const entityId$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'entityId',
  }).toReadonly();
  const name$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'name',
  });
  const addresses$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'addresses',
  });
  const dob$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'dateOfBirth',
  });
  const consentsGiven$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'consents',
  });
  const profile$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'profile',
  });
  const phoneNumber$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'phoneNumber',
  });
  const emailAddress$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'email',
  });
  const extraData$ = ReactiveStore.mkPropertyAccessors(applicant$, {
    propertyName: 'extraData',
  });

  const publicAccessors: PublicAccessors = {
    entityId: entityId$,
    name: name$,
    documents: documents$,
    addresses: addresses$,
    dateOfBirth: dob$,
    isLoading: isLoading$,
    consentsGiven: consentsGiven$,
    profile: profile$,
    phoneNumber: phoneNumber$,
    email: emailAddress$,
    device: device$,
    isPersisted: isPersisted$,
    extraData: extraData$,
    // These fields are present only temporarily and should be replaced with new data models.
    // Exposing Applicant, Document and Address is risky as it couples the host application to data models that WILL change in the future.
    // Instead, expose each of the individual fields using a generic interface (name, date of birth, etc.)
    // The complete data models should only be exposed internally to other modules, but not to the host application.
    // !PROPOSAL: This will require a "backdoor" to be built into the module.
    // ! I recommend adding a field "backdoor" to the type ModuleDefinition, which will require "backdoor" be included in the module's context (object returned by this function)
    // ! The function mkModuleInstantiator will then remove "backdoor" from the context object before returning it to the host application.
    // ! Modules may use the function "getInitialiser" to access other modules' initialisers, which will include the backdoor field.
    __deprecated_applicant: applicant$,
    __deprecated_documents: documents$,
  };

  // For each public field, emit "data_updated" when they change
  subscribeAndEmitEvents({
    emitDataUpdatedFor: [
      entityId$,
      name$,
      dob$,
      addresses$,
      consentsGiven$,
      documents$,
      profile$,
      phoneNumber$,
      emailAddress$,
      device$,
    ],
    eventHub: localEventHub,
    applicant$,
    documents$: documents$,
    isPersisted$,
  });

  // methods
  const search = mkSearch({
    client,
    eventHub: localEventHub,
    state$,
  });

  const submitData = mkSubmit({
    state$,
    client,
    eventHub: localEventHub,
    globalEventHub,
  });

  const addConsent = mkAddConsents({
    consentsGiven$: consentsGiven$,
  });

  const addDocument = mkAddDocument({
    documents$: documents$,
  });
  const updateDocument = mkUpdateDocument({
    documents$: documents$,
  });
  const formIOUpdateDocument = mkFormIOUpdateDocument({
    documents$: documents$,
  });
  const addAddress = mkAddAddress({
    addresses$: addresses$,
  });
  const updateAddress = mkUpdateAddress({
    addresses$: addresses$,
  });
  const addReference = mkAddReference({
    applicant$,
    eventHub: localEventHub,
  });
  const setProfileType = mkSetProfileType({
    applicant$,
  });
  const getProfileType = mkGetProfileType({
    applicant$,
  });

  const setPhoneNumber = mkSetPhoneNumber({
    applicant$,
  });
  const getPhoneNumber = mkGetPhoneNumber({
    applicant$,
  });

  const setEmail = mkSetEmail({
    applicant$,
  });
  const getEmail = mkGetEmail({
    applicant$,
  });
  const setExtraData = mkSetExtraData({
    applicant$,
  });

  return {
    // Fetch methods
    search,
    isLoading: () => isLoading$.getValue(),
    isPreloaded: () => isPreloaded$.getValue(),
    // Submission methods
    isPersisted: () => isPersisted$.getValue(),
    submit: submitData,
    // Data methods
    access: (fieldName) => publicAccessors[fieldName],
    addDocument,
    updateDocument,
    formIOUpdateDocument,
    addAddress,
    updateAddress,
    addConsent,
    addReference,
    setProfileType,
    getProfileType,
    setPhoneNumber,
    getPhoneNumber,
    setEmail,
    getEmail,
    setExtraData,
  };
};

export default IndividualModuleF2;
