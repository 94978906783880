import { Address, addressTypeSorter, IAddressPayload } from './Address';
import { Core } from './Core';
import { ExtraData, ICloneable, Nullable } from './general';
import { IProfilePayload, Profile } from './Profile';

type AddressType = Address['addressType'];

type ApplicantExtraData = ExtraData &
  Partial<{
    home_country_givenname: string;
    home_country_familyname: string;
    marital_status: Core['enumMaritalStatus'];
    'dob.Buddhist': string;
    buddhist_solar_dob: true;
  }>;

export type TOriginalUboDetails = {
  name: {
    givenName: Nullable<string>;
    middleName: Nullable<string>;
    familyName: Nullable<string>;
    displayName: Nullable<string>;
  };
  dateOfBirth: Nullable<string>;
  addresses: Address[];
  isNameAddedByCustomer?: boolean;
  isDobAddedByCustomer?: boolean;
};

export interface IApplicantPayload {
  entityId: Nullable<string>;
  customerReference: Nullable<string>;
  additionalReferences: Record<string, string>;
  name: {
    givenName?: Nullable<string>;
    middleName?: Nullable<string>;
    familyName?: Nullable<string>;
    displayName?: Nullable<string>;
  };
  extraData?: ApplicantExtraData;
  dateOfBirth: Nullable<string>;
  gender: Nullable<Core['enumGender']>;
  phoneNumber: {
    documentId: Nullable<string>;
    idNumber: Nullable<string>;
  };
  addresses: IAddressPayload[];
  email: {
    documentId: Nullable<string>;
    idNumber: Nullable<string>;
  };
  profile: IProfilePayload | null;
  assignee: Nullable<string>;
  consents: string[];
  blocklistAttributes: Nullable<'ATTR' | 'FULL'>;
  originalUboDetails?: Nullable<TOriginalUboDetails>;
}

interface IApplicantConstructor {
  entityId: Nullable<string>;
  customerReference?: Nullable<string>;
  additionalReferences?: Record<string, string>;
  name?: {
    givenName?: Nullable<string>;
    middleName?: Nullable<string>;
    familyName?: Nullable<string>;
    displayName?: Nullable<string>;
  };
  extraData?: ApplicantExtraData;
  dateOfBirth?: Nullable<string>;
  gender?: Nullable<Core['enumGender']>;

  phoneNumber?: {
    documentId?: Nullable<string>;
    idNumber?: Nullable<string>;
  };
  addresses?: Address[];
  email?: {
    documentId?: Nullable<string>;
    idNumber?: Nullable<string>;
  };
  profile?: Nullable<Profile>;
  assignee?: Nullable<string>;
  consents?: string[];
  blocklistAttributes?: Nullable<'ATTR' | 'FULL'>;
  originalUboDetails?: Nullable<TOriginalUboDetails>;
}

export type FullNameFields = {
  givenName?: Nullable<string>;
  middleName?: Nullable<string>;
  familyName?: Nullable<string>;
  displayName?: Nullable<string>;
};

export type SpecialDocument = {
  documentId: Nullable<string>;
  idNumber: Nullable<string>;
};

export class Applicant implements ICloneable<Applicant> {
  entityId: Nullable<string> = null;
  customerReference: Nullable<string> = null;
  additionalReferences: Record<string, string> = {};

  name: FullNameFields = {
    givenName: null,
    middleName: null,
    familyName: null,
    displayName: null,
  };

  dateOfBirth: Nullable<string> = null;
  gender: Nullable<Core['enumGender']> = null;

  extraData: ApplicantExtraData = {};
  phoneNumber: SpecialDocument = {
    documentId: null,
    idNumber: null,
  };
  addresses: Address[] = [];
  email: SpecialDocument = {
    documentId: null,
    idNumber: null,
  };
  profile: Nullable<Profile> = null;
  assignee: Nullable<string> = null;
  consents: string[] = [];
  blocklistAttributes: Nullable<'ATTR' | 'FULL'> = null;
  originalUboDetails: Nullable<TOriginalUboDetails> = null;

  constructor();

  constructor(a: IApplicantConstructor);

  constructor(a?: IApplicantConstructor) {
    if (!a) return;
    this.entityId = a?.entityId?.toLowerCase() || null;
    this.customerReference = a.customerReference || null;
    this.additionalReferences = a.additionalReferences || {};
    this.name = {
      givenName: a.name?.givenName || null,
      middleName: a.name?.middleName || null,
      familyName: a.name?.familyName ?? null,
      displayName: a.name?.displayName || null,
    };
    this.dateOfBirth = a.dateOfBirth || null;
    this.gender = a.gender || null;
    this.phoneNumber = {
      documentId: a.phoneNumber?.documentId || null,
      idNumber: a.phoneNumber?.idNumber || null,
    };
    this.email = {
      documentId: a.email?.documentId || null,
      idNumber: a.email?.idNumber || null,
    };
    this.addresses = sortAddressArray(a.addresses || []);
    this.profile = a.profile!;
    this.assignee = a.assignee || null;
    this.consents = a.consents || [];
    this.extraData = a.extraData ?? {};
    this.blocklistAttributes = a.blocklistAttributes ?? null;
    this.originalUboDetails = !a.originalUboDetails
      ? null
      : {
          name: {
            givenName: a.originalUboDetails?.name?.givenName || null,
            middleName: a.originalUboDetails?.name?.middleName || null,
            familyName: a.originalUboDetails?.name?.familyName ?? null,
            displayName: a.originalUboDetails?.name?.displayName || null,
          },
          dateOfBirth: a.originalUboDetails?.dateOfBirth || null,
          isDobAddedByCustomer: a.originalUboDetails?.isDobAddedByCustomer,
          isNameAddedByCustomer: a.originalUboDetails?.isNameAddedByCustomer,
          addresses: sortAddressArray(a.originalUboDetails.addresses || []),
        };
  }

  get currentPreviousAddress() {
    return {
      current: this.addresses[0],
      previous: this.addresses[1],
    };
  }

  set fullName(value: string) {
    const split = value.split(' ');
    const given = split[0];
    const family = split[1];
    const middle = split.slice(1, split.length - 1).join(' ');
    this.name.givenName = given;
    this.name.middleName = middle;
    this.name.familyName = family;
  }

  get displayName() {
    const fullName = [
      this.name.givenName,
      this.name.middleName,
      this.name.familyName,
    ]
      .filter((i) => !!i)
      .join(' ');
    return this.name.displayName || fullName;
  }

  get nativeName(): string {
    const givenName = this.extraData.home_country_givenname;
    const familyName = this.extraData.home_country_familyname;
    return [givenName, familyName].filter(Boolean).join(' ');
  }

  getAddress(addressType: AddressType): Address | null {
    const addresses = this.addresses;
    const address =
      addresses.find((a) => addressTypeFinder(a.addressType, addressType)) ??
      null;
    return address;
  }

  clone(): Applicant {
    const payload = JSON.parse(JSON.stringify(this));
    const applicant = Applicant.fromJSON(payload);
    return applicant;
  }

  toJSON(): IApplicantPayload {
    const { profile, addresses } = this;
    // This is a copy of Address.toJSON()
    // It does not require address item to be casted as new Address()
    // which eliminates the export of Address class to user
    const addressMap = addresses.map((address) => {
      // Filter out address fields that should not be included
      const addressFields = Object.keys(new Address()).filter(
        (field) => !['country', 'addressType', 'addressId'].includes(field),
      );

      // Check if the address only has country information
      const isAddressHasCountryOnly = addressFields.every(
        (field) => !address[field],
      );

      if (isAddressHasCountryOnly) return { ...address };

      // Destructure the street name from the address
      const { _streetName, ...restAddress } = address;

      // Return modified address object
      return {
        ...restAddress,
        streetName: address.streetName,
        longForm: Boolean(address.data?.longForm)
          ? address.longForm
          : undefined,
      };
    });
    const profilePayload = profile?.toJSON() || null;
    return { ...this, profile: profilePayload, addresses: addressMap };
  }

  static fromJSON(payload: IApplicantPayload): Applicant {
    const profile = payload.profile ? Profile.fromJSON(payload.profile) : null;
    const addresses = payload.addresses.map((m) => Address.fromJSON(m));
    const consents = payload.consents ?? [];

    return new Applicant({
      ...payload,
      addresses,
      profile,
      consents,
    });
  }

  static default(): Applicant {
    return new Applicant({
      addresses: [],
      profile: Profile.default(),
      consents: [],
      entityId: null,
      customerReference: null,
      additionalReferences: {},

      name: {
        givenName: null,
        middleName: null,
        familyName: null,
        displayName: null,
      },

      dateOfBirth: null,
      gender: null,
      phoneNumber: {
        documentId: null,
        idNumber: null,
      },
      email: {
        documentId: null,
        idNumber: null,
      },
      assignee: null,
      blocklistAttributes: null,
      originalUboDetails: null,
    });
  }
}

function sortAddressArray(list: Address[]) {
  return list?.sort((a, b) => addressTypeSorter(a.addressType, b.addressType));
}

function addressTypeFinder(compare: AddressType, needle: AddressType) {
  // if needle is residential1 and compare is any of the different values
  // for residential 1 (residential, residential1 and falsy), return true
  // otherwise, simply compare both
  const residential1Variants = [
    'RESIDENTIAL',
    'RESIDENTIAL1',
  ] as Address['addressType'][];
  const isCompareAVariant = residential1Variants.includes(compare);
  const isCompareFalsy = !Boolean(compare);
  const isCompareResidential1 = isCompareAVariant || isCompareFalsy;
  if (needle === 'RESIDENTIAL1' && isCompareResidential1) return true;
  else return compare === needle;
}
