import type { Events, InternalState } from '@module/business';
import type { EventHub, ReactiveStore } from '@module/common';
import { OneSDKError } from '@module/common';
import type { organizations_entity_response } from '@module/common/shared/models/internal';
import type { BusinessClient } from '@module/frankie-client/clients/BusinessClient';

type MkSearchDeps = {
  client: BusinessClient;
  eventHub: EventHub<Events>;
  state$: ReactiveStore<InternalState>;
  recipeName: string;
};
export const mkSearch = (deps: MkSearchDeps) => {
  return async () => {
    const { client, eventHub, state$ } = deps;

    const isLoading$ = state$.getRootAccessors('isLoading');
    let business: organizations_entity_response | null = null;

    try {
      isLoading$.setValue(true);
      business = await client.load();
    } catch (e) {
      if (client.entityId) {
        const errorMessage = `Critical error! There was an issue loading the business's details. Is the entityId (${client.entityId}) used to create this session valid?`;
        eventHub.emit('error', new OneSDKError(errorMessage, e));

        throw new Error(errorMessage);
      } else {
        const errorMessage = `Entity id not found in local state. Has entity been created yet?`;
        eventHub.emit('error', new OneSDKError(errorMessage, e));

        throw new Error(errorMessage);
      }
    }
    const isPreloaded = Boolean(business?.organization?.entityId);
    state$.next({
      organization: business.organization ?? { details: {} },
      isLoading: false,
    });
    isLoading$.setValue(false);
    eventHub.emit('data_loaded', {
      entityId: client.entityId,
      reference: client.reference,
      isPreloaded,
    });
  };
};
