import merge from 'lodash.merge';

import type {
  Document,
  Scan,
  TScan,
} from '@module/common/shared/models/Document';
import type { DeepPartial } from '@module/common/shared/models/general';
import type { OCRDocumentType } from '@module/frankie-client/clients/OCRClient';
import { isOcrPreloaded } from '@module/ocr/actions/start';
import { OCRStatus } from '@module/types';

import { resolveScans, statusToScans } from './preloadedIndividual';

import type { SimplifiedMockOptions } from './SimplifiedMockOptions.type';
import type { OCRDocumentOptions } from '../../ocr/ocrSubmission';
import type { MockOptions } from '../MockOptions.type';

export type SimplifiedOCRFlow = {
  ocrFlow: {
    detectedType: OCRDocumentType;
    statusResults: (OCRStatus | SimplifiedOCRResult)[];
  };
};
export type SimplifiedOCRResult = {
  ocrResult?: Document['ocrResult'];
  ocrDocument?: PartialDocument;
  status: OCRStatus;
};

export function validateOCRFlowSimplifiedOptions(
  simplifiedOptions: Readonly<SimplifiedMockOptions>,
): asserts simplifiedOptions is SimplifiedOCRFlow {
  const updateStatusIncompleteMsg = `ocrFlow.statusResults is required to end in '${OCRStatus.COMPLETE}'.`;
  const missingUpdateStatusMsg = `If a preloadedIndividual.documents with a non void ocrResult.status different from '${OCRStatus.COMPLETE}' is specified, then ocrFlow.statusResults is required.`;
  const diffDetectedIdTypeMsg = `ocrFlow.detectedType needs to match the idType of any preloadedIndividual.documents with a ocrResult.status. In other words, the idType of a preloaded OCR document need to match the value provided in ocrFlow.detectedType`;
  const missingOptions = 'ocrFlow.detectedType must be provided';
  const ocrFlow = simplifiedOptions.ocrFlow;

  if (ocrFlow && !ocrFlow.detectedType) throw new Error(missingOptions);

  const documents = getPreloadedDocuments(simplifiedOptions);
  const statusResults = simplifiedOptions.ocrFlow?.statusResults ?? [];
  const detectedType = simplifiedOptions.ocrFlow?.detectedType;
  const incompletePreloadedOcr = documents.find(
    (d) => d.ocrResult?.status && d.ocrResult.status !== OCRStatus.COMPLETE,
  );
  // If has list of status, is last status a COMPLETE? Status list is required to end in COMPLETE
  if (
    statusResults.length &&
    resolveOCRStatus(statusResults[statusResults.length - 1]) !==
      OCRStatus.COMPLETE
  )
    throw new Error(updateStatusIncompleteMsg);
  // Has incomplete ocr document and no status list? Status list is required when ocr is not completed yet.
  if (incompletePreloadedOcr && !statusResults.length)
    throw new Error(missingUpdateStatusMsg);
  // Has incomplete ocr document with idType not matching ocrFlow.detectedType? idType and detectedType need to match
  if (incompletePreloadedOcr && detectedType !== incompletePreloadedOcr.idType)
    throw new Error(diffDetectedIdTypeMsg);
}
export function resolveSimplifiedOCRFlowMockOptions(
  simplifiedOptions: SimplifiedMockOptions,
): Partial<MockOptions> {
  const ocrFlow = simplifiedOptions.ocrFlow;
  if (!ocrFlow) return {};
  // If we have simplified definitions for the mocked OCR flow, we need to resolve them into a sequence of ocr requests, which is defined by OCRFlowOptions as
  // A single detectedType for the scaned document, a postResponsePayload which is the first request to be made to the OCR service, and an array of updateResponsePayloads,
  // which can go through multiple successful and failed statuses necessarily need to end in a succesfull COMPLETE status

  const hasPreexistingOCRResult =
    getPreloadedDocuments(simplifiedOptions).find(isOcrPreloaded);
  const docType = simplifiedOptions.ocrFlow?.detectedType;

  const allRequests: OCRDocumentOptions[] = ocrFlow.statusResults.map(
    (result, index, allResults) => {
      // convert the list of status results, which could be defined simply as a string or a full ocr document object, into
      // a OCRStatus enum and an ocrResults containing the data supposed to be extracted from the OCR run to this point
      // It will also contains the scans determined by the whole expected sequence of results, whose logic is implemented by statusToScans.
      const idType = ocrFlow.detectedType;
      const thisStatus = resolveOCRStatus(result);
      const allStatuses = allResults.map(resolveOCRStatus);
      const scans = resolveScans(
        statusToScans(thisStatus, index, allStatuses, docType),
      );
      // The final ocrDocument is a combination of the ocrDocument object present in the current status result and
      // the ocrResults added together all the way to the current index
      // all merged together, along with the calculated scans above
      const ocrResult = allResults
        .slice(0, index + 1)
        .map((res) => {
          if (typeof res === 'string') return {};
          return res.ocrResult ?? {};
        })
        .reduce(merge);
      const ocrDocument = allResults
        .slice(0, index + 1)
        .map((res) => {
          if (typeof res === 'string') return {};
          return res.ocrDocument ?? {};
        })
        .reduce(merge);
      return {
        status: thisStatus,
        ocrDocument: Object.assign(ocrDocument, {
          idType,
          ocrResult,
          scans,
        }),
      };
    },
  );
  let postRequest: OCRDocumentOptions | undefined,
    updateRequests: OCRDocumentOptions[];
  if (hasPreexistingOCRResult) updateRequests = allRequests;
  else {
    postRequest = allRequests.splice(0, 1).shift();
    updateRequests = allRequests;
  }
  const resolvedOcrRequests: {
    detectedType?: OCRDocumentType;
    postResponsePayload: OCRDocumentOptions | null;
    updateResponsePayloads: OCRDocumentOptions[];
  } = {
    detectedType: docType,
    postResponsePayload: null,
    updateResponsePayloads: [],
  };
  if (postRequest) resolvedOcrRequests.postResponsePayload = postRequest;
  if (updateRequests)
    resolvedOcrRequests.updateResponsePayloads = updateRequests;

  return {
    ocrRequests: resolvedOcrRequests,
  };
}

const getPreloadedDocuments = (simplifiedOptions: SimplifiedMockOptions) => {
  const { preloadedIndividual } = simplifiedOptions;
  if (!preloadedIndividual) return [];
  return preloadedIndividual.documents ?? [];
};
export const resolveOCRStatus = (
  statusResult: OCRStatus | SimplifiedOCRResult,
) => (typeof statusResult === 'string' ? statusResult : statusResult.status);

export type ScanSide = 'F' | 'B' | 'both' | 'none';
export type ScanObject = DeepPartial<TScan> & { side: 'F' | 'B' };
export type PartialDocument = DeepPartial<Omit<Document, 'scans'>> & {
  scans?: ScanSide | ScanObject[] | Scan[];
};
