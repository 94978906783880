/* eslint-disable check-file/filename-naming-convention */
import jwtDecode from 'jwt-decode';

import type { EventListener } from '@module/common';

type Options = Partial<{
  takenFromStorage: false | (() => void);
  appReference: string;
}>;

/**
 *
 */
export class SessionContext implements EventListener {
  #meta: SessionMeta;
  #token: string | null;
  #decodedToken: DecodedToken;
  #options: Options;
  #frankie2customer: boolean;

  /**
   * If tokenOrMeta is a string, it's the token itself and will be decoded into the {@link SessionMeta} object
   * If tokenOrMeta is the {@link SessionMeta} object itself, it's already decoded and will be used as is
   * @param tokenOrMeta Either the token string or the pre-decoded token payload
   * @param options Options object
   */
  constructor(
    tokenOrMeta: string | SessionMeta,
    frankie2customer: boolean = false,
    options?: Options,
  ) {
    this.#options = {
      takenFromStorage: options?.takenFromStorage ?? false,
      appReference: options?.appReference,
    };
    this.setFrankie2Customer(frankie2customer);

    if (typeof tokenOrMeta === 'string') {
      const frankie2customerFromToken = SessionContext.decodeToken(
        tokenOrMeta as string,
      );
      this.setToken(tokenOrMeta);
      if (!frankie2customer) {
        this.setFrankie2Customer(
          frankie2customerFromToken.data.frankie2customer ?? false,
        );
      }
    } else {
      this.#token = null;
      this.#meta = tokenOrMeta;
    }
  }

  on() {
    throw new Error('No implementation yet. This is WIP');
  }

  off() {
    throw new Error('No implementation yet. This is WIP');
  }

  isTakenFromStorage() {
    return Boolean(this.#options.takenFromStorage);
  }

  unpersist() {
    const cleanup = this.#options.takenFromStorage;
    if (typeof cleanup === 'function') return cleanup();
  }

  get appReference() {
    return this.#options.appReference;
  }

  get customerID() {
    return this.#meta.customerID;
  }

  get customerChildID() {
    return this.#meta.customerChildID;
  }

  get environment() {
    return this.#meta.environment;
  }

  get sessionId() {
    return this.#meta.sessionId;
  }

  get entityId() {
    return this.#meta.entityId;
  }

  get reference() {
    return this.#meta.reference;
  }

  get frankie2customer() {
    return this.#frankie2customer;
  }

  get token() {
    return this.#token;
  }

  get extraData() {
    return this.#decodedToken.data.extraData ?? null;
  }

  get expirationEpoch() {
    return this.#decodedToken.exp;
  }

  setFrankie2Customer(value: boolean) {
    this.#frankie2customer = value;
  }

  setToken(token: string) {
    this.#token = token;
    this.#decodedToken = SessionContext.decodeToken(token);
    // Ensure any discovered identitification is preserved
    const existingEntityId = this.#meta?.entityId ?? null;
    const existingReference = this.#meta?.reference ?? null;
    this.#meta = SessionContext.assembleMeta(this.#decodedToken);

    if (existingEntityId) this.#meta.entityId = existingEntityId;
    if (existingReference) this.#meta.reference = existingReference;
  }

  setEntityId(entityId: string | null) {
    this.#meta.entityId = entityId;
  }

  isValid() {
    return SessionContext.verifyExpiration(this.#decodedToken);
  }

  static assembleMeta({ data }: DecodedToken): SessionMeta {
    return {
      customerID: data.organisation.customerId,
      customerChildID: data.organisation.customerChildId ?? null,
      environment: data.environment,
      sessionId: data.sessionId,
      entityId: (data as { entityId }).entityId ?? null,
      reference: (data as { reference }).reference ?? null,
    };
  }

  static decodeToken(token: string): DecodedToken {
    return jwtDecode(token);
  }

  static verifyExpiration(decodedToken: DecodedToken): boolean {
    if (!decodedToken) return false;
    // Epoch seconds vs milliseconds
    const currentEpoch = Date.now();
    const expirationEpoch = decodedToken.exp * 1000;
    return currentEpoch <= expirationEpoch;
  }
}

export type SessionMeta = Readonly<{
  sessionId: string;
  customerID: string;
  customerChildID: string | null;
  environment: string;
}> &
  SomeEntityIdentification;
type SomeEntityIdentification =
  | { entityId: string; reference: string | null }
  | { reference: string; entityId: string | null };
type DecodedToken = { data: SessionTokenPayload; exp: number };

export const hasEntityId = (p: ApplicantId): p is { entityId: string } =>
  typeof (p as { entityId: string }).entityId === 'string';

export type ApplicantId = { entityId: string } | { reference: string };
export type SessionTokenPayload = {
  organisation: {
    customerId: string;
    customerChildId?: string;
  };
  sessionId: string;
  environment: string;
  extraData?: Record<string, unknown>;
  frankie2customer: boolean;
} & ApplicantId;
