import type {
  driversLicensePage,
  mediCarePage,
  passportPage,
  docUploadPage,
  nationalIdPage,
} from '@module/form/vendors/react/features';

import type { ActionContent, DocumentKeys } from '../appConfig.type';
import type { FrankieFormProps } from '../components/view/FrankieFormButton/FrankieFormButton';
import type { consentPage, idSelectionPage, welcomePage } from '../features';
import type { manualConsentPage } from '../features/Consent/manualConsent.config';
import type { loadingPage } from '../features/Loading/loading.config';
import type { personalDetailsPage } from '../features/PersonalDetails/personalDetails.config';
import type { resultPage } from '../features/Result/result.config';
import type { retryPage } from '../features/Retry/retry.config';
import type { reviewPage } from '../features/Review/review.config';
import type { manualWelcomePageAU1D } from '../features/Welcome/manualWelcome.config';
import type { LoaderDetails } from '../redux/slices/loaderSlice';

export enum React_Form_IDS {
  WELCOME = 'welcome',
  CONSENT = 'consent',
  DOCUMENT = 'document',
  REVIEW = 'review',
  PERSONAL = 'personal',
  LOADING = 'loading',
  RESULT = 'result',
  RETRY = 'retry',
  DOC_UPLOAD = 'doc_upload',
}

export type ReactFormIdsKeys = keyof typeof React_Form_IDS;
export type ReactFormIdsValues = typeof React_Form_IDS;

export type WrapperProps = {
  renderScreen: (props: {
    onValidate: (isValid: boolean) => void;
  }) => JSX.Element;
  loader?: LoaderDetails;
  onSubmit?: () => void;
  ctaInfo?: { cta: ActionContent };
  disabled?: boolean;
  inputClass?: string;
  hideCTA?: boolean;
  buttonConfig?: Partial<FrankieFormProps>;
  formWrapperClass?: string;
  inputButtonClass?: string;
  errorMessage?: string;
};

export type NewPageConfig = Partial<
  Record<
    React_Form_IDS.WELCOME,
    typeof welcomePage | typeof manualWelcomePageAU1D
  > &
    Record<
      React_Form_IDS.CONSENT,
      typeof consentPage | typeof manualConsentPage
    > &
    Record<React_Form_IDS.DOCUMENT, typeof idSelectionPage> &
    Record<React_Form_IDS.RETRY, typeof retryPage> &
    Record<React_Form_IDS.REVIEW, typeof reviewPage> &
    Record<React_Form_IDS.PERSONAL, typeof personalDetailsPage> &
    Record<React_Form_IDS.LOADING, typeof loadingPage> &
    Record<React_Form_IDS.RESULT, typeof resultPage> &
    Record<React_Form_IDS.DOC_UPLOAD, typeof docUploadPage>
> & {
  currentModule?: React_Form_IDS;
};

export type ModuleInfo = {
  element: () => JSX.Element | null;
  disabled: boolean;
  id: React_Form_IDS;
};

export type StaticScreenConfig = Partial<
  Record<DocumentKeys.DRIVERS_LICENCE, typeof driversLicensePage> &
    Record<DocumentKeys.PASSPORT, typeof passportPage> &
    Record<DocumentKeys.NATIONAL_HEALTH_ID, typeof mediCarePage> &
    Record<DocumentKeys.NATIONAL_ID, typeof nationalIdPage>
> & {
  currentModule?: React_Form_IDS;
};
