import { ICloneable } from '../general';
import { DatePayload, Date } from './Date';
import { Address as F1Address } from '../Address';
import { Core } from '../Core';

export enum AddressType {
  OTHER = 'OTHER',
  RESIDENTIAL = 'RESIDENTIAL',
  BUSINESS = 'BUSINESS',
  POSTAL = 'POSTAL',
  REGISTERED_OFFICE = 'REGISTERED_OFFICE',
  PLACE_OF_BUSINESS = 'PLACE_OF_BUSINESS',
  OFFICIAL_CORRESPONDANCE = 'OFFICIAL_CORRESPONDANCE',
  PLACE_OF_BIRTH = 'PLACE_OF_BIRTH',
  OFFICE_LOCALITY = 'OFFICE_LOCALITY',
}

export enum AddressStatus {
  CURRENT = 'CURRENT',
  PREVIOUS = 'PREVIOUS',
  FUTURE = 'FUTURE',
}

export interface AddressPayload {
  addressId?: string;
  type?: AddressType;
  typeDescription?: string;
  validFrom?: DatePayload;
  validTo?: DatePayload;
  longForm?: string;
  unitNumber?: string;
  buildingName?: string;
  streetName?: string;
  streetNumber?: string;
  streetType?: string;
  neighborhood?: string;
  locality?: string;
  district?: string;
  subdivision?: string;
  country: string;
  postalCode?: string;
  careOf?: string;
  status: AddressStatus;
}

export interface AddressConstructor {
  addressId?: string;
  type?: AddressType;
  typeDescription?: string;
  validFrom?: Date;
  validTo?: Date;
  longForm?: string;
  unitNumber?: string;
  buildingName?: string;
  streetName?: string;
  streetNumber?: string;
  streetType?: string;
  neighborhood?: string;
  locality?: string;
  district?: string;
  subdivision?: string;
  country: string;
  postalCode?: string;
  careOf?: string;
  status: AddressStatus;
}

export class Address implements AddressConstructor, ICloneable<Address> {
  addressId?: string;
  type?: AddressType;
  typeDescription?: string;
  validFrom?: Date;
  validTo?: Date;
  longForm?: string;
  unitNumber?: string;
  buildingName?: string;
  streetName?: string;
  streetNumber?: string;
  streetType?: string;
  neighborhood?: string;
  locality?: string;
  district?: string;
  subdivision?: string;
  country: string = '';
  postalCode?: string;
  careOf?: string;
  status: AddressStatus = AddressStatus.CURRENT;

  constructor();

  constructor(data: AddressConstructor);

  constructor(data?: AddressConstructor) {
    if (!data) return;
    this.addressId = data.addressId;
    this.type = data.type;
    this.typeDescription = data.typeDescription;
    this.validFrom = data.validFrom;
    this.validTo = data.validTo;
    this.longForm = data.longForm;
    this.unitNumber = data.unitNumber;
    this.buildingName = data.buildingName;
    this.streetName = data.streetName;
    this.streetNumber = data.streetNumber;
    this.streetType = data.streetType;
    this.neighborhood = data.neighborhood;
    this.locality = data.locality;
    this.district = data.district;
    this.subdivision = data.subdivision;
    this.country = data.country;
    this.postalCode = data.postalCode;
    this.careOf = data.careOf;
    this.status = data.status;
  }

  clone(): Address {
    const payload = JSON.parse(JSON.stringify(this));
    const address = Address.fromJSON(payload);
    return address;
  }

  toJSON(): AddressPayload {
    return {
      ...this,
      validFrom: this.validFrom?.toJSON(),
      validTo: this.validTo?.toJSON(),
    };
  }

  toF1Address(): F1Address {
    const getAddressType = (): Core['AddressObject']['addressType'] | null => {
      if (!this.type) return null;
      if (this.type.startsWith(AddressType.RESIDENTIAL)) {
        if (this.status === AddressStatus.CURRENT) {
          return 'RESIDENTIAL1';
        }
        return 'RESIDENTIAL2';
      }
      return this.type as Core['AddressObject']['addressType'];
    };

    return new F1Address({
      buildingName: this.buildingName ?? null,
      postalCode: this.postalCode ?? null,
      state: this.subdivision ?? null,
      streetName: this.streetName ?? null,
      streetNumber: this.streetNumber ?? null,
      streetType: this.streetType ?? null,
      suburb: this.neighborhood ?? null,
      town: this.locality ?? null,
      unitNumber: this.unitNumber ?? null,
      addressType: getAddressType(),
      country: this.country,
      addressId: this.addressId ?? null,
      isAddedByCustomer: false,
    });
  }

  static fromJSON(payload: AddressPayload): Address {
    return new Address({
      ...payload,
      validFrom: payload.validFrom
        ? Date.fromJSON(payload.validFrom)
        : undefined,
      validTo: payload.validTo ? Date.fromJSON(payload.validTo) : undefined,
    });
  }

  static default(): Address {
    return new Address();
  }

  static fromF1Address = (
    applicantAddress: F1Address,
    previousIndividualAddress?: Address,
  ): Address => {
    const inferType = () => {
      if (!applicantAddress.addressType) return undefined;
      if (applicantAddress.addressType.startsWith(AddressType.RESIDENTIAL))
        return AddressType.RESIDENTIAL;
      return AddressType[applicantAddress.addressType];
    };

    const inferStatus = () => {
      if (
        applicantAddress.addressType?.startsWith(AddressType.RESIDENTIAL) &&
        Number.parseInt(
          applicantAddress.addressType.replace(AddressType.RESIDENTIAL, ''),
        ) > 1
      ) {
        return AddressStatus.PREVIOUS;
      }
      return previousIndividualAddress?.status ?? AddressStatus.CURRENT;
    };

    return new Address({
      ...previousIndividualAddress,
      addressId: applicantAddress.addressId ?? undefined,
      type: inferType(),
      longForm: applicantAddress.longForm ?? undefined,
      unitNumber: applicantAddress.unitNumber ?? undefined,
      buildingName: applicantAddress.buildingName ?? undefined,
      streetName: applicantAddress.streetName ?? undefined,
      streetNumber: applicantAddress.streetNumber ?? undefined,
      streetType: applicantAddress.streetType ?? undefined,
      neighborhood: applicantAddress.suburb ?? undefined,
      locality: applicantAddress.town ?? undefined,
      subdivision: applicantAddress.state ?? undefined,
      country: applicantAddress.country ?? '',
      postalCode: applicantAddress.postalCode ?? undefined,
      status: inferStatus(),
    });
  };
}
