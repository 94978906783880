import { ICloneable } from '../general';

export enum ConsentType {
  GENERAL = 'GENERAL',
  DOCS = 'DOCS',
  CREDITHEADER = 'CREDITHEADER',
  UNDER18 = 'UNDER18',
  PAYROLL = 'PAYROLL',
  INSURANCE = 'INSURANCE',
  SUPERANNUATION = 'SUPERANNUATION',
}

export interface ConsentPayload {
  type?: ConsentType;
  schemaVersion?: number;
  sourceId?: string;
}

export class Consent implements ConsentPayload, ICloneable<Consent> {
  type?: ConsentType;
  schemaVersion?: number;
  sourceId?: string;

  constructor();

  constructor(data: ConsentPayload);

  constructor(data?: ConsentPayload) {
    if (!data) return;
    this.type = data.type;
    this.schemaVersion = data.schemaVersion;
    this.sourceId = data.sourceId;
  }

  clone(): Consent {
    const payload = JSON.parse(JSON.stringify(this));
    const consent = Consent.fromJSON(payload);
    return consent;
  }

  toJSON(): ConsentPayload {
    return { ...this };
  }

  static fromJSON(payload: ConsentPayload): Consent {
    return new Consent(payload);
  }

  static default(): Consent {
    return new Consent();
  }
}
