import type { Applicant } from '@module/common/shared/models/Applicant';
import {
  Profile,
  type ProfileTypeEnumerated,
} from '@module/common/shared/models/Profile';
import type { Accessors } from '@module/types';

import type { IndividualModule } from '../definition';

type SetProfileType = IndividualModule['moduleContext']['setProfileType'];
type GetProfileType = IndividualModule['moduleContext']['getProfileType'];

type MkSetProfileType = {
  applicant$: Accessors<Applicant>;
};

type MkGetProfileType = MkSetProfileType;

export function mkSetProfileType(deps: MkSetProfileType): SetProfileType {
  const { applicant$ } = deps;

  const setProfileType = (profileType: ProfileTypeEnumerated) => {
    const applicant = applicant$.getValue();
    if (!applicant.profile) applicant.profile = new Profile(profileType);
    else applicant.profile.profileType = profileType;
    applicant$.setValue(applicant);
  };
  return setProfileType;
}

export function mkGetProfileType(deps: MkGetProfileType): GetProfileType {
  const { applicant$ } = deps;

  const getProfileType = () => {
    const applicant = applicant$.getValue();
    return applicant?.profile?.profileType;
  };
  return getProfileType;
}
