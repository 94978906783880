/* eslint-disable check-file/filename-naming-convention */
import type { SessionContext } from './SessionContext';

/**
 * SessionFacade simply exposes the session module's public API. Nothing that allows customers to trick our service into accepting requests it's not supposed to
 */
export class SessionFacade {
  constructor(private readonly sessionContext: SessionContext) {}

  getEntityId(): string | null {
    return this.sessionContext.entityId;
  }

  getReference(): string | null {
    return this.sessionContext.reference;
  }

  getFrankie2customer() {
    return this.sessionContext.frankie2customer;
  }

  getSessionId(): string {
    return this.sessionContext.sessionId;
  }

  isPersisted(): boolean {
    return this.sessionContext.isTakenFromStorage();
  }

  unpersist(): void {
    return this.sessionContext.unpersist();
  }

  getExtraData(): {
    requestedDocuments?: string;
    senderName?: string;
    linkExpiry?: string;
  } {
    return this.sessionContext?.extraData ?? {};
  }

  getExpirationEpoch(): number {
    return this.sessionContext.expirationEpoch;
  }
}
