import { ICloneable } from '../general';

export enum ExternalReferenceType {
  SYSTEM = 'SYSTEM',
  CUSTOMER = 'CUSTOMER',
  OTHER = 'OTHER',
}

export interface ExternalReferencePayload {
  type?: ExternalReferenceType;
  name: string;
  value: string;
  description?: string;
}

export class ExternalReference
  implements ExternalReferencePayload, ICloneable<ExternalReference>
{
  type?: ExternalReferenceType;
  name: string = '';
  value: string = '';
  description?: string;

  constructor();

  constructor(data: ExternalReferencePayload);

  constructor(data?: ExternalReferencePayload) {
    if (!data) return;
    this.type = data.type;
    this.name = data.name;
    this.value = data.value;
    this.description = data.description;
  }

  clone(): ExternalReference {
    const payload = JSON.parse(JSON.stringify(this));
    const externalReference = ExternalReference.fromJSON(payload);
    return externalReference;
  }

  toJSON(): ExternalReferencePayload {
    return { ...this };
  }

  static fromJSON(payload: ExternalReferencePayload): ExternalReference {
    return new ExternalReference(payload);
  }

  static default(): ExternalReference {
    return new ExternalReference();
  }
}
