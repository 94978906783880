import type { Vendor } from '@module/federation/constants';

import { BaseClient } from './BaseClient';
import { FederationClientF2 } from './F2/FederationClientF2';

import type { FrankieApiClient } from '../FrankieApiClient';

export class FederationClient extends BaseClient {
  constructor(frankie: FrankieApiClient) {
    super(frankie);
    if (frankie.frankie2customer) {
      new FederationClientF2(frankie);
      return;
    }
  }

  async provideAuthorisation(
    provider: Vendor,
    authCode: string,
  ): Promise<AuthorisationPayload> {
    // TODO: This whole method
    return await this.frankie
      .get<AuthorisationPayload>(
        `/federation/v1/callback/${provider}?authCode=${authCode}`,
      )
      .then((d) => d.data);
  }
  submitCachedData() {
    return this.frankie
      .post('/federation/v1/singpass/entity')
      .then((d) => d.data);
  }
}

type AuthorisationPayload = { individual: object; status: string };
