import type { Document } from '@module/common/shared/models/Document';
import type { FormModule } from '@module/form/definition';

/**
 * Convert the Form execution into a promise. This function will mount the
 * Form and wait for user completion, resulting in a Document object
 * @param form The Form module instance object generated by calling component('form')
 * @param args The expected document id to be found after form emits results
 * @returns The document object containing the specified document id
 */
export async function promisifyForm(
  form: FormModule['moduleInstance'],
  args: {
    documentId?: string | null;
    mountElement: HTMLElement;
  },
) {
  const { documentId, mountElement } = args;
  return new Promise<Document | null>((resolve, reject) => {
    const findDocument = (d: Document) => d.documentId === documentId;
    form.on('results', ({ documents }) => {
      const updatedDocument = documents.find(findDocument);
      resolve(updatedDocument ?? null);
    });
    form.on('error', reject);
    form.mount(mountElement);
  });
}
