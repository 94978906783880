import { FullNameFields } from '../Applicant';
import { ICloneable } from '../general';

export interface NamePayload {
  nameId?: string;
  language?: string;
  givenName?: string;
  middleName?: string;
  familyName: string;
  otherName?: string;
  prefix?: string;
  suffix?: string;
  displayName?: string;
}

export class Name implements NamePayload, ICloneable<Name> {
  nameId?: string;
  language?: string;
  givenName?: string;
  middleName?: string;
  familyName: string = '';
  otherName?: string;
  prefix?: string;
  suffix?: string;
  displayName?: string;

  constructor(data?: NamePayload) {
    if (!data) return;
    this.nameId = data.nameId;
    this.language = data.language;
    this.givenName = data.givenName;
    this.middleName = data.middleName;
    this.familyName = data.familyName;
    this.otherName = data.otherName;
    this.prefix = data.prefix;
    this.suffix = data.suffix;
    this.displayName = data.displayName;
  }

  clone(): Name {
    const payload = JSON.parse(JSON.stringify(this));
    const name = Name.fromJSON(payload);
    return name;
  }

  toJSON(): Name {
    return { ...this };
  }

  static fromJSON(payload: NamePayload): Name {
    return new Name(payload);
  }

  static default(): Name {
    return new Name();
  }

  static fromF1Name(f1Name: FullNameFields, previousName?: Name): Name {
    return new Name({
      ...previousName,
      givenName: f1Name.givenName ?? undefined,
      middleName: f1Name.middleName ?? undefined,
      familyName: f1Name.familyName ?? '',
      displayName: f1Name.displayName ?? undefined,
    });
  }
}

export enum AlternateNameType {
  OTHER = 'OTHER',
  ALIAS = 'ALIAS',
  PREVIOUS = 'PREVIOUS',
}

export interface AlternateNamePayload extends NamePayload {
  type?: AlternateNameType;
}

export class AlternateName implements AlternateNamePayload, ICloneable<Name> {
  nameId?: string;
  language?: string;
  givenName?: string;
  middleName?: string;
  familyName: string = '';
  otherName?: string;
  prefix?: string;
  suffix?: string;
  displayName?: string;
  type?: AlternateNameType;

  constructor();

  constructor(data: AlternateNamePayload);

  constructor(data?: AlternateNamePayload) {
    this.nameId = data.nameId;
    this.language = data.language;
    this.givenName = data.givenName;
    this.middleName = data.middleName;
    this.familyName = data.familyName ?? '';
    this.otherName = data.otherName;
    this.prefix = data.prefix;
    this.suffix = data.suffix;
    this.displayName = data.displayName;
    this.type = data.type;
  }

  clone(): AlternateName {
    const payload = JSON.parse(JSON.stringify(this));
    const name = AlternateName.fromJSON(payload);
    return name;
  }

  toJSON(): AlternateName {
    return { ...this };
  }

  static fromJSON(payload: AlternateNamePayload): AlternateName {
    return new AlternateName(payload);
  }

  static default(): AlternateName {
    return new AlternateName();
  }
}
