import type { Accessors } from '@module/common';
import type { Document } from '@module/common/shared/models/Document';

import type { IndividualModule } from '../definition';

type IndividualModuleContext = IndividualModule['moduleContext'];
type MkAddDocumentOptions = {
  documents$: Accessors<Document[]>;
};
export const mkAddDocument =
  (deps: MkAddDocumentOptions): IndividualModuleContext['addDocument'] =>
  (document) => {
    const { documents$ } = deps;
    const allDocuments = documents$.getValue();
    document.isPersisted = false;
    allDocuments.push(document);
    documents$.setValue(allDocuments);
  };

export const mkUpdateDocument =
  (deps: MkAddDocumentOptions): IndividualModuleContext['updateDocument'] =>
  (id, document) => {
    const { documents$ } = deps;
    const allDocuments = documents$.getValue();
    const index = allDocuments.findIndex((d) => d.documentId === id);

    document.documentId = id;
    document.isPersisted = false;

    if (index === -1) {
      mkAddDocument(deps)(document);
    } else {
      allDocuments[index] = document;
      documents$.setValue(allDocuments);
    }
  };

/** @deprecated This is only for form-io and should not be used. use `mkUpdateDocument` instead */
export const mkFormIOUpdateDocument =
  (deps: MkAddDocumentOptions): IndividualModuleContext['updateDocument'] =>
  (id, document) => {
    const { documents$ } = deps;
    const allDocuments = documents$.getValue();
    const index = allDocuments.findIndex((d) => d.documentId === id);

    document.documentId = id;
    document.isPersisted = false;

    if (index === -1) {
      throw new Error(`Document with id ${id} not found`);
    } else {
      allDocuments[index] = document;
      documents$.setValue(allDocuments);
    }
  };
