/* eslint-disable check-file/filename-naming-convention */

import type { RecipeConfiguration } from '@module/common';
import { OneSDKError } from '@module/common';
import { defineWrapper } from '@module/common/modules/defineModule';
import { LocalStorage } from '@module/common/modules/LocalStorage';
import { getURLParams } from '@module/common/modules/url';
import type { InjectedState } from '@module/common/types';
import { FederationClient } from '@module/frankie-client/clients/FederationClient';
import { StorageKeys } from '@module/session/constants';

import type { FederationModule } from '../definition';
import type { AxiosError } from 'axios';

//   302 Service will redirect all responses to 'redirect_uri' with additional parameters added as response results. Expected parameters include:

// code: this is the authorisation code you will use when calling the token endpoint
// state: this should be the same state passed in your initial URL.
// error: if there are any errors encountered, the error code will be given in this parameter.
//      '500' - Unknown or other server side errors.
//      '503' - MyInfo under maintenance. Error description will also be given in error_description parameter.
//      'access_denied' - When user did not give consent, refer to error_description parameter for the reason.
// error_description: if error is 'access_denied' i.e. user did not give consent, the description will be 'Resource Owner did not authorize the request'.

// Note: If user closes the browser window prematurely, there will be no callback to the 'redirect_uri'. Therefore it is important for you to check the 'state' to verify that the transaction is the same.

export const initialise = defineWrapper<FederationModule>(
  'singpass',
  async (shared, options) => {
    const urlParams = getURLParams();
    if (hasAllAuthorisationParams(urlParams, shared))
      await submitAuthorization(urlParams, shared, options);
    else await authorize(shared);
  },
);

async function submitAuthorization(
  urlParams: URLSearchParams | null,
  shared: InjectedState,
  options: FederationModule['wrapperOptions'],
) {
  // Submit data to BFF
  const client = new FederationClient(shared.frankieClient);
  const clearStoredToken = () => new LocalStorage().delete(StorageKeys.TOKEN);
  const emitError = (e: { message; payload }) => {
    options.eventHub.emit('error', new OneSDKError(e.message, e.payload));
  };
  try {
    // If initial request fails, it will contain an object in the form { message, errorCode }
    // On failure, this request interrupts the flow with an error event containing
    // the message and an error payload containing "errorCode" AND clears any stored session
    const { individual, status } = await client.provideAuthorisation(
      'singpass',
      urlParams?.get('code') ?? null,
    );
    // If customer decides to call "approve", submit confirmation to BFF
    // otherwise data will automatically be purged after a few minutes
    const approve = () => {
      client
        .submitCachedData()
        .then((data: { entityId?: string }) => {
          options.eventHub.emit('data_extracted_successfully', {
            entityId: data.entityId,
          });
        })
        .catch((e) => {
          const { message, errorCode, issues } = e.response.data;
          emitError({
            message: `Failed sending confirmation: "${message}"`,
            payload: { errorCode, issues },
          });
        })
        .finally(clearStoredToken);
    };
    options.eventHub.emit('results', individual, status, approve);
  } catch (e) {
    clearStoredToken();
    const { message, errorCode, issues } =
      (
        e as AxiosError<{
          message: string;
          errorCode: string;
          issues: object[];
        }>
      ).response?.data ?? {};
    emitError({
      message: `Failed sending authorisation: "${message}"`,
      payload: { errorCode, issues },
    });
  }
}

async function authorize(shared: InjectedState) {
  const singpassConfig = shared.recipe.idps?.singpass;
  // Set query parameters
  const url = getSingpassURL(singpassConfig?.environment ?? 'test');
  url.searchParams.set('client_id', String(singpassConfig?.client_id));
  url.searchParams.set('attributes', String(singpassConfig?.attributes));
  url.searchParams.set('state', shared.session.sessionId); // Take at runtime from customer
  url.searchParams.set(
    'redirect_uri',
    singpassConfig?.redirect_url ?? window.location.href,
  );
  url.searchParams.set('purpose', String(singpassConfig?.purpose));
  // Using FrankieClient for redirection so in dummy mode we can intercept it and fake a redirection
  shared.frankieClient.redirect(url);
}
export function hasAllAuthorisationParams(
  urlParams: URLSearchParams | null,
  shared: InjectedState,
): boolean {
  const stateMatches = shared.session.sessionId === urlParams?.get('state');

  const authCode = urlParams?.get('code');
  const error = urlParams?.get('error');
  const errorInfo = urlParams?.get('error_description');

  if (error) throw new Error(errorInfo ?? 'Internal server error');
  if (authCode && !stateMatches)
    throw new Error(
      'A different session is being used to initialise the OneSDK than the one used at the beginning of the federation flow.',
    );

  return !!authCode && stateMatches;
}

export type SingpassEnvironment = NonNullable<
  NonNullable<
    NonNullable<RecipeConfiguration['idps']>['singpass']
  >['environment']
>;
export type SingpassEnvironmentURLDictionary = Record<
  SingpassEnvironment,
  string
>;
export const singpassURLs: SingpassEnvironmentURLDictionary = {
  sandbox: 'https://sandbox.api.myinfo.gov.sg',
  test: 'https://test.api.myinfo.gov.sg',
  production: 'https://api.myinfo.gov.sg',
} as const;
export function getSingpassURL(environment: SingpassEnvironment): URL {
  const urlString = [singpassURLs[environment], 'com/v3/authorise'].join('/');
  return new URL(urlString);
}
