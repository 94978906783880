import {
  getAustralianStateDetails,
  getCountryDetails,
  isEmptyAddress,
} from '../utils';

import { Core } from './Core';

export interface IAddressPayload {
  buildingName: string | null;
  postalCode: string | null;
  state: string | null;
  streetName: string | null;
  streetNumber: string | null;
  streetType: string | null;
  suburb: string | null;
  town: string | null;
  unitNumber: string | null;
  addressType: Core['AddressObject']['addressType'] | null;
  country: string | null;
  addressId: string | null;
  longForm?: string | null;
  data?: { [k: string]: any };
  isAddedByCustomer?: boolean | null;
}

export class Address implements IAddressPayload {
  buildingName: string | null = null;
  postalCode: string | null = null;
  state: string | null = null;
  streetNumber: string | null = null;
  streetType: string | null = null;
  suburb: string | null = null;
  town: string | null = null;
  unitNumber: string | null = null;
  addressType: Core['AddressObject']['addressType'] | null = null;
  _streetName: string | null = null;
  country: string | null = null;
  addressId: string | null = null;
  data?: { [k: string]: any };
  isAddedByCustomer: boolean | null = null;

  constructor();
  constructor(a: IAddressConstructor);
  constructor(a?: IAddressConstructor) {
    if (!a) return;
    this.buildingName = a.buildingName || null;
    this.postalCode = a.postalCode || null;
    this.state = a.state || null;
    this.streetNumber = a.streetNumber || null;
    this.streetType = a.streetType || null;
    this.suburb = a.suburb || null;
    this.town = a.town || null;
    this.unitNumber = a.unitNumber || null;
    this.addressType = a.addressType || null;
    this._streetName = a.streetName || null;
    this.country = a.country || null;
    this.addressId = a?.addressId?.toLowerCase() || null;
    this.isAddedByCustomer = a?.isAddedByCustomer || null;
  }

  set streetName(name: string | null) {
    if (name !== null && this.data) this.data.longForm = null;
    this._streetName = name;
  }

  get streetName(): string | null {
    return this._streetName ?? null;
  }

  get isIncomplete(): boolean {
    return isEmptyAddress(this);
  }

  hasExplicitLongForm(): boolean {
    return Boolean(this.data?.longForm);
  }

  set longForm(value: string | null) {
    // save longForm to data
    if (this.data) this.data.longForm = value;
    else
      this.data = {
        longForm: value,
      };
  }

  get longForm(): string | null {
    console.log('getLongform', this.data);
    if (this.data?.longForm) return this.data.longForm ?? null;
    if (this.isIncomplete) return null;
    const {
      buildingName,
      unitNumber,
      streetNumber,
      streetName,
      streetType,
      suburb,
      state,
      postalCode,
      country,
      town,
    } = this;

    const stateName = (() => {
      if (!state) return null;
      if (country === 'AUS')
        return getAustralianStateDetails(state)?.label ?? state;
      return state;
    })();
    const countryName = (country && getCountryDetails(country)?.label) || null;
    const numbers = [unitNumber, streetNumber].filter(Boolean).join('/');
    const unitLine = [buildingName, numbers].filter(Boolean).join(', ');
    const streetLine = [unitLine, streetName, streetType]
      .filter(Boolean)
      .join(' ');
    const stateLine = [town, stateName, postalCode].filter(Boolean).join(' ');

    return [streetLine, suburb, stateLine, countryName]
      .filter(Boolean)
      .join(', ');
  }
  clone(): Address {
    const payload = this.toJSON();
    const instance = Address.fromJSON(payload);
    return instance;
  }
  toJSON(): IAddressPayload {
    let addressFields = Object.keys(new Address());
    addressFields = addressFields.filter(
      (i) => !['country', 'addressType', 'addressId'].includes(i),
    );

    // this might not be relevant anymore
    const isAddressHasCountryOnly = addressFields.every(
      (field) => !this[field],
    );
    //if address only has country, other field is empty, don't send longForm
    if (isAddressHasCountryOnly) return { ...this };
    // until here

    const { _streetName, ...thisRest } = this;
    return {
      ...thisRest,
      streetName: this.streetName,
      longForm: this.hasExplicitLongForm() ? this.longForm : undefined,
    };
  }
  static fromJSON(payload: IAddressPayload): Address {
    const newAddress = new Address();
    newAddress.buildingName = payload.buildingName;
    newAddress.unitNumber = payload.unitNumber;
    newAddress.streetNumber = payload.streetNumber;
    newAddress.streetName = payload.streetName;
    newAddress.streetType = payload.streetType;
    newAddress.suburb = payload.suburb;
    newAddress.state = payload.state;
    newAddress.postalCode = payload.postalCode;
    newAddress.country = payload.country;
    newAddress.town = payload.town;
    newAddress.addressId = payload.addressId;
    newAddress.addressType = payload.addressType;
    newAddress.data = payload.data ?? {};
    newAddress.isAddedByCustomer = payload.isAddedByCustomer ?? null;
    return newAddress;
  }
  static default(): Address {
    const newAddress = new Address();
    newAddress.buildingName = null;
    newAddress.unitNumber = null;
    newAddress.streetNumber = null;
    newAddress.streetName = null;
    newAddress.streetType = null;
    newAddress.suburb = null;
    newAddress.state = null;
    newAddress.postalCode = null;
    newAddress.country = null;
    newAddress.town = null;
    newAddress.addressId = null;
    newAddress.addressType = null;
    newAddress.isAddedByCustomer = null;
    return newAddress;
  }
}

export function addressTypeSorter(a, b) {
  const getPoints = (t) => {
    const type = t?.toLowerCase() || null;
    if (type === 'residential1') return 0;
    if (type === 'residential') return 1;
    const match = type?.match(/residential([0-9]+)/);
    if (match) return parseInt(match[1]);
    return Infinity;
  };
  return getPoints(a) - getPoints(b);
}

export interface IAddressConstructor {
  buildingName: string | null;
  postalCode: string | null;
  state: string | null;
  streetName: string | null;
  streetNumber: string | null;
  streetType: string | null;
  suburb: string | null;
  town: string | null;
  unitNumber: string | null;
  addressType: Core['AddressObject']['addressType'] | null;
  country: string | null;
  addressId: string | null;
  isAddedByCustomer: boolean | null;
}
