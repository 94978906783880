import { mkEventHub, mkEventListener } from '@module/common';
import type {
  AnyModuleInitialiseFunction,
  BrandedInitialiseFunction,
  InjectedModuleParameters,
  ModuleDefinition,
  ModuleInitialiseFunction,
} from '@module/common/types';
import { brandInitialiseFunction } from '@module/common/types';

/**
 * This simple function takes a module name and its initialise function and adds extra capabilities to it.
 * - It enforces the type of the module's initialise function, as defined by the Module type definition.
 * - It initialises and injects commonly created variables to reduce boilerplate:
 * - - localEventHub: an eventHub used by the module to communicate with other modules and the host application
 * - - - This event hub will also be used to decorate the context object with a event listener (methods on and off)
 * @param name module name used as an object key for storage
 * @param initialise the module's initialise function, which will be wrapped to inject the parameters above and which returned context object will be extended with a listener
 * @returns the same module initialise function provided above
 */
export const defineModule = <M extends ModuleDefinition>(
  name: M['moduleName'],
  initialise: RawInitialise<M>,
): BrandedInitialiseFunction<M> => {
  // Inject new parameters and context to avoid needing to do that manually in each module every single time
  // - eventHub (passed as localEventHub) is used by all modules to communicate with each other and with the host application
  // - every module returns a context object containing a listener for its own local eventHub
  // TODO: Create an extra eventhub "customerEventListener" where:
  // - "localEventHub" pipes/maps events into "customerEventListener"
  // - customerEventListener is not passed down to the module and is used only by the host application to listen to events piped into it
  const injectedParameters = {
    localEventHub: mkEventHub(),
  };
  const injectedContext = {
    ...mkEventListener(injectedParameters.localEventHub),
  };
  // Wrap the provided initialise function with a new function which injects the parameters above
  const wrappedInitialise = (globalState: object, options: object) => {
    // Injecting parameters into shared, in case callers rely on the object reference (mainly tests)
    const mainParameters = Object.assign(globalState, injectedParameters);
    // Since we can't know the signature of the initialise function at this point, use the most generic signature when injecting parameters.
    const context = (initialise as AnyModuleInitialiseFunction)(
      mainParameters,
      options,
    );
    // Merge returned context with the injected context, which allows us to provide a listener wrapper automatically for all modules
    // This means all modules will contain listener methods (on off), but not all categories will use them and therefore they are not part of the context's interface
    return Object.assign(context ?? {}, injectedContext);
  };

  const branded = brandInitialiseFunction<M>(
    name,
    wrappedInitialise as M['moduleInitialiseFunction'],
  );
  return branded;
};

/** RawInitialise type is used internally to provide type safety to the function provided to the defineModule function, before it's returned type is extended */
export type RawInitialise<Module extends ModuleDefinition> =
  ModuleInitialiseFunction<
    Module['category'],
    Module['moduleContext'],
    Module['moduleOptions'],
    InjectedModuleParameters<Module>
  >;

/**
 * This simple function takes a wrapper initialise function and returns it, simply enforcing types defined in the Module type definition
 * @param initialise The wrapper's initialise function
 * @returns The same wrapper initialise function provided above
 */
export const defineWrapper = <W extends ModuleDefinition>(
  name: W['vendorName'],
  initialise: W['wrapperInitialiseFunction'],
) => Object.assign(initialise, { vendorName: name });

export type WrapperParameters<W extends ModuleDefinition> = Parameters<
  W['wrapperInitialiseFunction']
>;
export type ModuleParameters<W extends ModuleDefinition> = Parameters<
  W['moduleInitialiseFunction']
>;
