import { Scan, TScan } from '../Document';
import { ICloneable } from '../general';
import { CustomAttribute, CustomAttributeRecord } from './CustomAttribute';
import { Date, DatePayload } from './Date';
import { Document as F1Document } from '../Document';
import { Core } from '../Core';

export interface HealthIdDocumentSupplementaryData {
  type: 'NATIONAL_HEALTH_ID';
  reference?: string;
  nameOnCardLine1?: string;
  nameOnCardLine2?: string;
  nameOnCardLine3?: string;
  nameOnCardLine4?: string;
  middleNameOnCard?: string;
}

export enum DocumentType {
  OTHER = 'OTHER',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  PASSPORT = 'PASSPORT',
  VISA = 'VISA',
  IMMIGRATION = 'IMMIGRATION',
  NATIONAL_ID = 'NATIONAL_ID',
  TAX_ID = 'TAX_ID',
  NATIONAL_HEALTH_ID = 'NATIONAL_HEALTH_ID',
  CONCESSION = 'CONCESSION',
  HEALTH_CONCESSION = 'HEALTH_CONCESSION',
  PENSION = 'PENSION',
  MILITARY_ID = ' MILITARY_ID',
  BIRTH_CERT = 'BIRTH_CERT',
  CITIZENSHIP = 'CITIZENSHIP',
  MARRIAGE_CERT = 'MARRIAGE_CERT',
  DEATH_CERT = 'DEATH_CERT',
  NAME_CHANGE = 'NAME_CHANGE',
  UTILITY_BILL = 'UTILITY_BILL',
  BANK_STATEMENT = 'BANK_STATEMENT',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  INTENT_PROOF = 'INTENT_PROOF',
  ATTESTATION = 'ATTESTATION',
  SELF_IMAGE = 'SELF_IMAGE',
  DEVICE = 'DEVICE',
  VEHICLE_REGISTRATION = 'VEHICLE_REGISTRATION',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  HOUSE_REGISTRATION = 'HOUSE_REGISTRATION',
  YELLOW_HOUSE_REGISTRATION = 'YELLOW_HOUSE_REGISTRATION',
  WORK_PERMIT = 'WORK_PERMIT',
  EMPLOYMENT_CERTIFICATE = 'EMPLOYMENT_CERTIFICATE',
  NOTARY_PUBLIC_ID = 'NOTARY_PUBLIC_ID',
  EXTERNAL_ADMIN = 'EXTERNAL_ADMIN',
  CHARGES = 'CHARGES',
  PRE_ASIC = 'PRE_ASIC',
  ANNUAL_RETURN = 'ANNUAL_RETURN',
  REPORT = 'REPORT',
  TRUST_DEED = 'TRUST_DEED',
  PARTNERSHIP_AGREEMENT = 'PARTNERSHIP_AGREEMENT',
  ADMIN_CHANGE = 'ADMIN_CHANGE',
  COMPANY_REPORT = 'COMPANY_REPORT',
  CHECK_RESULTS = 'CHECK_RESULTS',
  AVIATION_SECURITY_ID = 'AVIATION_SECURITY_ID',
  MARITIME_SECURITY_ID = 'MARITIME_SECURITY_ID',
}

export enum AttachmentSide {
  FRONT = 'FRONT',
  BACK = 'BACK',
}

export enum AttachmentType {
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  PDF = 'PDF',
  DOC = 'DOC',
  ZIP = 'ZIP',
}

export interface AttachmentPayload {
  attachmentId?: string;
  filename?: string;
  mimeType?: string;
  pageNumber?: number;
  side?: AttachmentSide;
  type?: AttachmentType;
  location?: string;
  data:
    | {
        uri: string;
      }
    | {
        base64: string;
      };
  lastMalwareScanAt?: string;
  createdAt?: string;
  updatedAt?: string;
}

export class Attachment implements AttachmentPayload, ICloneable<Attachment> {
  attachmentId?: string;
  filename?: string;
  mimeType?: string;
  pageNumber?: number;
  side?: AttachmentSide;
  type?: AttachmentType;
  location?: string;
  data:
    | {
        uri: string;
      }
    | {
        base64: string;
      } = { uri: '' };
  lastMalwareScanAt?: string;
  createdAt?: string;
  updatedAt?: string;

  constructor();

  constructor(data: AttachmentPayload);

  constructor(data?: AttachmentPayload) {
    if (!data) return;
    this.attachmentId = data.attachmentId;
    this.filename = data.filename;
    this.mimeType = data.mimeType;
    this.pageNumber = data.pageNumber;
    this.side = data.side;
    this.type = data.type;
    this.location = data.location;
    this.data = data.data;
    this.lastMalwareScanAt = data.lastMalwareScanAt;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  clone(): Attachment {
    const payload = JSON.parse(JSON.stringify(this));
    const attachment = Attachment.fromJSON(payload);
    return attachment;
  }

  toJSON(): AttachmentPayload {
    return { ...this };
  }

  toScan(): Scan {
    const getFile = () => {
      if ('uri' in this.data) return this.data.uri;
      if ('base64' in this.data) return this.data.base64;
    };

    return new Scan({
      scanId: this.attachmentId ?? null,
      file: getFile(),
      mimeType: this.mimeType,
      side: this.side?.[0],
      scanCreated: this.createdAt,
      scanName: this.filename,
    });
  }

  static fromJSON(payload: AttachmentPayload): Attachment {
    return new Attachment(payload);
  }

  static default(): Attachment {
    return new Attachment();
  }

  static fromScan = (
    scan: TScan,
    previousAttachment?: Attachment,
  ): Attachment => {
    const getData = () => {
      if (!scan.file) return { uri: '' };
      if (
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=?))?$/.test(
          scan.file,
        )
      )
        return { base64: scan.file };
      return { uri: scan.file };
    };

    return new Attachment({
      ...previousAttachment,
      attachmentId: scan.scanId ?? undefined,
      filename: scan.scanName ?? undefined,
      mimeType: scan.mimeType ?? undefined,
      side: scan.side === 'B' ? AttachmentSide.BACK : AttachmentSide.FRONT,
      data: getData(),
    });
  };
}

export enum DocumentClass {
  REPORT = 'REPORT',
  SUPPORTING = 'SUPPORTING',
  IDENTITY = 'IDENTITY',
  OTHER = 'OTHER',
}

export interface DocumentConstructor {
  documentId?: string;
  expiryDate?: Date;
  issueDate?: Date;
  primaryIdentifier?: string;
  secondaryIdentifier?: string;
  type: DocumentType;
  subType?: string;
  subdivision?: string;
  country: string;
  validFrom?: Date;
  validTo?: Date;
  supplementaryData?: HealthIdDocumentSupplementaryData;
  customAttributes?: CustomAttributeRecord;
  attachments?: Attachment[];
  requestedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  isPersisted?: boolean;
}

export interface DocumentPayload {
  documentId?: string;
  expiryDate?: DatePayload;
  issueDate?: DatePayload;
  primaryIdentifier?: string;
  secondaryIdentifier?: string;
  type: DocumentType;
  subType?: string;
  subdivision?: string;
  country: string;
  validFrom?: DatePayload;
  validTo?: DatePayload;
  supplementaryData?: HealthIdDocumentSupplementaryData;
  customAttributes?: Record<string, CustomAttribute>;
  attachments?: AttachmentPayload[];
  requestedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  isPersisted?: boolean;
}

export class Document implements DocumentConstructor, ICloneable<Document> {
  documentId?: string;
  expiryDate?: Date;
  issueDate?: Date;
  primaryIdentifier?: string;
  secondaryIdentifier?: string;
  type: DocumentType = DocumentType.OTHER;
  subType?: string;
  subdivision?: string;
  country: string = '';
  validFrom?: Date;
  validTo?: Date;
  supplementaryData?: HealthIdDocumentSupplementaryData;
  customAttributes?: CustomAttributeRecord;
  attachments?: Attachment[];
  requestedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  isPersisted: boolean = false;

  constructor();

  constructor(data: DocumentConstructor);

  constructor(data?: DocumentConstructor) {
    if (!data) return;
    this.documentId = data.documentId;
    this.expiryDate = data.expiryDate;
    this.issueDate = data.issueDate;
    this.primaryIdentifier = data.primaryIdentifier;
    this.secondaryIdentifier = data.secondaryIdentifier;
    this.type = data.type;
    this.subType = data.subType;
    this.subdivision = data.subdivision;
    this.country = data.country;
    this.validFrom = data.validFrom;
    this.validTo = data.validTo;
    this.supplementaryData = data.supplementaryData;
    this.customAttributes = data.customAttributes;
    this.attachments = data.attachments;
    this.requestedAt = data.requestedAt;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.isPersisted = data.isPersisted;
  }

  clone(): Document {
    const payload = JSON.parse(JSON.stringify(this));
    const document = Document.fromJSON(payload);
    return document;
  }

  toJSON(): DocumentPayload {
    return {
      ...this,
      customAttributes: this.customAttributes?.toJSON(),
      attachments: this.attachments?.map((attachment) => attachment.toJSON()),
    };
  }

  toF1Document(): F1Document {
    const customAttributeRecordAlterFromf1 = (
      customAttr?: CustomAttributeRecord,
    ) => {
      const filterCustomAttr = new CustomAttributeRecord(
        customAttr && customAttr.record ? { ...customAttr.record } : {},
      );
      const medicareSupplementaryData = this.supplementaryData;

      if (medicareSupplementaryData) {
        filterCustomAttr.record['display_name_line1'] = {
          type: 'general.string',
          value: medicareSupplementaryData.nameOnCardLine1,
        };
        filterCustomAttr.record['display_name_line2'] = {
          type: 'general.string',
          value: medicareSupplementaryData.nameOnCardLine2,
        };
        filterCustomAttr.record['display_name_line3'] = {
          type: 'general.string',
          value: medicareSupplementaryData.nameOnCardLine3,
        };
        filterCustomAttr.record['display_name_line4'] = {
          type: 'general.string',
          value: medicareSupplementaryData.nameOnCardLine4,
        };

        filterCustomAttr.record['reference'] = {
          type: 'general.string',
          value: medicareSupplementaryData.reference,
        };

        filterCustomAttr.record['display_middle_name'] = {
          type: 'general.string',
          value: medicareSupplementaryData.middleNameOnCard,
        };
      }

      const dlSecondaryIdentifier = this.secondaryIdentifier;

      if (this.type === DocumentType.DRIVERS_LICENSE && dlSecondaryIdentifier) {
        filterCustomAttr.record['document_number'] = {
          type: 'general.string',
          value: dlSecondaryIdentifier,
        };
      }

      return filterCustomAttr;
    };

    const filterCustomAttr = customAttributeRecordAlterFromf1(
      this.customAttributes ?? null,
    );

    return new F1Document({
      documentId: this.documentId ?? null,
      idType:
        this.type === DocumentType.DRIVERS_LICENSE
          ? 'DRIVERS_LICENCE'
          : (this.type as Core['enumIdType']),
      idSubType: this.subType ?? null,
      country: this.country,
      region: this.subdivision ?? null,
      idNumber: this.primaryIdentifier ?? null,
      idExpiry: this.expiryDate?.toDateString() ?? null,
      extraData: filterCustomAttr.toExtraData() ?? undefined,
      scans: this.attachments?.map((attachment) => attachment.toScan()),
      dateOfBirth: null,
      gender: null,
      isPersisted: this.isPersisted,
    });
  }

  static fromJSON(payload: DocumentPayload): Document {
    return new Document({
      ...payload,
      expiryDate: payload.expiryDate
        ? Date.fromJSON(payload.expiryDate)
        : undefined,
      issueDate: payload.issueDate
        ? Date.fromJSON(payload.issueDate)
        : undefined,
      validFrom: payload.validFrom
        ? Date.fromJSON(payload.validFrom)
        : undefined,
      validTo: payload.validTo ? Date.fromJSON(payload.validTo) : undefined,
      customAttributes: payload.customAttributes
        ? CustomAttributeRecord.fromJSON(payload.customAttributes)
        : undefined,
      attachments: payload.attachments?.map(Attachment.fromJSON),
    });
  }

  static default(): Document {
    return new Document();
  }

  static fromF1Document = (
    f1Document: F1Document,
    previousDocument?: Document,
  ): Document => {
    const getType = (): DocumentType => {
      if (f1Document.idType === 'DRIVERS_LICENCE')
        return DocumentType.DRIVERS_LICENSE;
      return f1Document.idType
        ? DocumentType[f1Document.idType as string]
        : DocumentType.OTHER;
    };

    const getFilteredExtraData = (
      extraData: F1Document['extraData'],
    ): F1Document['extraData'] => {
      const newExtraData = { ...extraData };

      const keyArr = [
        'display_middle_name',
        'display_name_line1',
        'display_name_line2',
        'display_name_line3',
        'reference',
        'display_name_line4',
      ];

      keyArr.forEach((key) => {
        if (newExtraData[key]) {
          delete newExtraData[key];
        }
      });

      return newExtraData;
    };

    const driversLicenseDocNumber =
      f1Document.idType === 'DRIVERS_LICENCE'
        ? (f1Document.extraData.document_number as string)
        : undefined;

    const medicareSupplementaryData = f1Document.idType ===
      'NATIONAL_HEALTH_ID' && {
      type: 'NATIONAL_HEALTH_ID' as const,
      middleNameOnCard:
        f1Document.extraData['display_middle_name']?.toString() ?? undefined,
      nameOnCardLine1:
        f1Document.extraData['display_name_line1']?.toString() ?? undefined,
      nameOnCardLine2:
        f1Document.extraData['display_name_line2']?.toString() ?? undefined,
      nameOnCardLine3:
        f1Document.extraData['display_name_line3']?.toString() ?? undefined,
      nameOnCardLine4:
        f1Document.extraData['display_name_line4']?.toString() ?? undefined,
      reference: f1Document.extraData['reference']?.toString() ?? undefined,
    };

    return new Document({
      ...previousDocument,
      documentId: f1Document.documentId ?? undefined,
      expiryDate: f1Document.idExpiry
        ? Date.fromDateString(f1Document.idExpiry, previousDocument?.expiryDate)
        : undefined,
      primaryIdentifier: f1Document.idNumber ?? undefined,
      secondaryIdentifier: driversLicenseDocNumber,
      type: getType(),
      subType: f1Document.idSubType ?? undefined,
      subdivision: f1Document.region ?? undefined,
      country: f1Document.country ?? '',
      customAttributes: CustomAttributeRecord.fromExtraData(
        getFilteredExtraData(f1Document.extraData),
        previousDocument?.customAttributes,
      ),
      supplementaryData:
        f1Document.idType === 'NATIONAL_HEALTH_ID'
          ? medicareSupplementaryData
          : undefined,
      attachments: f1Document.scans.map((scan) => {
        const previousAttachment = previousDocument?.attachments?.find(
          ({ attachmentId }) => attachmentId === scan.scanId,
        );
        return Attachment.fromScan(scan, previousAttachment);
      }),
      isPersisted: f1Document.isPersisted,
    });
  };
}
