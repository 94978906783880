import { ICloneable } from '../general';

export enum PhoneNumberType {
  OTHER = 'OTHER',
  WORK = 'WORK',
  HOME = 'HOME',
  MOBILE = 'MOBILE',
  BUSINESS = 'BUSINESS',
  FAX = 'FAX',
}

export interface PhoneNumberPayload {
  phoneNumberId?: string;
  isPreferred?: boolean;
  type?: PhoneNumberType;
  country?: string;
  number?: string;
  unstructuredLongForm?: string;
}

export class PhoneNumber
  implements PhoneNumberPayload, ICloneable<PhoneNumber>
{
  phoneNumberId?: string;
  isPreferred?: boolean;
  type?: PhoneNumberType;
  country?: string;
  number?: string;
  unstructuredLongForm?: string;

  constructor();

  constructor(data: PhoneNumberPayload);

  constructor(data?: PhoneNumberPayload) {
    if (!data) return;
    this.phoneNumberId = data.phoneNumberId;
    this.isPreferred = data.isPreferred;
    this.type = data.type;
    this.country = data.country;
    this.number = data.number;
    this.unstructuredLongForm = data.unstructuredLongForm;
  }

  clone(): PhoneNumber {
    const payload = JSON.parse(JSON.stringify(this));
    const phoneNumber = PhoneNumber.fromJSON(payload);
    return phoneNumber;
  }

  toJSON(): PhoneNumberPayload {
    return { ...this };
  }

  static fromJSON(payload: PhoneNumberPayload): PhoneNumber {
    return new PhoneNumber(payload);
  }

  static default(): PhoneNumber {
    return new PhoneNumber();
  }
}
