import { filter, Subject, takeUntil, takeWhile } from 'rxjs';

import type { Accessors, EventHub, ReadonlyAccessors } from '@module/common';
import { runOnce } from '@module/common';
import { OneSDKError } from '@module/common/errors/OneSDKError.class';
import { Document } from '@module/common/shared/models/Document';
import type {
  CompleteStatus,
  FailedStatus,
  InputRequiredStatus,
  OCRClient,
  OCRResults,
} from '@module/frankie-client/clients/OCRClient';
import {
  isCompleteStatus,
  isFailedStatus,
  isInputRequiredStatus,
  OCRStatus,
} from '@module/frankie-client/clients/OCRClient';
import type { PartialDocument } from '@module/frankie-client/mocks/options/simplified/ocrFlow';
import type { IndividualModule } from '@module/individual';
import type { OCRRecipeOptions } from '@module/ocr';
import type { GlobalEvents } from '@module/sdk/types';

import type { Events } from '../events';

export type Dependencies = {
  _expectedSide$: ReadonlyAccessors<'front' | 'back' | null>;
  status$: Accessors<InputRequiredStatus | CompleteStatus | null>;
  document$: Accessors<Document | null>;
  files$: Accessors<File[]>;
  individual: IndividualModule['moduleContext'];
  eventHub: EventHub<Events>;
  client: OCRClient;
  isPreloaded$: Accessors<boolean | null>;
  failedStatus$: Accessors<FailedStatus | null>;
  globalEventHub: EventHub<GlobalEvents>;
  maxDocumentCount: number;
  documents: OCRRecipeOptions['documents'];
};

export type DependenciesWithInterrupt = Dependencies & {
  interruptOCR: InterruptOCR;
};
type InterruptOCR = (e: { message: string; payload?: unknown }) => void;
/**
 * Returns the start method called by customers to trigger the OCR flow
 * It first generates two observables by branching off the OCR's internal state variables
 * a) status$
 *    Which will cycle on every new status and
 *    will interrupt the cycle once the value of status is COMPLETE OR there is a forced interruption
 * b) failedStatus$
 *    Which will cycle on every new fail status, ignoring null values, issued when failures are resolved
 *    Will be interrupted by any forced interruptions
 *
 * Forced interruptions occur when the function interruptOCR is called by either the customer or internally,
 * when we reach an unrecoverable state. So far the unrecoverable states are
 * - Receiving a status PROVIDER_OFFLINE form backend
 * - File submission fails due to server/network issues
 * - Receiving a status WAITING_OCR_RUN, reattempting automatically and failing with another WAITING_OCR_RUN
 *
 * Each of the subscriber callbacks will trigger a different part of the OCR flow via events:
 * next => input_required, this one is triggered from both status$ and failedStatus$ observables
 * - Will happen on every failed status and every new that *IS NOT*
 * -- COMPLETE
 * -- WAITING_OCR_RUN
 * -- PROVIDER_OFFLINE
 * complete => results
 * - Will happen when the status is COMPLETE
 * error => error
 * - Will happen when the flow is interrupted by
 * -- calling interruptOCR
 * -- status is PROVIDER_OFFLINE
 * -- WAITING_OCR_RUN is received twice in a row
 * -- file submission fails
 *
 * Besides these, an exception is thrown from the function submitFile when
 * - file is not of type "image"
 * - file is missing a type property
 *
 * @param deps dependencies passed from the OCR module
 * @returns { interruptOCR }, where interruptOCR forces the end of the OCR cycles
 */
export const mkStartMethod = (deps: Dependencies) => async () => {
  const {
    status$,
    failedStatus$,
    eventHub,
    document$,
    files$,
    isPreloaded$,
    globalEventHub,
  } = deps;
  globalEventHub.emit('telemetry', 'OCR:START');
  // If flow was interrupted with an error, interrupt observable as well
  const interruptor = new Subject();
  const interruptOCR: InterruptOCR = ({ message, payload = null }) =>
    interruptor.error({ message, payload });
  // Loop until Status is COMPLETE, then unsubscribe automatically
  // Repeated statuses do not trigger another cycle, so this assumes IDV always returns either a new
  // status or a status flagging an issue
  const statusUntilComplete$ = status$.observable.pipe(
    filter(Boolean),
    takeUntil(interruptor),
    takeWhile((status: OCRStatus) => !isCompleteStatus(status)),
  );
  // Ignore null failures (when error is resolved). Run until interrupted
  const failedStatusExceptNull$ = failedStatus$.observable.pipe(
    filter(Boolean),
    takeUntil(interruptor),
  );
  const depsWithInterrupt: DependenciesWithInterrupt = {
    ...deps,
    interruptOCR,
  };
  const resetState = () => {
    status$.setValue(null);
    failedStatus$.setValue(null);
    document$.setValue(null);
    files$.setValue([]);
    isPreloaded$.setValue(null);
  };
  // callbacks for
  // 1) "loop"/"input_required" aka the "next" callback
  // 2) "complete" when OCR status is OCR_COMPLETE
  // 3) "error", when an uncaught error occurred, or interruptOCR is called
  const subscriber = {
    next: (newStatus: OCRStatus) => {
      // For each new status/failureStatus, trigger inputRequired part of the flow
      // This callback is what implements the loops in the OCR Flow
      inputRequired(newStatus, depsWithInterrupt);
    },
    complete: () => {
      // when flow is flagged as completed by the operator takeWhile above
      completeOCR(depsWithInterrupt);
      resetState();
    },
    error: (e: { message: string; payload: unknown }) => {
      // when flow is interrupted by calling interruptOCR, which will in turn
      // emit an error internally, trigerring this error callback
      eventHub.emit(
        'error',
        new OneSDKError(
          'While executing OCR callbacks: ' + e.message,
          e.payload,
        ),
      );
      resetState();
    },
  };

  // Before starting the OCR flow, analyse the existing documents to
  // either continue a flow, or start a new one
  await resetCurrentStatus(depsWithInterrupt);

  // register callbacks to each data stream
  // - for each new status, handle "error", "complete" and "next"/loop callbacks
  statusUntilComplete$.subscribe(subscriber);
  // - for each new failed statuses, only handle the "next"/loop callback
  failedStatusExceptNull$.subscribe(subscriber.next);

  // expose interruptOCR() to allow interruption of an active flow, which unregister all listeners
  return {
    interruptOCR: () =>
      interruptOCR({
        message: 'OCR Flow interrupted externally',
        payload: {
          document: deps.document$.getValue(),
          status: deps.status$.getValue(),
          failedStatus: deps.failedStatus$.getValue(),
        },
      }),
  };
};
export const isOcrPreloaded = (d: PartialDocument) => {
  // d.verified.{manual|electronic} can have boolean or null values
  const { manual = null, electronic = null } = d.verified ?? {};
  if (!d.ocrResult?.status) return false;
  if (manual === false) return false;
  if (electronic === false && manual !== true) return false;
  // COMPLETE, WAITING_FRONT, WAITING_BACK, WAITING_OCR_RUN, FAILED
  return true;
};

// helpers
const isOcrInterrupted = (
  status?: OCRStatus,
): status is OCRStatus.WAITING_OCR_RUN => status === OCRStatus.WAITING_OCR_RUN;
const isOcrWaitingOrComplete = (
  status?: OCRStatus,
): status is InputRequiredStatus | CompleteStatus =>
  isInputRequiredStatus(status) || isCompleteStatus(status);

export const resetCurrentStatus = async (deps: DependenciesWithInterrupt) => {
  /**
   * Resolve the status, the document object and the "isPreloaded" flag before initiating the ocr flow
   * The only statuses that should come out of this function are COMPLETE, WAITING_FRONT and WAITING_BACK
   *
   * 1) Determine if any document already has any valid OCR results
   * 1.a) has ocrResult.status
   * 1.b) has no failed KYC (neither manually or electronically rejected). Here manual verification
   * takes precedence for this decision.
   */
  const {
    individual,
    document$,
    status$,
    isPreloaded$,
    client,
    failedStatus$,
    interruptOCR,
    maxDocumentCount,
  } = deps;

  const initialiseWith = (
    status: InputRequiredStatus | CompleteStatus,
    document: Document,
    isPreloaded: boolean,
  ) => {
    document$.setValue(document);
    isPreloaded$.setValue(isPreloaded);
    failedStatus$.setValue(null);
    status$.setValue(status);
  };
  const startNewFlow = () =>
    initialiseWith(OCRStatus.WAITING_FRONT, new Document(), false);

  const retryLatest = async () => {
    const { documentStatus, ocrDocument } = await client.rerun(
      latestOcrDocument.documentId ?? 'undefined',
    );

    if (allowAnotherDocument && isCompleteStatus(documentStatus))
      return startNewFlow();
    // Only if more ocr is allow by configuration AND If results are: WAITING_FRONT, WAITING_BACK, COMPLETE_OCR,
    // use that status to continue the flow. COMPLETE_OCR will immediately cause a complete callback
    if (isOcrWaitingOrComplete(documentStatus))
      return initialiseWith(documentStatus, ocrDocument, true);
    // IF attempt fails with PROVIDER_OFFLINE, interrupt flow
    if (documentStatus === OCRStatus.PROVIDER_OFFLINE)
      throw new Error(
        'Provider is offline. Either switch providers and restart or skip OCR temporarily. ' +
          `Status '${OCRStatus.PROVIDER_OFFLINE}'`,
      );

    return startNewFlow();
  };

  // Search for existing documents with ocr
  const allDocuments = individual.access('documents');
  const allOcrDocuments = allDocuments
    .getValue()
    .filter(isOcrPreloaded)
    .sort(
      (a, b) =>
        (b.ocrResult.runDate ? +new Date(b.ocrResult.runDate) : NaN) -
        (a.ocrResult.runDate ? +new Date(a.ocrResult.runDate) : NaN),
    );
  const [latestOcrDocument] = allOcrDocuments;
  // The numbered comments below describe each of the possible ways a new flow can be initialised
  // Based on the list of existing documents

  // 1) If there are no documents with OCR results, start a new flow
  // This is the base scenario, where no OCR has been performed yet even if the entity already has documents
  // At the moment we do not consider how old the OCR results are.
  if (!latestOcrDocument) return startNewFlow();

  const latestOcrDocumentStatus = latestOcrDocument.ocrResult.status;
  const allowAnotherDocument =
    maxDocumentCount < 0 ||
    (maxDocumentCount > 1 && maxDocumentCount > allOcrDocuments.length);

  // 2) If latest OCR document has a failed status, restart OCR capture for this same document object
  if (isFailedStatus(latestOcrDocumentStatus)) {
    // if failed status, use existing document with status WAITING_FRONT
    return initialiseWith(OCRStatus.WAITING_FRONT, latestOcrDocument, true);
  }
  // 3) If latest OCR document was interrupted and we don't know the status, request API for a new status and reevaluate
  if (isOcrInterrupted(latestOcrDocumentStatus)) {
    return retryLatest().catch((e) => {
      return interruptOCR({
        message: e.message,
        payload: e,
      });
    });
  }
  if (allowAnotherDocument && isCompleteStatus(latestOcrDocumentStatus)) {
    return startNewFlow();
  }
  if (isOcrWaitingOrComplete(latestOcrDocumentStatus)) {
    // WAITING_FRONT, WAITING_BACK, COMPLETE_OCR
    return initialiseWith(latestOcrDocumentStatus, latestOcrDocument, true);
    // anything else starts a new flow with WAITING_FRONT
  }
  return startNewFlow();
};
export const completeOCR = (deps: DependenciesWithInterrupt) => {
  const { document$, eventHub, individual, client } = deps;

  const document = document$.getValue();
  const entityId = client.entityId;

  if (document) individual.updateDocument(document.documentId, document);
  eventHub.emit('results', { document, entityId });
};
export const inputRequired = (
  status: OCRStatus,
  deps: DependenciesWithInterrupt,
) => {
  const { document$, _expectedSide$, eventHub } = deps;
  const document = document$.getValue();
  const idType = document?.idType === 'OTHER' ? null : document?.idType ?? null;
  const info = {
    documentType: idType,
    side: _expectedSide$.getValue(),
  } as const;
  const message =
    "Callback 'submitFile' on event 'input_required' called twice. Second call was ignored";
  const provideFile = mkProvideFile(deps);
  eventHub.emit('input_required', info, status, runOnce(provideFile, message));
};

export const mkProvideFile =
  (deps: DependenciesWithInterrupt) => (file: File) => {
    const { files$, document$, client } = deps;
    if (!file.type) {
      throw new Error(
        "File is missing mime type. Provide a 'type' option in the File constructor",
      );
    }
    if (!file.type.match(/^image/)) {
      throw new Error(
        "Invalid mime type. Provided files must be of type 'image'",
      );
    }
    files$.setValue([...files$.getValue(), file]);
    const { documentId } = document$.getValue() ?? {};
    const evaluateResults = mkEvaluateResults(deps);
    const hasAnyDigital = !!deps.documents?.some(({ digital }) => digital);
    const submit = documentId
      ? () => client.updateOCRDocument(file, documentId)
      : () => client.attachNewOCRDocument(file, hasAnyDigital);

    submit()
      .then(evaluateResults)
      .catch((e) => {
        deps.interruptOCR({ message: 'Error submitting file', payload: e });
      });
  };

export const mkEvaluateResults =
  (deps: DependenciesWithInterrupt) =>
  (results: OCRResults, recursionFlag?: boolean) => {
    const {
      _expectedSide$,
      document$,
      eventHub,
      status$,
      client,
      failedStatus$,
      interruptOCR,
    } = deps;
    const { documentStatus, ocrDocument } = results;

    // Update local document object first
    const document = ocrDocument.clone();
    document$.setValue(document);
    // if not a failure and not a WAITING_OCR_RUN, emit logs to confirm file upload
    if (
      !isFailedStatus(documentStatus) &&
      documentStatus !== OCRStatus.WAITING_OCR_RUN
    ) {
      const expectedSide = _expectedSide$.getValue();
      const expectedScanSide = expectedSide === 'front' ? 'F' : 'B';
      const foundScan = ocrDocument.scans.find(
        (s) => expectedScanSide === s.side,
      );

      if (foundScan) {
        eventHub.emit('file_uploaded', {
          fileName: foundScan.scanName,
          mimeType: foundScan.mimeType,
          scanId: foundScan.scanId,
          statusAfterUpload: documentStatus,
          statusBeforeUpload: status$.getValue(),
        });
      } else {
        eventHub.emit('warning', {
          message:
            'File was uploaded successfully, but there was an issue identifying it within the document payload: ' +
            `document.scans[].side didn't match expected side ${expectedSide} (${expectedScanSide}) `,
        });
      }
    }

    if (documentStatus === OCRStatus.PROVIDER_OFFLINE) {
      interruptOCR({
        message:
          'Provider is offline. Either switch providers and restart or skip OCR temporarily. ' +
          `Status ${OCRStatus.PROVIDER_OFFLINE}`,
        payload: null,
      });
    } else if (documentStatus === OCRStatus.WAITING_OCR_RUN) {
      // If request previously returned WAITING_OCR_RUN, we tried again and got another WAITING_OCR_RUN,
      // throw error, which will interruptOCR
      if (recursionFlag)
        throw new Error(
          `Two attempts to get OCR results failed with an unresolved "${OCRStatus.WAITING_OCR_RUN}". ` +
            'This is a system failure.',
        );
      // If waiting_ocr_run is returned, try to rerun and reevaluate results
      const evaluateResults = mkEvaluateResults(deps);
      client
        .rerun(ocrDocument.documentId ?? 'undefined')
        .then((res) => evaluateResults(res, true))
        .catch((e) =>
          interruptOCR({
            message: `Attempting to resolve the status ${OCRStatus.WAITING_OCR_RUN} resulted in an error`,
            payload: e,
          }),
        );
    } else if (isFailedStatus(documentStatus)) {
      // if failed, set failedStatus and keep status the same
      failedStatus$.setValue(documentStatus);
    } else {
      // otherwise, update status to new received status
      failedStatus$.setValue(null);
      status$.setValue(documentStatus);
    }
  };
