import type { OneSdkContext } from '../types';

const DEVTOOLS_GLOBAL_VARIABLE = '__ONE_SDK__DEVTOOLS';
/**
 * This function will setup and attach visibility tools to the window object, so they are available to developers
 * @param sdk The OneSDK context
 */
export function attachDevTools(sdk: OneSdkContext): void {
  const isDevtoolsInstalled = DEVTOOLS_GLOBAL_VARIABLE in window;

  if (!isDevtoolsInstalled) {
    // TODO: Add support for multiple instances
    const initialDevtools: Devtools = {
      numberOfInstances: 0,
      oneSdkInstance: sdk,
      moduleInstances: {
        components: sdk.component.instances,
        flows: sdk.flow.instances,
      },
    };
    Object.defineProperty(window, DEVTOOLS_GLOBAL_VARIABLE, {
      value: initialDevtools,
      enumerable: false,
      writable: false,
      configurable: false,
    });
  }

  const devtools = window[DEVTOOLS_GLOBAL_VARIABLE];
  devtools.numberOfInstances += 1;

  if (devtools.numberOfInstances > 1) {
    // eslint-disable-next-line no-console
    console.trace(
      `OneSDK devtools doesn't support multiple instances yet, but ${devtools.numberOfInstances} ` +
        'initialisations were detected. Devtools will ignore all but the first one. ' +
        'Follow stack trace to identify the source of the extra initialisations.',
    );
    return;
  }

  devtools.oneSdkInstance = sdk;
  devtools.moduleInstances.components = sdk.component.instances;
  devtools.moduleInstances.flows = sdk.flow.instances;
}

type Devtools = {
  numberOfInstances: number;
  oneSdkInstance: OneSdkContext;
  moduleInstances: {
    components: Record<string, unknown>;
    flows: Record<string, unknown>;
  };
};
declare global {
  interface Window {
    [DEVTOOLS_GLOBAL_VARIABLE]: Devtools;
  }
}
