// TODO: Prevent one sdk from ever throwing an error. Emit error events instead.
import { getInitialiser } from '@config';
import type {
  ResolvedRootParameters,
  SharedDependencies,
} from '@module/common/types';
import { SessionFacade } from '@module/session/SessionFacade';

import { mkEventListener } from '../common';

import { attachDevTools } from './devTools/attachDevTools';
import { mkModuleInstantiator } from './initialisation/componentLoader';
import {
  parseConfiguration,
  resolveParameters,
} from './initialisation/configurationParser';
import { mkSharedDependencies } from './initialisation/sharedDependencies';

import type { InjectedDependencies } from './initialisation/componentLoader';
import type {
  OneSdkContext,
  OneSdkModule,
  OneSdkRootParameters,
} from './types';

export const oneSdk: OneSdkModule = {
  initialise: async function (oneSdkRootParameters: OneSdkRootParameters) {
    const resolvedParameters: ResolvedRootParameters =
      resolveParameters(oneSdkRootParameters);
    const sharedDependencies: SharedDependencies =
      await mkSharedDependencies(resolvedParameters);

    const { globalEventHub } = sharedDependencies;
    try {
      const { globalState, warnings } = await parseConfiguration(
        resolvedParameters,
        sharedDependencies,
      );
      // TODO: Based on recipe configuration, add pre-fetch scripts tags so all files can be eager loaded in parallel as soon as possible
      // Build the OneSDK instance which will be returned by this function
      // The one sdk only exposes the listener methods of the event hubs
      // Both the dynamic component loader and the individual component (which is returned by the "individual" method)
      // are defined next
      const globalEventListener = mkEventListener(globalEventHub);
      const sdk: OneSdkContext = {
        ...globalEventListener,
        session: new SessionFacade(globalState.session),
        individual: () => individual,
        business: () => business,
        flow: null,
        component: null,
      };
      const injectedState: InjectedDependencies = Object.assign(globalState, {
        oneSdkInstance: sdk,
      });
      // Initialise and label the individual and business component, which is
      // special and will be available through the static factory method
      // called "individual" and ""business" respectively
      const individual = getInitialiser('individual')(injectedState);
      const business = getInitialiser('business')(injectedState);
      // Make the dynamic component loader, which initialises components dynamically
      sdk.component = mkModuleInstantiator<'component'>(injectedState);
      sdk.flow = mkModuleInstantiator<'flow'>(injectedState);

      // wait for individual's data_loaded event before continuing
      await new Promise((resolve) => {
        individual.on('data_loaded', () => {
          resolve(sdk);
        });
        individual.search();
      });
      // then schedule the emission of all events/warnings for after oneSDK instance is resolved
      // Even telemetry, in case customers want to observe them
      // TODO: THESE EVENTS NEED TO WAIT UNTIL ONESDK.ON IS CALLED
      // Otherwise they are impossible to listen for
      globalEventHub.emit('telemetry', {
        eventName: 'INIT',
        data: { mode: globalState.mode, recipe: globalState.recipe, warnings },
      });
      warnings.forEach(({ level, data }) => globalEventHub.emit(level, data));

      if (oneSdkRootParameters.devtools) attachDevTools(sdk);

      return sdk;
    } catch (error) {
      // TODO: Emit telemetry event here
      // Errors that reach this catch block are critical errors and will interrupt the flow of the OneSDK
      globalEventHub.emit('telemetry', {
        eventName: 'INIT:ERROR',
        data: {
          oneSdkOptions: oneSdkRootParameters,
        },
        error,
      });
      throw error;
    }
  },
};
