import type { Applicant } from '@module/common/shared/models/Applicant';
import type { Document, Scan } from '@module/common/shared/models/Document';
import type { DeepPartial } from '@module/common/shared/models/general';
import type { OCRDocumentType } from '@module/frankie-client/clients/OCRClient';
import {
  OCRStatus,
  isCompleteStatus,
  isInputRequiredStatus,
} from '@module/frankie-client/clients/OCRClient';

import { resolveScanData } from '../../individual/resolveDocumentData';

import { resolveOCRStatus } from './ocrFlow';

import type { PartialDocument, ScanSide } from './ocrFlow';
import type { SimplifiedMockOptions } from './SimplifiedMockOptions.type';
import type { MockOptions } from '../MockOptions.type';

/**
 * Preloaded Individual
 */

/** SimplifiedMockOptions */
export type SimplifiedPreloadedIndividual =
  | {
      preloadedIndividual: {
        individual?: DeepPartial<Applicant>;
        documents?: PartialDocument[];
      };
    }
  | {
      preloadedIndividual: false;
    };

/** Validation */
export function validatePreloadedIndividualSimplifiedOptions(
  _options: SimplifiedMockOptions,
): asserts _options is SimplifiedPreloadedIndividual {
  // No validation provided here
}

/** SimplifiedMockOptions resolution */
export function resolveSimplifiedPreloadedIndividualMockOptions(
  simplified: SimplifiedMockOptions,
): Partial<MockOptions> {
  if (!hasPreloadedIndividual(simplified)) return {};
  // If we have a preloaded individual, we need to resolve both the provided simplified individual object into
  // an instance of Applicant and the provided simplified documents into full Document objects
  const { individual = {}, documents = [] } = simplified.preloadedIndividual;
  const resolvedDocuments = documents.map((d) =>
    resolveSimplifiedPreloadedDocument(d, simplified),
  );
  return {
    preloadedIndividual: {
      individual,
      documents: resolvedDocuments as Document[],
    },
  };
}

/** Helpers */

export const hasPreloadedIndividual = (
  options: SimplifiedMockOptions,
): options is Exclude<
  SimplifiedPreloadedIndividual,
  { preloadedIndividual: false }
> => {
  return Boolean(options.preloadedIndividual);
};

export const resolveSimplifiedPreloadedDocument = (
  partialDoc: PartialDocument,
  allOptions: SimplifiedMockOptions,
): DeepPartial<Document> => {
  const ocrStatus = partialDoc.ocrResult?.status;
  const docType = partialDoc.idType;

  partialDoc.scans = resolveScans(partialDoc.scans);

  if (ocrStatus) {
    const allNextOcrStatuses = allOptions.ocrFlow?.statusResults ?? [];
    const mergedOcrStatuses = [ocrStatus, ...allNextOcrStatuses];
    const allStatuses = mergedOcrStatuses.map(resolveOCRStatus);
    partialDoc.scans = resolveScans(
      statusToScans(ocrStatus, 0, allStatuses, docType as OCRDocumentType),
    );
  }

  return partialDoc as DeepPartial<Document>;
};

/**
 * statusToScans will resolve the scan side for any particular status in the whole ocr flow taking the latest results and the commplete pre defined flow
 * By a combination of the past status, the current status and the next status, it will determine what the current required scan is, if any and also what the current scans are, if any.
 * @param status the latest status returned from a mocked OCR request
 * @param index the current position within the complete OCR flow (the requests array)
 * @param allStatuses the complete array of different statuses expected in the OCR flow
 * @param docType the type of document being scanned
 **/
export const statusToScans = (
  status: OCRStatus,
  index: number,
  allStatuses: OCRStatus[],
  docType?: OCRDocumentType,
): ScanSide => {
  // Helpers to make code more semantic
  const isNotFailed = (status: OCRStatus) =>
    isInputRequiredStatus(status) || isCompleteStatus(status);
  const docIs = (type: OCRDocumentType) => docType === type;
  type statuses =
    | 'COMPLETE'
    | 'FAILED'
    | 'WAITING_FRONT'
    | 'WAITING_BACK'
    | 'WAITING_RUN';
  const statusIs = (compare: statuses): boolean => {
    if (compare === 'WAITING_FRONT') return status === OCRStatus.WAITING_FRONT;
    if (compare === 'WAITING_BACK') return status === OCRStatus.WAITING_BACK;
    if (compare === 'WAITING_RUN') return status === OCRStatus.WAITING_OCR_RUN;
    if (compare === 'COMPLETE') return status === OCRStatus.COMPLETE;
    if (compare === 'FAILED') {
      const allOthers: statuses[] = [
        'COMPLETE',
        'WAITING_RUN',
        'WAITING_FRONT',
        'WAITING_BACK',
        'WAITING_RUN',
      ];
      return !allOthers.some(statusIs);
    }
    return false;
  };
  // use next AND past successful status to determine current scans
  const pastSuccessfulStatus =
    allStatuses.slice(0, index).reverse().find(isNotFailed) ?? null;
  const nextSuccessfulStatus =
    allStatuses.slice(index + 1).find(isNotFailed) ?? null;
  const nextStatusIs = (nextStatus: 'FRONT' | 'BACK' | 'COMPLETE') => {
    if (nextStatus === 'COMPLETE')
      return nextSuccessfulStatus === OCRStatus.COMPLETE;
    if (nextStatus === 'FRONT')
      return nextSuccessfulStatus === OCRStatus.WAITING_FRONT;
    if (nextStatus === 'BACK')
      return nextSuccessfulStatus === OCRStatus.WAITING_BACK;
    return false;
  };
  const pastStatusWas = (pastStatus: 'FRONT' | 'BACK' | 'NONE') => {
    if (pastStatus === 'BACK')
      return pastSuccessfulStatus === OCRStatus.WAITING_BACK;
    if (pastStatus === 'FRONT')
      return pastSuccessfulStatus === OCRStatus.WAITING_FRONT;
    if (pastStatus === 'NONE') return pastSuccessfulStatus === null;
    return false;
  };

  // --- ALL COMBINATIONS DEFINED CLEARLY FOR EASIER DEBUGGING

  // PASSPORT

  // "Passport" when status is "waiting front" has no scans
  if (docIs('PASSPORT') && statusIs('WAITING_FRONT')) return 'none';
  // "Passport" when status is "waiting ocr run" and next successful status is "waiting front" has no scans
  if (docIs('PASSPORT') && statusIs('WAITING_RUN') && nextStatusIs('FRONT'))
    return 'none';
  // "Passport" when status is "waiting ocr run" and next successful status is "complete" has front scan
  if (docIs('PASSPORT') && statusIs('WAITING_RUN') && nextStatusIs('COMPLETE'))
    return 'F';
  // "Passport" when status is "failed" and next successful status is "front" has no scans
  if (docIs('PASSPORT') && statusIs('FAILED') && nextStatusIs('FRONT'))
    return 'none';
  // "Passport" when status is "failed" and next successful status is "complete" has front scan
  if (docIs('PASSPORT') && statusIs('FAILED') && nextStatusIs('COMPLETE'))
    return 'F';
  // "Passport" when status is "complete" has front scan
  if (docIs('PASSPORT') && statusIs('COMPLETE')) return 'F';

  // DRIVERS LICENCE

  // "Drivers Licence" when status is "waiting front" has no scans
  if (docIs('DRIVERS_LICENCE') && statusIs('WAITING_FRONT')) return 'none';
  // "Drivers Licence" when status is "waiting back" has front scan
  if (docIs('DRIVERS_LICENCE') && statusIs('WAITING_BACK')) return 'F';
  // "Drivers Licence" when status is "waiting ocr run" AND next status is "waiting front" has no scans
  if (
    docIs('DRIVERS_LICENCE') &&
    statusIs('WAITING_RUN') &&
    nextStatusIs('FRONT')
  )
    return 'none';
  // "Drivers Licence" when status is "waiting ocr run" AND next status is "waiting back" has front scan
  if (
    docIs('DRIVERS_LICENCE') &&
    statusIs('WAITING_RUN') &&
    nextStatusIs('BACK')
  )
    return 'F';
  // "Drivers Licence" when status is "waiting ocr run" AND next status is "complete" has both scans
  if (
    docIs('DRIVERS_LICENCE') &&
    statusIs('WAITING_RUN') &&
    nextStatusIs('COMPLETE')
  )
    return 'both';
  // "Drivers Licence" when status is "failed" AND next status is "waiting back" has no scans
  // This is because if you will get a "waiting back" later, we are still supposed to submit the front scan
  if (docIs('DRIVERS_LICENCE') && statusIs('FAILED') && nextStatusIs('BACK'))
    return 'none';
  // "Drivers Licence" when status is "failed" AND next status is "complete" we aren't able to determine the scans
  // We then look at past statuses. If there is no successful past status, then we already have both scans
  // This will happen when preloading a document with two scans and failing straight away, but succeeding next.
  if (
    docIs('DRIVERS_LICENCE') &&
    statusIs('FAILED') &&
    nextStatusIs('COMPLETE') &&
    pastStatusWas('NONE')
  )
    return 'both';
  // As above, if past status was "waiting front" and now we failed, then we still have no scans
  if (
    docIs('DRIVERS_LICENCE') &&
    statusIs('FAILED') &&
    nextStatusIs('COMPLETE') &&
    pastStatusWas('FRONT')
  )
    return 'none';
  // Now if we failed and we will "complete" next, if we already had a "waiting back" before, means we're still "waiting back"
  // So we already have a front scan
  if (
    docIs('DRIVERS_LICENCE') &&
    statusIs('FAILED') &&
    nextStatusIs('COMPLETE') &&
    pastStatusWas('BACK')
  )
    return 'F';
  // "Drivers Licence" when status is "complete" has both scans
  if (docIs('DRIVERS_LICENCE') && statusIs('COMPLETE')) return 'both';

  // Any missed edge case defaults to no scans
  return 'none';
};

export const resolveScans = (
  scansOptions: PartialDocument['scans'],
): Scan[] => {
  if (Array.isArray(scansOptions)) {
    return scansOptions.map(resolveScanData);
  }
  const scans: Scan[] = [];
  if (scansOptions && ['both', 'F'].includes(scansOptions)) {
    scans.push(
      resolveScanData({
        scanCreated: '2022-05-31T03:48:25.000+0000',
        scanName: 'dl-front.jpg',
        side: 'F',
      }),
    );
  }
  if (scansOptions && ['both', 'B'].includes(scansOptions)) {
    scans.push(
      resolveScanData({
        scanCreated: '2022-05-31T03:52:25.000+0000',
        scanName: 'dl-back.jpg',
        side: 'B',
      }),
    );
  }
  return scans;
};
