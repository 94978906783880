import { v4 as uuid } from 'uuid';

import { singpassURLs } from '@module/federation/vendors/Singpass';
import type { DummyFrankieApiClient } from '@module/frankie-client';

import { QUERY_PARAM, URL_PARAM } from '../../DummyFrankieApiClient';

export function skipFederationLogin(client: DummyFrankieApiClient) {
  client.stubResponse({ url: /.+/, method: 'redirect' }, (url) => {
    const authCode = uuid();

    if (Object.values(singpassURLs).some((u) => u.includes(url.hostname))) {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.append('code', authCode);
      newUrl.searchParams.append(
        'state',
        String(url.searchParams.get('state')),
      );
      return newUrl;
    }
    return null;
  });
}

export function successfulDataFetching(client: DummyFrankieApiClient) {
  client.stubResponse(
    {
      url: new RegExp(
        `/federation/v1/callback/${URL_PARAM}\\?authCode=${QUERY_PARAM}`,
      ),
      method: 'get',
    },
    (data) => ({
      title: `Get data from '${data.urlMatch?.[1]}'`,
      status: 200,
      data: {
        individual: {
          name: { givenName: 'Dummy name', familyName: 'Family' },
          addresses: [],
          documents: {},
        },
        status: 'STATUS_OK',
      },
    }),
  );
}
export function successfulConfirmation(client: DummyFrankieApiClient) {
  client.stubResponse(
    { url: '/federation/v1/singpass/entity', method: 'post' },
    {
      title: 'Confirming federation data',
      status: 201,
    },
  );
}
