import type { RecipeConfiguration } from '@module/common';
import type { SimplifiedMockOptions } from '@module/frankie-client/mocks/options/simplified/SimplifiedMockOptions.type';

import type { PartialDeep, SetRequired } from 'type-fest';

/**
 * Different levels of configuration may be done in OneSdk. This isn't a requirement,
 * but allows us to ensure options are consistent and compatible throughout all levels of OneSdk
 *
 * Throughout this codebase "options" refer to the options passed to the OneSdk and "configuration/config" are the recipe configuration.
 * Recipe/recipe name is the string name representing a recipe.
 *
 * 1) initialisation as OneSdk({
 *  * all options are optional on initialisation
 *  recipe?,
 *  environment?
 *  mode?
 *  session (required unless mode is "dummy")
 * })
 * 2) Resulting parsed configuration = {
 *  * once parsed, all missing options will be filled with defaults
 *  * recipe object will be fetched from API and merged with recipe object from initialisation, if any
 *  recipe,
 *  environment,
 *  mode,
 *  session (unless mode is "dummy")
 * }
 * 3) When instanciating a component = {
 *  * instanceName allows multiple instances to be stored in a central instance registry, otherwise by default only one instance is
 *  * created for each module this allows modules to call existing instances or create new instances on demand and
 *  * share them with other modules
 *  instanceName?
 * }
 */
export type OneSdkRootParameters = {
  recipe?: RecipeOption;
  telemetry?: boolean;
  devtools?: boolean;
} & ModeAndSessionOptions;

/******* Below you'll find the bits and pieces that together form the parameter object above */
export type PartialRecipe = PartialDeep<RecipeConfiguration>;
export type RecipeOption = string | PartialRecipe;
// Once parsed, "recipe" is always an object including at least { name: 'recipe name defaulting to auto' }
export type RecipeParsed = SetRequired<PartialRecipe, 'name'>;

/**
 * * SdkModes are the different modes in which the SDK can be initialised.
 * TODO: Rename it to SdkMode for consistency. Any enums meant to be used as a type should be named as a singular.
 * This is because they are assignable as a single value, not as a collection of values.
 * ie currentMode: SdkMode = SdkMode.DEV
 * on the other hand, enums exposed as a collection of values should be named as a plural.
 * ie moduleContext.modes = { DEV: "development", PROD: "production" }
 *
 * There is a fine line between the two concepts and this is still a discussion in progress.
 */
export enum SdkModes {
  DEV = 'development',
  PROD = 'production',
  DUMMY = 'dummy',
}
export type DummyModeOptions = {
  modeName: SdkModes.DUMMY;
  mocks?: SimplifiedMockOptions;
};
export type NonDummyModeOptions = {
  modeName: Exclude<SdkModes, SdkModes.DUMMY>;
};
/**
 * * Mode Options
 * If in dummy mode, it doesn't contain a session object
 * Tagged unions only apply to fields in the root of objects, so when mode is defined as an object the typing simply breaks.
 * ie the assignment below doesn't work because typescript can't infer the type of "mode" from its object form
 * - declare let session: ModeAndSessionOptions["session"];
 * - declare let mode: ModeAndSessionOptions["mode"];
 * - const config: ModeAndSessionOptions = {
 * -   mode,
 * -   session,
 * - };
 *
 * The fix is to use type assertions instead
 * - const config = {
 * -   mode,
 * -   session,
 * - } as ModeAndSessionOptions;
 * https://github.com/microsoft/TypeScript/issues/18758
 * */
type ModeOptionsObject<Mode extends SdkModes> = Mode extends SdkModes.DUMMY
  ? DummyModeOptions
  : NonDummyModeOptions;
export type ModeOption<Mode extends SdkModes> = Mode | ModeOptionsObject<Mode>;

/**
 * Session Options
 * Session is not required when in dummy mode
 */
type SessionOptions = {
  /** token is required when not in dummy mode */
  token: string | null;
  /** persist will ensure the provided token is stored and reused next time OneSDK is initialised */
  persist?: boolean;
  /** appReference identifies the host application executing the OneSDK. This is attached to logs and used by some services in their decision making */
  appReference?: string;
  frankie2customer?: boolean;
};

export type ModeAndSessionOptions =
  | {
      mode: ModeOption<SdkModes.DUMMY>;
      session?: Partial<SessionOptions> | null;
    }
  | {
      mode?: ModeOption<SdkModes.PROD | SdkModes.DEV>;
      session: SessionOptions;
    };
