import Axios from 'axios';

import type { ENVIRONMENTS } from '@module/common';
import { env2url } from '@module/common';
import { stringToBase64 } from '@module/common/modules/base64';
import type { ApplicantId } from '@module/session/SessionContext';
import { SessionContext } from '@module/session/SessionContext';

import type {
  ClientsConstructorDictionary,
  InstanceOf,
  LoadClientParametersFor,
} from './ClientsDictionary';
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export type IFrankieClientCredentials = {
  customerID: string;
  customerChildID?: string;
  apiKey: string;
};

export class FrankieApiClient {
  protected instance: AxiosInstance;
  protected sessionContext: SessionContext;

  constructor() {
    this.instance = Axios.create();
    this.interceptors.response.use((response: AxiosResponse) => {
      const token = response.headers.token;
      const frankie2customer = response.headers.frankie2customer;
      if (token && this.session) {
        this.session.setToken(token);
        this.instance.defaults.headers.common.authorization = token;
      }
      if (frankie2customer && this.session) {
        this.session.setFrankie2Customer(
          String(response.headers.frankie2customer) === 'true',
        );
      }

      return response;
    });
  }
  get interceptors(): AxiosInstance['interceptors'] {
    return this.instance.interceptors;
  }

  set entityId(value: string | null) {
    this.sessionContext.setEntityId(value);
  }

  set frankie2customer(frankie2customer: boolean) {
    this.sessionContext.setFrankie2Customer(frankie2customer);
  }

  async login(
    credentials: IFrankieClientCredentials,
    applicantId: ApplicantId,
    options: { environment: ENVIRONMENTS; preset?: string },
  ): Promise<{ token: string; frankie2customer: boolean }> {
    const endpoint = '/auth/v2/machine-session';
    const agent = 'machine';
    const environmentUrl = env2url(options.environment);
    this.instance.defaults.baseURL = environmentUrl;

    const response = await this.post<{
      token: string;
      frankie2customer: boolean;
    }>(
      endpoint,
      {
        permissions: {
          preset: options.preset ?? 'one-sdk',
          ...applicantId,
        },
      },
      {
        headers: {
          authorization: `${agent} ${serialiseCredentials(credentials)}`,
        },
      },
    );
    const { token, frankie2customer } = response.data;
    if (!token) throw new Error('Missing token');
    this.session = new SessionContext(token, frankie2customer, {
      takenFromStorage: false,
    });

    const tokenEnv = new URL(this.session.environment).toString();
    const providedEnv = new URL(environmentUrl).toString();
    if (tokenEnv !== providedEnv)
      // eslint-disable-next-line no-console
      console.warn(
        `Environment mismatch: Provided '${providedEnv}' (${options.environment}), but token is '${tokenEnv}`,
      );
    return {
      token,
      frankie2customer,
    };
  }

  get frankie2customer(): boolean {
    return this.session.frankie2customer;
  }

  get token() {
    return this.session.token;
  }

  get session(): SessionContext {
    return this.sessionContext;
  }

  set session(session: SessionContext) {
    this.instance.defaults.baseURL = session.environment;
    if (session.token)
      this.instance.defaults.headers.common.authorization = session.token;
    this.instance.defaults.headers.common['X-Frankie-Channel'] = 'onesdk';

    this.sessionContext = session;
  }

  validateSession() {
    return this.get('data/v2/token-validity');
  }

  get<T = unknown>(
    url: string,
    config?: AxiosRequestConfig | undefined,
  ): Promise<AxiosResponse<T>> {
    return this.instance.get(url, config);
  }
  delete<T = unknown>(
    url: string,
    config?: AxiosRequestConfig | undefined,
  ): Promise<AxiosResponse<T>> {
    return this.instance.delete(url, config);
  }
  post<T = unknown>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig | undefined,
  ): Promise<AxiosResponse<T>> {
    return this.instance.post(url, data, config);
  }
  put<T = unknown>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig | undefined,
  ): Promise<AxiosResponse<T>> {
    return this.instance.put(url, data, config);
  }
  redirect(url: URL) {
    window.location.href = url.href;
  }

  async loadClient<Client extends keyof ClientsConstructorDictionary>(
    ...parameters: LoadClientParametersFor<Client>
  ): Promise<InstanceOf<Client>> {
    const [which, options] = parameters;

    let ClientClass; /** TODO: Add type to this variable*/
    if (which === 'configuration')
      ClientClass = (await import('./clients/ConfigurationClient'))
        .ConfigurationClient;
    else if (which === 'applicant')
      ClientClass = (await import('./clients/ApplicantClient')).ApplicantClient;
    else if (which === 'telemetry')
      ClientClass = (await import('./clients/TelemetryEventsClient'))
        .TelemetryEventsClient;

    return new ClientClass(this, options) as InstanceOf<Client>;
  }
}

function serialiseCredentials(credentials: IFrankieClientCredentials): string {
  const elements = [
    credentials.customerID,
    credentials.customerChildID,
    credentials.apiKey,
  ];
  return stringToBase64(elements.filter(Boolean).join(':'));
}
