import type { EventHub, ReactiveStore } from '@module/common';
import { OneSDKError } from '@module/common';
import { DocumentClass } from '@module/common/shared/models/F2/Document';
import {
  ExternalReference,
  ExternalReferenceType,
} from '@module/common/shared/models/F2/ExternalReference';
import { Individual } from '@module/common/shared/models/F2/Individual';
import type { IndividualClient } from '@module/frankie-client/clients/F2/IndividualClient';
import type { Events } from '@module/individual';

import type { InternalStateF2 } from '../definition';

type MkSearchDeps = {
  client: IndividualClient;
  eventHub: EventHub<Events>;
  state$: ReactiveStore<InternalStateF2>;
};
export const mkSearch = (deps: MkSearchDeps) => async () => {
  const { client, eventHub, state$ } = deps;

  const isLoading$ = state$.getRootAccessors('isLoading');
  let individual: Individual;

  try {
    isLoading$.setValue(true);
    const found = await client.load();
    individual = found.individual;
  } catch (e) {
    if (client.entityId) {
      const errorMessage = `Critical error! There was an issue loading the individual's details. Is the entityId (${client.entityId}) used to create this session valid? ${e}`;
      eventHub.emit('error', new OneSDKError(errorMessage, e));

      throw new Error(errorMessage);
    }

    isLoading$.setValue(true);
    individual = new Individual();
    if (client.reference) {
      individual.externalReferences = [
        new ExternalReference({
          type: ExternalReferenceType.CUSTOMER,
          name: 'CUSTOMER_REFERENCE',
          value: client.reference,
        }),
      ];
    }
  }

  const isPreloaded = Boolean(individual.entityId);
  state$.next({
    individual,
    _applicant: individual.toApplicant(),
    _documents:
      individual.documents?.[DocumentClass.IDENTITY]?.map((document) =>
        document.toF1Document(),
      ) ?? [],
    device: {},
    isLoading: false,
    isPreloaded,
    isPersisted: isPreloaded,
  });
  // This is required because isPersisted is set to false every time applicant and documents are reset
  state$.getRootAccessors('isPersisted').setValue(Boolean(individual.entityId));
  isLoading$.setValue(false);
  eventHub.emit('data_loaded', {
    entityId: client.entityId ?? '',
    isPreloaded,
  });
};
