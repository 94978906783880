import type { Accessors, EventHub, ReadonlyAccessors } from '@module/common';
import type { Applicant } from '@module/common/shared/models/Applicant';
import type { Document } from '@module/common/shared/models/Document';

import type { Events } from '..';

type SubscribeAndEmitEventsDeps = {
  emitDataUpdatedFor: ReadonlyAccessors<unknown>[];
  eventHub: EventHub<Events>;
  applicant$: ReadonlyAccessors<Applicant>;
  documents$: ReadonlyAccessors<Document[]>;
  isPersisted$: Accessors<boolean>;
};
export const subscribeAndEmitEvents = (deps: SubscribeAndEmitEventsDeps) => {
  const { eventHub, emitDataUpdatedFor, applicant$, documents$, isPersisted$ } =
    deps;
  // Emit "data_updated" for all accessors provided in `emitDataUpdatedFor`
  emitDataUpdatedFor.forEach((accessors) => {
    const fieldName = accessors.property;
    const observable = accessors.observable;
    observable.subscribe(() =>
      eventHub.emit('data_updated', fieldName, accessors.getValue()),
    );
  });
  // Whenever applicant or document changes, set isPersisted to false
  const setUnpersisted = (_: unknown) => isPersisted$.setValue(false);
  applicant$.observable.subscribe(setUnpersisted);
  documents$.observable.subscribe(setUnpersisted);
};
