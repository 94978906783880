import { mkVendorLoader } from '@module/common';
import { defineModule } from '@module/common/modules/defineModule';
import { getInitialiser } from '@module/config';
import { mkFrankieClient } from '@module/frankie-client';
import { SdkModes } from '@module/sdk/types';

import { parseConfiguration } from './parseConfiguration';

import type { FederationModule } from './definition';

export default defineModule<FederationModule>('federation', (globalState) => {
  parseConfiguration(globalState);
  // The two lines below will replace the session provided on OneSDK initialisation with
  // any existing session stored in the local storage.
  // A new frankie client is created with the new session and also replaces the existing client
  // NOTE: Any component referencing Frankie Client from this point on will now refer to this new instance of FrankieApiClient
  // TODO: Consider if we should isolate the stored session to this component only
  //       That would mean that the OneSDK could use two different sessions at the same time, including two different sessionId
  // NOTE: When in Dummy mode the optional provided session is kept as is
  if (globalState.mode.modeName !== SdkModes.DUMMY) {
    globalState.session = getInitialiser('session')(
      {
        mode: globalState.mode,
        recipe: globalState.recipe,
        telemetry: globalState.telemetry,
        session: {
          appReference: globalState.session.appReference ?? null,
          persist: true,
          token: globalState.session.token,
          frankie2customer: globalState.session.frankie2customer,
        },
      },
      // Since session module isn't a component, it doesn't have access to the global event hub through its first parameter.
      // That means the globalEventHub is a "custom" parameter and belongs in the second parameter.
      // TODO: session module is not supposed to be provided from the module initialiser "getInitialiser". It doesn't need this sort of constraint. See comments for ModuleInitialiseFunctionParameters
      {
        globalEventHub: globalState.globalEventHub,
      },
    );
    globalState.frankieClient = mkFrankieClient({
      mode: globalState.mode,
      session: globalState.session,
      validateToken: false,
    });

    globalState.globalEventHub.emit('info', {
      message: globalState.session.isTakenFromStorage()
        ? 'Found existing token in storage. Using it as new session.'
        : 'No token found in storage. Using provided token for this session.',
      payload: {
        sessionId: globalState.session.sessionId,
      },
    });
  }

  const { localEventHub } = globalState;

  const loadVendorWrapper = mkVendorLoader<FederationModule>({
    vendorName: 'singpass',
    sharedConfiguration: globalState,
    vendorLoader: {
      singpass: () =>
        import(
          /* webpackChunkName: 'federation-singpass' */ './vendors/Singpass.js'
        ),
    },
  });

  const start: FederationModule['moduleContext']['start'] = () => {
    loadVendorWrapper({
      vendorWrapperOptions: { eventHub: localEventHub },
    });
  };
  return {
    start,
  };
});

export * from './definition';
