import debounce from 'lodash.debounce';
import merge from 'lodash.merge';

import { mkVendorLoader, ReactiveStore } from '@module/common';
import { defineModule } from '@module/common/modules/defineModule';
import type { Document } from '@module/common/shared/models/Document';
import { MOUNT_DEBOUNCE_WAIT } from '@module/common/shared/utils';
import type {
  FailedStatus,
  InputRequiredStatus,
} from '@module/frankie-client/clients/OCRClient';
import {
  isInputRequiredStatus,
  OCRClient,
  OCRStatus,
} from '@module/frankie-client/clients/OCRClient';
import { mkMountMethod } from '@module/ocr/actions/mount';
import type { Events } from '@module/ocr/events';
import { SdkModes } from '@module/sdk/types';

import { mkStartMethod } from './actions/start';

import type { Dependencies } from './actions/start';
import type { OCRModule } from './definition';

export * from './definition';
export type { Events };
export { OCRStatus as Status };

export default defineModule<OCRModule>('ocr', (globalState, options = {}) => {
  const {
    frankieClient,
    oneSdkInstance,
    globalEventHub,
    localEventHub,
    recipe: { ocr },
  } = globalState;

  function overwriteDocuments(
    firstObj: typeof ocr,
    secondObj: typeof options,
  ): void {
    if (secondObj && secondObj.documents) {
      firstObj.documents = secondObj.documents;
    }
  }

  // @TODO add validation check
  if (ocr.provider.name === 'onfido') {
    // for onfido ocr, only take run-time recipe
    overwriteDocuments(ocr, options);
  } else {
    Object.assign(ocr, merge(ocr, options));
  }

  const client = new OCRClient(frankieClient);

  const {
    status$,
    failedStatus$,
    document$,
    files$,
    isPreloaded$,
    _expectedSide$,
  } = createOCRInternalState({
    document: null,
    status: null,
    failedStatus: null,
    files: [],
    isPreloaded: null,
  });

  const publicAccessors = {
    status: status$.toReadonly(),
    failedStatus: failedStatus$.toReadonly(),
    isPreloaded: isPreloaded$.toReadonly(),
  };

  const loadVendorWrapper = mkVendorLoader({
    // If initialising in dummy mode, force the use of the dummy-ocr wrapper
    // otherwise load the wrapper based on the provider's name
    vendorName:
      globalState.mode.modeName === SdkModes.DUMMY
        ? 'dummy-ocr'
        : ocr.provider.name,
    sharedConfiguration: globalState,
    vendorLoader: {
      'dummy-ocr': () =>
        import(
          /* webpackChunkName: 'ocr-dummy' */ './vendors/dummy-ocr/index.js'
        ),
      incode: () =>
        import(
          /* webpackChunkName: 'ocr-incode' */ './vendors/Incode/index.js'
        ),
      onfido: () =>
        import(
          /* webpackChunkName: 'ocr-onfido' */ './vendors/Onfido/index.js'
        ),
      ocrlabs: null,
    },
  });

  const deps: Dependencies = {
    _expectedSide$,
    client,
    document$,
    eventHub: localEventHub,
    files$,
    individual: oneSdkInstance.individual(),
    status$,
    isPreloaded$,
    failedStatus$,
    globalEventHub,
    maxDocumentCount: ocr?.maxDocumentCount ?? 1,
    documents: ocr?.documents ?? [],
  };

  const start = mkStartMethod(deps);

  const mount = mkMountMethod({
    ...globalState,
    ...deps,
    vendorLoader: loadVendorWrapper,
  });

  // Event mappings for telemetry

  localEventHub.on('input_required', ({ documentType, side }, status) =>
    globalEventHub.emit('telemetry', {
      eventName: 'OCR:INPUT_REQUIRED',
      data: {
        documentType,
        side,
        status,
      },
    }),
  );

  localEventHub.on('session_interrupted', () =>
    globalEventHub.emit('telemetry', 'OCR:INTERRUPTED'),
  );
  localEventHub.on('results', () =>
    globalEventHub.emit('telemetry', 'OCR:RESULTS'),
  );

  return {
    mount: debounce(mount, MOUNT_DEBOUNCE_WAIT, {
      leading: true,
      trailing: false,
    }),
    start,
    isPreloaded: () => isPreloaded$.getValue(),
    access: (fieldName) => publicAccessors[fieldName],
    statuses: Object.freeze(OCRStatus),
  };
});

type State = {
  document: Document;
  status: InputRequiredStatus | OCRStatus.COMPLETE;
  failedStatus: FailedStatus;
  files: File[];
  isPreloaded: boolean;
};

export function createOCRInternalState(initialState: State) {
  // Set reactive internal state
  const state$ = new ReactiveStore<State>(initialState);
  const status$ = state$.getRootAccessors('status');
  const failedStatus$ = state$.getRootAccessors('failedStatus');
  const document$ = state$.getRootAccessors('document');
  const files$ = state$.getRootAccessors('files');
  const isPreloaded$ = state$.getRootAccessors('isPreloaded');

  const _expectedSide$ = ReactiveStore.mkComputedAccessors(status$, {
    transformer: (status) => {
      if (isInputRequiredStatus(status)) {
        return status === OCRStatus.WAITING_FRONT ? 'front' : 'back';
      } else {
        return null;
      }
    },
    propertyName: 'expectedSide',
  });
  return {
    state$,
    status$,
    failedStatus$,
    document$,
    files$,
    isPreloaded$,
    _expectedSide$,
  };
}
