import { ICloneable } from '../general';

export enum EmailAddressType {
  OTHER = 'OTHER',
  WORK = 'WORK',
  PERSONAL = 'PERSONAL',
  BUSINESS = 'BUSINESS',
}

export interface EmailAddressPayload {
  emailAddressId?: string;
  type?: EmailAddressType;
  email?: string;
  isPreferred?: boolean;
}

export class EmailAddress
  implements EmailAddressPayload, ICloneable<EmailAddress>
{
  emailAddressId?: string;
  type?: EmailAddressType;
  email?: string;
  isPreferred?: boolean;

  constructor();

  constructor(data: EmailAddressPayload);

  constructor(data?: EmailAddressPayload) {
    if (!data) return;
    this.emailAddressId = data.emailAddressId;
    this.type = data.type;
    this.email = data.email;
    this.isPreferred = data.isPreferred;
  }

  clone(): EmailAddress {
    const payload = JSON.parse(JSON.stringify(this));
    const emailAddress = EmailAddress.fromJSON(payload);
    return emailAddress;
  }

  toJSON(): EmailAddressPayload {
    return { ...this };
  }

  static fromJSON(payload: EmailAddressPayload): EmailAddress {
    return new EmailAddress(payload);
  }

  static default(): EmailAddress {
    return new EmailAddress();
  }
}
