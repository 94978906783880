import type { EventHub } from '@module/common';
import { ReactiveStore } from '@module/common';
import { OneSDKError } from '@module/common';
import { CheckSummary } from '@module/common/shared/models/CheckSummary';
import {
  IssueCategory,
  IssueType,
  WorkflowStatus,
  type WorkflowSummary,
} from '@module/common/shared/models/F2/ServiceProfiles';
import type {
  IIssue,
  IIssueAction,
  TIssueType,
} from '@module/common/shared/models/TApplicantIssue';
import type {
  IndividualResponse,
  IndividualClient,
} from '@module/frankie-client/clients/F2/IndividualClient';
import type { GlobalEvents } from '@module/sdk/types';

import type { IndividualModule } from '../../definition';
import type { Events } from '../../events';
import type { InternalStateF2 } from '../definition';
import type { AxiosError } from 'axios';

const THROW_ERROR_WHEN_MISSING_CONSENTS = false;
type SubmitMethod = IndividualModule['moduleContext']['submit'];
type Dependencies = {
  state$: ReactiveStore<InternalStateF2>;
  eventHub: EventHub<Events>;
  client: IndividualClient;
  globalEventHub: EventHub<GlobalEvents>;
};
export const mkSubmit = (deps: Dependencies) => {
  const submitMethod = async (o: {
    verify: boolean;
  }): Promise<CheckSummary | void> => {
    const { eventHub, client, globalEventHub, state$ } = deps;
    const documents$ = state$.getRootAccessors('_documents');
    const applicant$ = state$.getRootAccessors('_applicant');
    const individual$ = state$.getRootAccessors('individual');
    const isLoading$ = state$.getRootAccessors('isLoading');
    const isPersisted$ = state$.getRootAccessors('isPersisted');

    const eventName = o?.verify ? 'SUBMIT_AND_VERIFY' : 'SUBMIT';
    try {
      isLoading$.setValue(true);
      // TODO: Should we use client.entityId instead of applicant$.getValue().entityId?
      const hasEntityId = Boolean(individual$.getValue().entityId);
      // This method will apply any logic for validating consents (if any) and potentially throw an error to interrupt the operation
      handleConsents(deps);

      // Decide if creating or updating applicant and then extract method into a single variable
      const method = hasEntityId
        ? client.updateIndividual
        : client.createIndividual;
      const applicant = applicant$.getValue();
      const individual = individual$.getValue();
      // const device = device$.getValue();

      // Bind extracted method above to the client object and then call it
      // Either action will result in an entityId
      // If creating new applicant, store recently found entity Id
      const response: IndividualResponse = await method.call(
        client,
        { individual },
        o,
      );

      if (!hasEntityId) {
        applicant.entityId = response.individual.entityId;
        applicant$.setValue(applicant);
      }

      isPersisted$.setValue(true);
      documents$.setValue(
        documents$.getValue().map((document) => {
          document.isPersisted = true;
          return document;
        }),
      );

      globalEventHub.emit('telemetry', {
        eventName,
        data: hasEntityId
          ? {}
          : { createdEntityId: response.individual.entityId },
      });

      if (o?.verify) {
        const workflowSummaries = response.serviceProfiles[0].workflowSummaries;
        return workflowSummaryToCheckSummary(
          workflowSummaries?.[workflowSummaries.length - 1],
        );
      }
    } catch (error) {
      const err = new OneSDKError((error as AxiosError).message, error);
      eventHub.emit('error', err);
      throw error;
    } finally {
      isLoading$.setValue(false);
    }
  };
  return submitMethod as SubmitMethod;
};

const handleConsents = ({ state$, eventHub }: Dependencies) => {
  const individual$ = state$.getRootAccessors('individual');
  const consentsGiven$ = ReactiveStore.mkPropertyAccessors(individual$, {
    propertyName: 'consents',
  });

  const isMissingConsents = !consentsGiven$.getValue()?.length;
  const errorMessage =
    'Consent needs to be provided before submitting any data.';
  const hintMessage =
    "Capture user's consent and provide it using the method addConsent(type) on the individual module, " +
    "where 'type' is one of the values defined in our api documentation";

  // How we want to handle consents is still being discussed, but we know consents are not always strictly required to be provided at this stage
  // For that reason we are not throwing an error, but instead emitting a warning. At least until we decide to do otherwise
  if (isMissingConsents && THROW_ERROR_WHEN_MISSING_CONSENTS) {
    throw new OneSDKError(errorMessage, {
      hint: hintMessage,
    });
  } else if (isMissingConsents) {
    eventHub.emit('warning', {
      message: errorMessage + 'This request might fail.',
      payload: {
        hint: hintMessage,
      },
    });
  }
};

export const workflowSummaryToCheckSummary = (
  workflowSummary?: WorkflowSummary,
): CheckSummary => {
  if (!workflowSummary) return CheckSummary.default();

  const getStatus = () => {
    switch (workflowSummary.status) {
      case WorkflowStatus.APPROVED:
      case WorkflowStatus.CLEAR:
      case WorkflowStatus.COMPLETE:
      case WorkflowStatus.PASS:
        return workflowSummary.statusOverrideRequestId ? 'pass_manual' : 'pass';
      case WorkflowStatus.BLOCKED:
      case WorkflowStatus.NEEDS_APPROVAL:
      case WorkflowStatus.MONITOR:
      case WorkflowStatus.URGENT:
        return 'review';
      case WorkflowStatus.FAIL:
      case WorkflowStatus.IN_PROGRESS:
      case WorkflowStatus.INCOMPLETE:
      case WorkflowStatus.REJECTED:
        return workflowSummary.statusOverrideRequestId ? 'fail_manual' : 'fail';
      default:
        return workflowSummary.status ?? '';
    }
  };

  const issuesToAlertList = () =>
    workflowSummary.issues?.map((issue) => {
      let term: string = issue.issue.toLowerCase();
      let type: TIssueType = 'alert';
      const extra: {
        label?: string;
        action: string;
      } = { action: '' };

      if (issue.category === IssueCategory.FRAUD) {
        term = 'fraud';
      } else if (issue.category === IssueCategory.KYC) {
        term = 'partial';
      } else if (issue.category === IssueCategory.BLOCKLISTED) {
        term = 'blacklist';
      }

      if (issue.issue === IssueType.MEDIA) {
        type = 'warning';
      }

      if (issue.category === IssueCategory.BLOCKLISTED) {
        type = 'action';
        extra.label = 'Blocklist';
        extra.action = 'blacklist';
      } else if (issue.category === IssueCategory.DUPLICATE) {
        type = 'action';
        extra.label = 'Duplicate';
        extra.action = 'duplicate';
      }

      const alert: IIssue = {
        term,
        type,
      };
      if (alert.type === 'action') {
        (alert as IIssueAction).extra = extra;
      }
      return alert;
    }) ?? [];

  return new CheckSummary({
    checkTypes: workflowSummary.steps?.order ?? [],
    checkDate: workflowSummary.startedAt ?? '',
    personalChecks: {
      name: null,
      dateOfBirth: null,
      phoneNumber: null,
      email: null,
      chineseName: '',
      addresses: {},
    },
    status: getStatus(),
    blocklistPotentialMatches: [],
    duplicatePotentialMatches: [],
    duplicateBlocklistMatches: [],
    risk: {
      level: workflowSummary.riskAssessment?.riskScore ?? null,
      class: workflowSummary.riskAssessment?.riskLevel ?? null,
    },
    alertList: issuesToAlertList(),
    checkCounts: {
      name: null,
      address: {},
      document: {},
      dob: null,
    },
    kycMethod: workflowSummary.isManual ? 'manual' : 'electronic',
    issues: {
      creditHeader:
        !!workflowSummary.issues?.find(
          ({ issue }) => issue === IssueType.CREDIT_HEADER,
        ) ?? false,
      MANUAL_BLOCKLIST:
        !!workflowSummary.issues?.find(
          ({ issue }) => issue === IssueType.MATCHED_INTERNAL,
        ) ?? false,
      DUPLICATE_RESOLVED:
        !!workflowSummary.issues?.find(
          ({ issue }) => issue === IssueType.DUPLICATE,
        ) ?? false,
      MANUAL_WATCHLIST:
        !!workflowSummary.issues?.find(
          ({ issue }) => issue === IssueType.INTERNAL_MATCH,
        ) ?? false,
    },
  });
};
