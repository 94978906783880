import type { UtilityConfiguration } from '@module/common/types/utilityConfig.types';

import {
  BaseConfigurationClient,
  ConfigurationClientF2,
} from './F2/ConfigurationClientF2';

import type { RecipeConfiguration } from '../../common';
import type { FrankieApiClient } from '../FrankieApiClient';

export class ConfigurationClient extends BaseConfigurationClient {
  constructor(frankie: FrankieApiClient, options: { recipe: string }) {
    super(frankie, options);
    if (frankie.frankie2customer) {
      return new ConfigurationClientF2(frankie, options);
    }
  }
  async loadRecipe(): Promise<RecipeConfiguration> {
    const params = {
      namespace: 'onesdk_profiles',
      item: this.options.recipe,
      itemType: 'object',
    };
    return this.frankie
      .get('/onesdk/v2/config', {
        params,
      })
      .then((response) => response.data?.[this.options.recipe]);
  }
  async loadUtilityConfig() {
    const params = {
      namespace: 'onesdk_utility',
    };
    return this.frankie
      .get<UtilityConfiguration>('/onesdk/v2/config', {
        params,
      })
      .then((response) => response.data);
  }
}
