import { ExtraData } from '../general';

export interface CustomAttribute {
  type: string;
  value: string;
}

export type CustomAttributesPayload = Record<string, CustomAttribute>;

export class CustomAttributeRecord {
  record: CustomAttributesPayload;

  constructor(record?: CustomAttributesPayload) {
    this.record = record ?? {};
  }

  toJSON(): CustomAttributesPayload {
    return this.record;
  }

  static fromJSON(payload: CustomAttributesPayload): CustomAttributeRecord {
    return new CustomAttributeRecord(payload);
  }

  static default(): CustomAttributeRecord {
    return new CustomAttributeRecord();
  }

  static fromExtraData = (
    extraData: ExtraData,
    previousCustomAttributes?: CustomAttributeRecord,
  ): CustomAttributeRecord =>
    Object.entries(extraData).reduce((acc, [key, value]) => {
      const inferType = () => {
        if (typeof value === 'string') return 'general.string';
        if (typeof value === 'boolean') return 'general.bool';
        if (typeof value === 'number') {
          if (Number.isInteger(value)) return 'general.integer';
          return 'general.float';
        }
        return 'general.string';
      };

      acc.record[key] = {
        type: previousCustomAttributes?.record[key]?.type ?? inferType(),
        value: value?.toString() ?? '',
      };

      return acc;
    }, new CustomAttributeRecord());

  toExtraData(): ExtraData {
    return Object.entries(this.record).reduce<ExtraData>(
      (acc, [key, { value }]) => ({
        ...acc,
        [key]: value,
      }),
      {},
    );
  }
}
