import type { Events } from '@module/business';
import type { EventHub } from '@module/common';
import type { SupportingDocument } from '@module/common/shared/models/SupportingDocument';

import { BaseClient } from '../BaseClient';

/**
 * Supporting documents client handles BFF documents operations
 */
export class SupportingDocumentsClientF2 extends BaseClient {
  async upload(
    file: File,
    eventHub: EventHub<Events>,
  ): Promise<{
    id: string;
  }> {
    const formData = new FormData();
    if (file) {
      formData.append('the-file', file);
    }
    const { data } = await this.frankie.post<{ id: string }>(
      `/f2/v1/file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: ({ loaded, total }) => {
          const completed = Math.round((loaded / total) * 100);
          eventHub.emit('upload_progress', { completed, loaded, total });
        },
      },
    );
    return data;
  }

  async create(
    supportingDocument: SupportingDocument,
  ): Promise<SupportingDocument> {
    if (!this.entityId)
      throw new Error(
        'Entity id not found in local state. Has entity been created yet?',
      );
    const { data } = await this.frankie.post<SupportingDocument>(
      `/f2/v1/applicant/${this.entityId}/supporting-documents`,
      {
        supportingDocument,
      },
    );

    return data;
  }
}
