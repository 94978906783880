import type { ModeObject } from '@module/common/types';
import { SdkModes } from '@module/sdk/types';

import { DummyFrankieApiClient } from './DummyFrankieApiClient';
import { FrankieApiClient } from './FrankieApiClient';
import { applyMocks } from './mocks/applyMocks';
import { defaultMockOptions } from './mocks/options/simplified/defaults';
import { resolveSimplifiedMockOptions } from './mocks/options/simplified/resolveSimplifiedMockOptions';

import type { MockOptions } from './mocks/options/MockOptions.type';
import type { SimplifiedMockOptions } from './mocks/options/simplified/SimplifiedMockOptions.type';
import type { SessionContext } from '../session/SessionContext';

type Options<Validate extends boolean = true> = {
  mode: ModeObject;
  session: SessionContext;
  /** Should this factory function validate the session? */
  validateToken?: Validate;
};
export function mkFrankieClient(options: Options<false>): FrankieApiClient;
export function mkFrankieClient(
  options: Options<true>,
): Promise<FrankieApiClient>;
export function mkFrankieClient(
  options: Options<boolean>,
): FrankieApiClient | Promise<FrankieApiClient> {
  if (options.mode.modeName === SdkModes.DUMMY) {
    // initialise dummy client with optional token
    const dummyClient = new DummyFrankieApiClient({
      ...options,
      defaultDelay: 200,
    });
    dummyClient.session = options.session;
    // resolve mock options either from mode: { options } or if mode: "dummy", then use default mock options
    const simplifiedMockOptions: SimplifiedMockOptions =
      options.mode.mocks ?? defaultMockOptions;
    const mockOptions: MockOptions = resolveSimplifiedMockOptions(
      simplifiedMockOptions,
    );
    console.debug('Resolved mock options', {
      mockOptions,
      resultingMockedRequests: mockOptions,
    });
    // inject mocked flow to dummy client
    return applyMocks(dummyClient, mockOptions);
  }
  // if not in dummy mode, simply initialise the regular api client with the provided token
  const client = new FrankieApiClient();
  client.session = options.session;

  // This function is only synchronous if options.validateToken is explicitly false
  const { validateToken = true } = options;
  if (validateToken) return client.validateSession().then(() => client);
  return client;
}
export { FrankieApiClient, DummyFrankieApiClient };
