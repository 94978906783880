// MIGRATION ARE NOT COMPLETE
// MAY BREAK THINGS UP
import { treeShake } from '@module/common/modules/objectUtils';
import type { Document } from '@module/common/shared/models/Document';
import { Status as OCRStatus } from '@module/ocr';
import type { Dictionary } from '@types';

import { BaseClient } from '../BaseClient';

export class IDVClientF2 extends BaseClient {
  async getToken<
    SDKParameters extends Dictionary<unknown> = Dictionary<unknown>,
  >(options?: IDVTokenParameters): Promise<IDVTokenResponse<SDKParameters>> {
    const url = `f2/v2/idvCheck/token`;
    const appReference = this.frankie.session.appReference;
    const extractData = (response) => response.data;
    return this.frankie
      .post<IDVTokenResponse<SDKParameters>>(
        url,
        treeShake(
          {
            applicant: {
              customerReference: this.reference,
              entityId: this.entityId,
            },
            appReference,
          },
          [undefined, null],
        ),
        {
          params: treeShake(
            {
              relayState: options?.relayState,
            },
            [undefined, null],
          ),
        },
      )
      .then(extractData)
      .then((d) => {
        this.entityId = d.entityId;
        return d;
      });
  }
  async initProcess({
    isSynchronous,
    vendorParameters = {},
  }: InitIDVProcessRequest): Promise<{
    checkStatus: IDVStatus;
    ocrDocument?: Document;
  }> {
    const entityId = this.entityId;

    return this.frankie
      .post<InitIDVProcessResponse>(
        `f2/v2/idvCheck/${entityId}/initProcess${isSynchronous ? '?is_synchronous' : ''}`,
        {
          vendorParameters,
        },
      )
      .then((response) => response.data);
  }
}
type IDVTokenResponse<Params extends Dictionary<unknown>> = {
  token: string;
  vendorParameters: Params;
  entityId: string;
};
type IDVTokenParameters = {
  relayState: string;
};

type InitIDVProcessRequest = {
  isSynchronous: boolean;
  vendorParameters?: Partial<{
    checkId: string;
  }>;
};

type InitIDVProcessResponse = {
  checkStatus: IDVStatus;
};

export enum IDVStatus {
  // Unknown use cases
  CHECK_NOT_STARTED = 'CHECK_NOT_STARTED',
  CHECK_PROCESSING = 'CHECK_PROCESSING',
  // Results
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED', // Added for the idv flow
  INCOMPLETE = 'INCOMPLETE', // Added for the idv flow
  DOCUMENTS_INVALID = OCRStatus.DOCUMENTS_INVALID, // Based on OCR Results
  // Errors
  PROVIDER_OFFLINE = 'PROVIDER_OFFLINE',
  AWAITING_CONSENT = 'AWAITING_CONSENT', // Added for the idv flow
  // Missing data
  WAITING_SELFIE_UPLOAD = 'WAITING_SELFIE_UPLOAD',
  WAITING_DOC_UPLOAD = 'WAITING_DOC_UPLOAD',
  INTERRUPTED = 'INTERRUPTED',
}
export type FailedStatus = IDVStatus.FAILED;
export type CompleteStatus = IDVStatus.COMPLETE;
export type InputRequiredStatus =
  | IDVStatus.DOCUMENTS_INVALID
  | IDVStatus.WAITING_DOC_UPLOAD
  | IDVStatus.WAITING_SELFIE_UPLOAD;
export type ErrorStatus =
  | IDVStatus.PROVIDER_OFFLINE
  | IDVStatus.AWAITING_CONSENT;

// TODO: Should we expose all these functions and types to customers?
// Types could potentially be a bit much, but maybe the utility functions could be useful
// Types could be exposed as a single namespace to make it a bit less overwhelming to learn
// ie: IDV["FailedStatus"], IDV["Status"] etc

export const isCompleteStatus = (
  status: IDVStatus,
): status is CompleteStatus => {
  return status === IDVStatus.COMPLETE;
};
export const isInputRequiredStatus = (
  status: IDVStatus,
): status is InputRequiredStatus => {
  return [
    IDVStatus.DOCUMENTS_INVALID,
    IDVStatus.WAITING_DOC_UPLOAD,
    IDVStatus.WAITING_SELFIE_UPLOAD,
  ].includes(status);
};
export const isFailedStatus = (status: IDVStatus): status is FailedStatus => {
  return [IDVStatus.FAILED].includes(status);
};
export const isErrorStatus = (status: IDVStatus): status is ErrorStatus => {
  return [IDVStatus.PROVIDER_OFFLINE, IDVStatus.AWAITING_CONSENT].includes(
    status,
  );
};
