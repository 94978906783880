import type { IApplicantDetails } from '@module/common/shared/models/api';
import type { IApplicantPayload } from '@module/common/shared/models/Applicant';
import { Applicant } from '@module/common/shared/models/Applicant';
import { CheckSummary } from '@module/common/shared/models/CheckSummary';
import type { DeviceCheckDetails } from '@module/common/shared/models/DeviceCheck';
import type { IDocumentPayload } from '@module/common/shared/models/Document';
import { Document } from '@module/common/shared/models/Document';

import { BaseClient } from './BaseClient';

/**
 * Applicant client handles BFF Applicant and Document Operations
 * Note that Applicant payloads actually include { applicant, documents }
 */
export class ApplicantClient extends BaseClient {
  async load(): Promise<ApplicantState> {
    /**
     * A search made using entityId HAS to be successful, since the entity id already exists. If not found, throw exception
     * A search made using reference doesn't need to return results, as it might be a
     */
    const { reference, entityId } = this;
    const data = await this.frankie
      .get<SearchPayload>('/data/v2/applicant/', {
        params: {
          reference,
          entityId,
        },
      })
      .then((response) => {
        const data = response.data;
        this.entityId = data.applicant.entityId;
        return data;
      })
      .then((data) => convertPayloadToSharedModels(data));
    return data;
  }
  async createApplicant(payload: ApplicantState): Promise<CreatedResponse> {
    const { applicant, documents } = payload;
    return this.frankie
      .post<CreatedResponse>('/data/v2/applicants', {
        applicant,
        documents,
        kycMethod: 'electronic',
      })
      .then((r) => {
        // store new entity id locally for subsequent requests
        this.entityId = r.data.entityId;
        return r.data;
      });
  }
  async updateApplicant(payload: ApplicantState): Promise<CreatedResponse> {
    const { applicant, documents } = payload;
    if (!this.entityId)
      throw new Error(
        'Entity id not found in local state. Has entity been created yet?',
      );

    return this.frankie
      .put<CreatedResponse>(`/data/v2/applicants/${this.entityId}`, {
        applicant,
        documents: documents.filter((d) => !d.isPersisted),
        kycMethod: 'electronic',
      })
      .then((r) => r.data);
  }
  async getLatestCheckResults(): Promise<CheckSummary> {
    if (!this.entityId)
      throw new Error(
        'Entity id not found in local state. Has entity been created yet?',
      );

    return await this.frankie
      .get<IApplicantDetails>(`/data/v2/applicants/${this.entityId}`)
      .then((r) => {
        const { checkSummary } = r.data;
        return CheckSummary.fromJSON(checkSummary);
      });
  }
  async triggerChecks({
    deviceCheckDetails = {},
  }: {
    deviceCheckDetails: DeviceCheckDetails;
  }): Promise<CheckSummary> {
    if (!this.entityId)
      throw new Error(
        'Entity id not found in local state. Has entity been created yet?',
      );
    await this.frankie.post(`/data/v1/applicants/${this.entityId}/checks`, {
      deviceCheckDetails,
    });
    return this.getLatestCheckResults();
  }
}
function convertPayloadToSharedModels(data: SearchPayload) {
  return {
    applicant: Applicant.fromJSON(data.applicant),
    documents:
      data.documents?.map((documentData) =>
        Document.fromJSON({
          ...documentData,
          isPersisted: true,
        }),
      ) ?? [],
  };
}
type ApplicantState = {
  applicant: Applicant;
  documents: Document[];
};
type SearchPayload = {
  applicant: IApplicantPayload;
  documents?: IDocumentPayload[];
};
type CreatedResponse = { entityId: string; requestId: string };
