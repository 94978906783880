import type { organizations_entity_response } from '@module/common/shared/models/internal';

import { BaseClient } from './BaseClient';

/**
 * Business client handles BFF Organization Operations
 */
export class BusinessClient extends BaseClient {
  async load(): Promise<organizations_entity_response> {
    if (!this.entityId)
      throw new Error(
        'Entity id not found in local state. Has entity been created yet?',
      );
    const { data } = await this.frankie.get<organizations_entity_response>(
      `/data/v3/business/${this.entityId}`,
    );
    this.entityId = data.organization?.entityId ?? null;
    return data;
  }
}
