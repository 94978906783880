import { LocalStorage } from '@module/common/modules/LocalStorage';
import { URL_PARAM } from '@module/frankie-client/DummyFrankieApiClient';
import type { DummyFrankieApiClient } from '@module/types';

export function mockStorage(
  client: DummyFrankieApiClient,
): DummyFrankieApiClient {
  client.stubResponse(
    {
      url: new RegExp(`\\/data\\/v1\\/key-value\\/${URL_PARAM}`),
      method: 'get',
    },
    (config) => {
      // When mocking storage, use localstorage as fake BFF cache
      const key = config.urlMatch?.[1];
      let value;
      if (key) {
        const storage = new LocalStorage();
        value = storage.retrieveAndDelete(key);
      }
      return {
        title: `Fetching key-pair value for key '${key}' `,
        status: value ? 200 : 404,
        statusText: 'not found',
        data: value ? { value } : {},
      };
    },
  );
  return client;
}
