import EE, { type Emitter } from 'event-emitter';
import allOff from 'event-emitter/all-off.js';
import hasListeners from 'event-emitter/has-listeners.js';

import { feat } from '@feature-flags';

import type { EventHub, EventsDictionary, ListenerFromEmitter } from './types';

export * from './types';

//TODO: Add method "breed", or "child" to generate a new event hub which reference is kept and can be used for cascading the "off" method
// This will allow to have a "parent" event hub which can be used to remove all the listeners of multiple children event hub in one function call
// IE global event hub generates all others and when it is destroyed, all the children are destroyed as well, ensuring no memory leak.

/**
 * Event Hub
 */

// Setting the Emitter constructor from the event-emitter library
// eslint-disable-next-line @typescript-eslint/no-empty-function
const Emitter = function () {};
EE(Emitter.prototype);

/**
 * Creates an event hub instance taking as a generic type parameter the dictionary of events that the event hub will support (and typescript will validate/autocomplete).
 * @returns A new EventHub object
 */
export function mkEventHub<
  Events extends EventsDictionary,
>(): EventHub<Events> {
  const emitter: Emitter = new Emitter();

  // These methods rely on the "this" context, so we need to bind them to the emitter when dettaching them
  const originalEmit = emitter.emit.bind(emitter);
  const originalOff = emitter.off.bind(emitter);
  const originalOn = emitter.on.bind(emitter);

  /**
   * Emit an event with the given name and arguments. Also emit a wildcard event with the same arguments.
   * @param eventName The name of the event to emit, which needs to be present in the Events dictionary
   * @param args Any arguments to be emitted with the event. Needs to match the tuple provided in the Events dictionary
   */
  const emit = (eventName: string, ...args: unknown[]) => {
    const wildcardArgs = { eventName, arguments: args };

    const skippedStacks = 2;
    const stackTrace = getStackTrace(skippedStacks);
    const argsWithStack: unknown[] = stackTrace ? [...args, stackTrace] : args;
    const wildcardArgsWithStack: unknown[] = stackTrace
      ? [wildcardArgs, stackTrace]
      : [wildcardArgs];

    originalEmit(eventName, ...argsWithStack);
    originalEmit('*', ...wildcardArgsWithStack);
  };
  /** Check if there are any listeners for the given event name */
  const hasListenersFor = (eventName: string) => {
    return hasListeners(emitter, eventName);
  };
  /**
   * Either remove all listeners for all events, or remove all listeners for a specific event.
   * TODO: Allow a single special "EventReference" object as parameter to "off" to allow caller to unplug the event listeners and prevent memory leaks easily
   * !ATTENTION: This needs to be backwards compatible
   */
  const off = (
    eventName?: string | string[],
    listener?: (...args: unknown[]) => void,
  ) => {
    const wasArrayProvided = Array.isArray(eventName);
    let eventNamesArray: string[] = [];
    if (eventName) {
      eventNamesArray = wasArrayProvided ? eventName : [eventName];
    }

    if (eventName && listener) {
      eventNamesArray.forEach((eventName) => originalOff(eventName, listener));
    } else {
      emit('__off__');
      allOff(emitter);
    }
  };

  /** Extending on to support array of event names and to optionally include stack trace
   * TODO: Return a "EventReference" object to allow caller to unplug all the event listeners mapped by this method at once and prevent memory leaks easily
   */
  const on = (
    eventNames: string | string[],
    callback: (...args: unknown[]) => void,
  ) => {
    const eventNamesArray = Array.isArray(eventNames)
      ? eventNames
      : [eventNames];
    eventNamesArray.forEach((eventName) => originalOn(eventName, callback));
    return {
      cleanup: () => {
        eventNamesArray.forEach((eventName) =>
          originalOff(eventName, callback),
        );
      },
    };
  };

  /**  Map an event from this event hub to another event hub.
   * TODO: Return the reference to the callback function to allow caller to turn this mapping "off"
   * TODO: Return a "EventReference" object to allow caller to unplug all the event listeners mapped by this method at once and prevent memory leaks easily
   **/
  const map = (
    eventNames: string | string[],
    proxyEventHub: EventHub<EventsDictionary>,
    proxyEventName?: string,
  ) => {
    // For each event name provided, add a listener who will either
    // emit the provided proxyEventName in the proxied eventHub, OR
    // emit the same eventName in the proxied eventHub, if no proxyEventName is provided
    const wasArrayProvided = Array.isArray(eventNames);
    const eventNamesArray: string[] = wasArrayProvided
      ? eventNames
      : [eventNames];
    eventNamesArray.forEach((eventName) => {
      const proxiedEventName = proxyEventName ?? eventName;
      on(eventName, (...args) => proxyEventHub.emit(proxiedEventName, ...args));
    });
  };

  return Object.assign(emitter, {
    on,
    emit,
    hasListenersFor,
    map,
    off,
  } as EventHub<Events>);
}
/**
 * EventListener conversion from an EventHub object
 * EventListener is a Read-only interface for eventhub
 * @param emitter The event hub to convert to an event listener
 * @returns An instance of the EventListener interface
 */
export function mkEventListener<Hub extends EventHub>(
  emitter: Hub,
): ListenerFromEmitter<Hub> {
  return {
    on: emitter.on.bind(emitter),
    off: emitter.off.bind(emitter),
  };
}

const getStackTrace = (skip: number): string[] | null => {
  if (!feat('includeStackTraceOnEvents')) return null;
  try {
    throw new Error();
  } catch (e) {
    // Skip the "Error" title and the "getStackTrace" function
    return (
      (e as Error).stack
        ?.split('\n')
        .slice(skip)
        .map((line: string) => line.trim()) ?? null
    );
  }
};
