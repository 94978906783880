import type { Applicant } from '@module/common/shared/models/Applicant';
import type { Accessors } from '@module/types';

import type { IndividualModule } from '../definition';

type SetEmail = IndividualModule['moduleContext']['setEmail'];
type GetEmail = IndividualModule['moduleContext']['getEmail'];

type MkSetEmail = {
  applicant$: Accessors<Applicant>;
};

type MkGetEmail = MkSetEmail;

export function mkSetEmail(deps: MkSetEmail): SetEmail {
  const { applicant$ } = deps;

  return (email: string | null) => {
    const applicant = applicant$.getValue();
    applicant.email = {
      documentId: applicant.email.documentId,
      idNumber: email,
    };
    applicant$.setValue(applicant);
  };
}

export function mkGetEmail(deps: MkGetEmail): GetEmail {
  const { applicant$ } = deps;

  return () => {
    const applicant = applicant$.getValue();
    return applicant.email.idNumber;
  };
}
