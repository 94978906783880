import { CheckSummary } from '@module/common/shared/models/CheckSummary';
import type { DummyFrankieApiClient } from '@module/frankie-client';

import { URL_PARAM } from '../../DummyFrankieApiClient';

import { resolveIndividualData } from './resolveIndividualData';

import type { ResolveIndividualDataOptions } from './resolveIndividualData';
import type { MockOptions as _MockOptions } from '../options/MockOptions.type';

type MockOptions = _MockOptions & {
  preloadedIndividual?: ResolveIndividualDataOptions;
};

export function loadExistingApplicant(
  client: DummyFrankieApiClient,
  options: MockOptions,
): DummyFrankieApiClient {
  const { individual, documents } = resolveIndividualData(
    options.preloadedIndividual,
  );

  client.stubResponse(
    { url: '/data/v2/applicant', method: 'get' },
    (config) => {
      if (config.params.entityId) individual.entityId = config.params.entityId;
      if (config.params.reference)
        individual.customerReference = config.params.reference;

      return {
        title: 'Search individual',
        status: 200,
        data: {
          applicant: individual.toJSON(),
          documents: documents?.map((d) => d.toJSON()),
        },
      };
    },
  );

  return client;
}
export function applicantNotFound(
  client: DummyFrankieApiClient,
): DummyFrankieApiClient {
  client.stubResponse(
    { url: '/data/v2/applicant', method: 'get' },
    {
      title: 'Individual not found',
      status: 404,
    },
  );

  return client;
}
export function loadSuccessfulCheckResults(
  client: DummyFrankieApiClient,
): DummyFrankieApiClient {
  const checkSummary = CheckSummary.default();
  checkSummary.status.type = 'passed';
  checkSummary.status.label = 'Passed';
  checkSummary.status.key = 'PASSED';
  checkSummary.alertList = [];

  client.stubResponse(
    { url: new RegExp(`/data/v2/applicants/${URL_PARAM}`), method: 'get' },
    {
      title: 'Load check results',
      data: {
        checkSummary: checkSummary.toJSON(),
      },
    },
  );
  return client;
}
